export const REQUEST_QUESTIONS = "REQUEST_QUESTIONS";
export const RECEIVE_QUESTIONS = "RECEIVE_QUESTIONS";
export const HANDLE_OPTIONS_CHANGE = "HANDLE_OPTIONS_CHANGE";
export const HANDLE_MARK = "HANDLE_MARK";
export const HANDLE_FLAG = "HANDLE_FLAG";
export const HANDLE_SUBMIT = "HANDLE_SUBMIT";
export const POST_RESULT = "POST_RESULT";
export const ADD_LOCATION = "ADD_LOCATION";
export const SUBMIT_FAILED = "SUBMIT_FAILED";
export const REQUEST_FAILED = "REQUEST_FAILED";

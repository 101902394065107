import React from "react";
import {  withRouter } from "react-router-dom";
import CourseUi from "./CourseUi";
import "./Course.css";

function CourseSelect({ history }) {
  let category = [
    { id: "pcl", name: "PCL Entrance", count: 7, link: "/courseMain" },
    {
      id: "nursing",
      name: "Nursing Liscense",
      count: 3,
      link: "/liscenseExam",
    },
  ];
  return <CourseUi category={category} history={history} />;
}

export default withRouter(CourseSelect);

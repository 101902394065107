import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      $(document).ready(function () {
        $("body").removeClass("sidebar-open");
        $("body").addClass("sidebar-collapse");
      });
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);

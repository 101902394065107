import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link, useLocation } from 'react-router-dom';
import SeriesNotFound from './SeriesNotFound';
import moment from 'moment';
import Preloader from '../Preloader/Preloader';
import { useModuleContext } from 'context/ModuleProvider';

function SeriesTest() {
    const { url, moduleId } = useModuleContext();
    const [test, setTest] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("question_set");

    useEffect(() => {
        if (query && !isNaN(query)) {
            axios({
                method: 'get',
                url: `${url}/questionSet/test?qSetId=${query}`,
                withCredentials: true,
            }).then((res) => {
                setTest(res.data.test || []);
            });
        } else {
            console.log("query is not an id :", query);
        }
    }, [url, query]);

    return (
        <section className="content">
            {test ? (
        <div className="container-fluid">
            <div className="row">
                {test.length && test.length !== 0 ? (
                    test.map((t) => <SingleTest key={t.id} t={t} moduleId={moduleId} />)
                ) : (
                    <SeriesNotFound name={'test'} />
                )}
            </div>
        </div>
    ) : (
        <Preloader />
    )}
        </section>
    );
}

function SingleTest({ t, moduleId }) {
    let SERVER_URL = process.env.REACT_APP_SERVER_URL;
    let redirect = `/${moduleId}/test/instruction`;
    let query = `testId=${t.id}`;
    let text = 'Start Test';
    let header = 'Test';
    let endDate = moment.utc(t.updated_at);

  let newTest = moment().subtract(7, 'days') < endDate;
    let updatedDate = moment.utc(t.updated_at);

    let startTestLink = `/${moduleId}/test/instruction`;
    let resultLink = `/${moduleId}/question-sets/test/result`;

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 px-sm-2 px-0">
            <div className="card" id="test-mb">
                {newTest ? (
                    <div className="ribbon ribbon-top-right">
                        <span>New</span>
                    </div>
                ) : (
                    <></>
                )}
                <div className="card-">
                    <Link
                        className="weekly-title d--inline-block text-dark float-right"
                        to={{
                            pathname: resultLink,
                            search: query && `?${query}`,
                            state: {
                                id: t.id,
                                name: t.name,
                                price: t.price,
                                isQuestionSet: true,
                            },
                        }}
                    >
                        <div className="row">
                            <div className="d-block date-card text-muted px-3 pt-2" style={{ fontSize: '11px', fontWeight: '600' }}>
                                {updatedDate.local().format('DD/MM/YYYY')}
                            </div>
                        </div>
                    </Link>

                    <div className="text-left" style={{ minHeight: '126px' }}>
                        <Link
                            className="row weekly-title text-dark p-2 w-100"
                            to={{
                                pathname: resultLink,
                                search: query && `?${query}`,
                                state: {
                                    id: t.id,
                                    name: t.name,
                                    price: t.price,
                                    isQuestionSet: true,
                                },
                            }}
                        >
                            <div className="col-md-2 col-3">
                                <div className="weekly-test pt-0">
                                    <img
                                        src={`${SERVER_URL}/images/subjectLight/icons/${moduleId}/chemistry.png`}
                                        alt="text-icon-indicator"
                                        className="media-object"
                                    />
                                </div>
                            </div>

                            <div className="col-md-10 col-9">
                                <h6 className="pr-0 font-weight-bold mb-2" style={{ fontSize: '12px' }}>
                                    {t.name}
                                </h6>
                                <div>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <p style={{ fontSize: '13px', color:"black" }}>
                                                {t.total} Questions, {t.duration} mins | {header}
                                            </p>
                                        </li>
                                        <li className="list-inline float-right">
                                            {text && (
                                                <Link
                                                    to={{
                                                        pathname: startTestLink,
                                                        search: query && `?${query}`,
                                                        state: {
                                                            id: t.id,
                                                            name: t.name,
                                                            price: t.price,
                                                            isQuestionSet: true,
                                                        },
                                                    }}
                                                    className="btn btn-outline-dark btn-sm ad-click-event small"
                                                    style={{ fontSize: '11px' }}
                                                >
                                                    {text}&nbsp;<i className="fa fa-chevron-right"></i>
                                                </Link>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SeriesTest);
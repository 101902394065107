import React from "react";
import ReactMarkdown from "react-markdown";

function QuestionCard({ q, index, len }) {
  let { optionA, optionB, optionC, optionD, answer } = q;
  optionD = optionD || "";
  return (
    <div id="form" className="card check_2 subject_6">
      <div>
        <div className="test-question">
          <span>
            Practice Question {index + 1} of {len}
          </span>
          <h3 className="card-title pb-2">
            <ReactMarkdown
              source={q.question}
              escapeHtml={false}
            />
          </h3>
        </div>
        <div className="form-check">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer1"
            defaultValue={1}
            checked={1 === parseInt(answer)}
            disabled
          />
          <label className="form-check-label"  htmlFor={`answer${q.id}`}>
            <ReactMarkdown
              source={optionA}
              escapeHtml={false}
            />
          </label>
        </div>
        <div className="form-check">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer2"
            defaultValue={2}
            disabled
            checked={2 === parseInt(answer)}
          />
          <label className="form-check-label" htmlFor={`answer${q.id}`}>
            <ReactMarkdown
              source={optionB}
              escapeHtml={false}
            />
          </label>
        </div>
        <div className="form-check">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer3"
            defaultValue={3}
            checked={3 === parseInt(answer)}
            disabled
          />
          <label className="form-check-label answer" htmlFor={`answer${q.id}`}>
            <ReactMarkdown
              source={optionC}
              escapeHtml={false}
            />
          </label>
        </div>
        <div className="form-check">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer4"
            checked={4 === parseInt(answer)}
            disabled
          />
          <label className="form-check-label" htmlFor={`answer${q.id}D`}>
            <ReactMarkdown
              source={optionD}
              escapeHtml={false}
            />
          </label>
        </div>
        <br />
        <div align="right">
          <div
            className="btn bookmark"
            id="bookmark_Which of the following is not a pictorial drawing?"
            style={{ color: "var(--warning)", padding: "0px" }}
            title="Mark for review"
          >
            <span className="fa-stack fa-lg">
              <i className="fa fa-circle fa-stack-2x" />
              <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {  ProfileImg } from "../../helper";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

function ExploreTap({ subject, moduleId, achievers }) {
  const bg = ["bg-primary", "bg-danger", "bg-success", "bg-info"];
  const [eswiper, updateEswiper] = useState(null);
  const showType = moduleId === "pg" || moduleId === "nmcle";
  const exploreParams = {
    slidesToShow: 1,
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
  };
  eswiper && eswiper.update();

  return (
    <div className="dis-categories-1">
      <div className="zero-list">
        <div className="dis__labels">
          <p className="text-muted small pl-2 font-weight-bold">Subject Discussions</p>
          <div className="row">
            {subject &&
              subject.map((s, index) => {
                let {
                  subject_id,
                  subject_name,
                  discussionCount,
                  answerCount,
                  pollCount,
                  type,
                } = s;
                if (showType && !type) {
                  return "";
                }
                return (
                  <div key={subject_id} className="col-sm-3 col-6">
                    <Link
                      className="d-block"
                      to={`/${moduleId}/discussion/detail?subjectId=${subject_id}`}
                    >
                      <div
                        className={`dis__cat__title p-sm-3 p-2  ${
                          bg[index % 4]
                        }`}
                      >
                        <h5 className="d-block">
                          {showType ? type.toUpperCase() : subject_name}
                        </h5>
                        <p className="d-block">{discussionCount} Discussions</p>
                        <p className="d-block">{pollCount} Polls</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="dis__labels">
        <p className="text-muted small pl-2 font-weight-bold">Top Achievers</p>
        <div className="row">
          {achievers && (
            <Swiper getSwiper={updateEswiper} {...exploreParams}>
              {achievers.map((a) => {
                let {
                  total,
                  user: { name, imageId },
                } = a;
                return (
                  <div key={a.user.id} className="swiper-slide dis__slider">
                    <ProfileImg
                      src={imageId}
                      className="rounded-circle circle d-block"
                    />
                    <span className="text-dark d-block">
                      &nbsp;<span>{name}</span>
                      <br />
                      Total Reviews
                      <h5 className="d-block pr-0">{total}</h5>
                    </span>
                  </div>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExploreTap;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import SeriesNotFound from './SeriesNotFound';
import moment from 'moment';
import Preloader from '../Preloader/Preloader';
import { useModuleContext } from 'context/ModuleProvider';
import { BiMessage } from 'react-icons/bi';

function Series() {
    const { url, moduleId } = useModuleContext();
    const [questionSets, setQuestionSets] = useState(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${url}/questionSet`,
            withCredentials: true,
        }).then((res) => {
            setQuestionSets(res.data.series || []);
        });
    }, [url]);

    return (
        <section className="content">
            {questionSets ?
            <div className="container-fluid test_series_container" style={{minHeight: "80vh"}}>
                <div className="row test_series" style={{flexWrap: 'wrap'}}>
                    {questionSets.length && questionSets.length !== 0 ? 
                    questionSets.map(item=>{
                        return <SingleSet key={item.id} item={item} moduleId={moduleId}/>
                    }):<SeriesNotFound name={'series'}/>}
                </div>
            </div> : <Preloader /> }
        </section>
    );
}

function SingleSet({ item, moduleId }) {
    return (
        <div className="item">
            <h4>{item.title}</h4>
            <div className="content">
                <div className="left">
                    <p>
                        <BiMessage style={{ color: 'red' }} /> No. of Tests : {item.no_of_tests}
                    </p>
                </div>  
                <Link
                    className="btn btn-dark"
                    to={item.has_paid ? `/${moduleId}/question-sets/test?question_set=${item.id}` : `/${moduleId}/payment`}
                >
                    {item.has_paid ? 'See Tests' : 'Subscribe'}
                </Link>
            </div>
        </div>
    );
}

export default withRouter(Series);
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DiscussionCard from '../Discussion/DiscussionCard';
import { useModuleContext } from 'context/ModuleProvider';

function ActivityTab() {
  let { moduleId, url } = useModuleContext();
  let [discussion, setDiscussion] = useState();
  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/discussion/recent?profile=true`,
      withCredentials: true,
    }).then(async (res) => {
      console.log(res.data.discussion, 'discussion');
      setDiscussion(res.data.discussion);
    });
  }, [url]);

  const addVote = (discussion_id, check) => {
    if (!check) {
      axios({
        method: 'post',
        url: `${url}/discussion/t/vote`,
        withCredentials: true,
        data: { type: 1, discussion_id },
      }).then((res) => {
        if (!res.data.error) {
          let data = res.data;
          let newDiscussion = discussion.map((d) => {
            if (d.discussion_id === discussion_id) {
              d.vote = data.vote;
              d.type = data.type;
            }
            return d;
          });
          setDiscussion(newDiscussion);
        } else {
          alert(res.data.error);
        }
      });
    }
  };
  return (
    <div className="px-4 ml-0" style={{background: "#fff"}}>
      <p className="profile__label mb-3">Activity Controls</p>
      <div className="row">
        <div className="col-sm-12">
          <p>
            The data saved in your account helps give you more personalized experiences across Avyaas app in both mobile
            and web. Choose which settings will save data in your Avyaas Account.
          </p>
        </div>
        <div className="col-sm-8 mt-3" id="activityControl">
          <div className="form-group d-flex justify-content-between" id="dToggleOne">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#activityDetail"
              aria-expanded="true"
              aria-controls="activityDetail"
            >
              <label className="mb-0 d-block" for="activity-switch">
                Web & App activity&nbsp;<i className="fa fas-chevron-down"></i>
              </label>
              <p className="small text-muted">Any major actions in your account such as posts, exams etc.</p>
            </div>
            <input name="activity-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div id="activityDetail" class="collapse show" aria-labelledby="dToggleOne" data-parent="#activityControl">
            <div class="py-3 px-1">
              <ul className="small" style={{ color: 'var(--gray)' }}>
                <li>You attended the test XI on 2021 - 08 - 06 at 02:22 AM.</li>
                <li>You logged in the app on 2021 - 08 - 06 at 02:22 AM.</li>
                <li>You posted the discussion in the app on 2021 - 08 - 06 at 02:22 AM.</li>
                <li>You commented on a post on 2021 - 08 - 06 at 02:22 AM.</li>
                <li>You voted on a poll on 2021 - 08 - 06 at 02:22 AM.</li>
              </ul>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="dToggleTwo">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#locationDetail"
              aria-expanded="true"
              aria-controls="locationDetail"
            >
              <label className="mb-0 d-block" for="location-switch">
                Location History
              </label>
              <p className="small text-muted">Saves your location address while using the exam module of the app.</p>
            </div>
            <input name="location-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div id="locationDetail" class="collapse show" aria-labelledby="dToggleTwo" data-parent="#activityControl">
            <div class=" py-3 px-1">
              <ul className="small" style={{ color: 'var(--gray)' }}>
                <li>Your account was logged in 2021 - 08 - 06 at 02:22 AM from Kathmandu, NP.</li>
                <li>Your account was logged in 2021 - 08 - 06 at 02:22 AM from Lalitpur, NP..</li>
                <li>Your account was logged in 2021 - 08 - 06 at 02:22 AM from Kathmandu, NP..</li>
              </ul>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="dToggleThree">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#accountDetail"
              aria-expanded="true"
              aria-controls="accountDetail"
            >
              <label className="mb-0 d-block" for="location-switch">
                Account History
              </label>
              <p className="small text-muted">Changes in your account settings are stored here.</p>
            </div>
            <input name="location-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div id="accountDetail" class="collapse show" aria-labelledby="dToggleThree" data-parent="#activityControl">
            <div class="py-3 px-1">
              <ul className="small" style={{ color: 'var(--gray)' }}>
                <li>You Changed your username to NEW USERNAME 2021 - 08 - 06 at 02:22 AM..</li>
                <li>You updated your contact number 2021 - 08 - 06 at 02:22 AM..</li>
                <li>You updated your profile picture 2021 - 08 - 06 at 02:22 AM..</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-sm-8 py-2 pl-0">
          <p className="profile__label mb-3">
            You have <strong>14</strong> posts till now.
          </p>
          {discussion && discussion.length !== 0 ? (
            <DiscussionCard discussion={discussion} addVote={addVote} moduleId={moduleId} />
          ) : (
            <div className="p-1 text-center">
              <p className="hero-p">You have not posted any questions yet. Join Discussion now.</p>
              {/* Download the used image below locally*/}
              <div className="post-error-content">
                <img src="/images/confused.jpg" alt="Post error" width="auto" height={300} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivityTab;

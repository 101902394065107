import React, { useState } from "react";
import { withRouter } from "react-router-dom";

function Esewa() {
  const [message, setMessage] = useState("");
  let mainPath = "https://uat.esewa.com.np/epay/main";

  let su = "http://localhost:3000/mbbs/esewa/sucess";
  let fu = "http://localhost:3000/mbbs/esewa?q=fu";

  var params = {
    tAmt: 100,
    amt: 90,
    psc: 2,
    pdc: 3,
    txAmt: 5,
    pid: "ee2c3ca1-696b-4cc5-a6be-2c40d929d853",
    scd: process.env.REACT_APP_ESEWA_SCD,
    su: su,
    fu: fu,
  };


  const postData = () => {
    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", mainPath);
    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <form action="https://uat.esewa.com.np/epay/main" method="POST">
        <input value={100} name="tAmt" type="hidden" />
        <input value={90} name="amt" type="hidden" />
        <input value={5} name="txAmt" type="hidden" />
        <input value={2} name="psc" type="hidden" />
        <input value={3} name="pdc" type="hidden" />
        <input value="EPAYTEST" name="scd" type="hidden" />
        <input
          value="ee2c3ca1-696b-4cc5-a6be-2c40d929d483"
          name="pid"
          type="hidden"
        />
        <input
          defaultValue="http://localhost:3000/mbbs/esewa/sucess"
          type="hidden"
          name="su"
        />
        <input
          defaultValue="http://localhost:3000/mbbs/esewa?q=fu"
          type="hidden"
          name="fu"
        />
        <input defaultValue="Submit" type="submit" />
      </form>
      <button onClick={postData}>Pay the payment</button>
      <div>{message}</div>
    </div>
  );
}

export default withRouter(Esewa);

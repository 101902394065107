import React, {  useContext } from 'react';
import {toast} from 'react-toastify';
import { ModalForm } from '../ui/ModalForm';
import ShareButton from '../ui/ShareButton';
import { AuthContext } from '../../context/AuthContextProvider';
import './Profile.css';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function ShareModel() {
  const { currentUser } = useContext(AuthContext);
  let shareUrl = `https://name.avyaas.com/a/${currentUser.id}`
  const notify = ()=>{
    // Calling toast method by passing string
    toast('Link Copied')
  }
  return (

    <ModalForm id="shareModal" className="popup" title="Share the App" style={{ transform: "translate(0,45vh)" }}>
      <div class="popup">
      <header>
      <div class ="close"><i class ="uil uil-times"></i></div>
      </header>
        <div class ="profile_content">
        <p>Share this link via</p>
          <ShareButton size={40} title={'Share App'} url={shareUrl} />
          {/* <ul class ="icons">
          
          <a href="#"><i class ="fab fa-facebook-f"></i></a>
          <a href="#"><i class ="fab fa-twitter"></i></a>
          <a href="#"><i class ="fab fa-instagram"></i></a>
          <a href="#"><i class ="fab fa-whatsapp"></i></a>
          <a href="#"><i class ="fab fa-telegram-plane"></i></a>
          </ul> */}
          <p>Or copy link</p>
          <div class ="profile_field row">
            <i class ="fas fa-link"></i>
            <input type ="text" readonly value={shareUrl}/>
            <button onClick={() => (notify) (navigator.clipboard.writeText(shareUrl)) } className="btn btn-success btn-sm">
              Copy
            </button>
        </div>
        </div>
      </div>
      </ModalForm >

  );
}

export default ShareModel;

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContextProvider';
import { concatText, ProfileImg } from '../helper';
import { useModuleContext } from 'context/ModuleProvider';
import { BiBook } from 'react-icons/bi';
import { GoBroadcast } from 'react-icons/go';
import { BsListCheck } from "react-icons/bs";

function Sidebar() {
  const { currentUser, logout } = useAuthContext();
  const { moduleId } = useModuleContext();
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4" style={{ minHeight: '548px' }}>
      {/* Brand Logo */}
      <NavLink style={{ display: 'flex', justifyContent: 'center', height: '68px' }} to={`/`} className="brand-link">
        <img
          src={moduleId === 'mbbs' ? `/images/logo.png` : `/images/nameplus.png`}
          alt="Avyass"
          className="brand-image top-logo d-block"
          style={{ opacity: '.8', maxWidth: '200px', objectFit: 'contain' }}
        />
        <span style={{ opacity: '0' }} className=" d-none brand-text font-weight-light">
          Avyass
        </span>
      </NavLink>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <NavLink to={`/${moduleId}/profile`} className="d-block">
            <ProfileImg
              alt="images"
              className="brand-link img-circle ml-3 bg-cover"
              style={{
                height: '40px',
                width: '40px',
                textAlign: 'center',
                border: '2px solid #e9ecef',
                padding: '1px',
                float: 'left',
              }}
              src={currentUser.imageId}
            />

            <div style={{ paddingTop: '8px' }}>
              <span className="" style={{ textAlign: 'center', margin: '1.5rem' }}>
                {concatText(currentUser.name, 20)}
                {/* <i className="flaticon-settings"></i> */}
              </span>
            </div>
          </NavLink>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2 pb-3" style={{ fontSize: '14px' }}>
          <ul
            id="nav"
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
            style={{ height: '65vh', overflowY: 'auto', flexWrap: 'nowrap' }}
          >
            {/* Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library */}
            <li className="nav-item">
              <NavLink to={`/${moduleId}/dashboard`} className="nav-link">
                <i className="nav-icon flaticon-home"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/practice`} className="nav-link">
                <i className="nav-icon flaticon-spots"></i>
                <p>Courses</p>
              </NavLink>
            </li>

            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/qbank`} className="nav-link">
                <i
                  class="nav-icon fas fa-book"
                  style={{ fontSize: '20px', marginLeft: '20px', marginRight: '10px', color: '#4a4a4a' }}
                ></i>
                <p>QBank</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/test`} className="nav-link">
                <i className="nav-icon flaticon-tube"></i>
                <p>Model Exam</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/question-sets`} className="nav-link">
                <i 
                className="nav-icon fas fa-edit" 
                style={{ fontSize: '18px', marginLeft: '20px', marginRight: '10px', color: '#4a4a4a' }}></i>
                <p>Question Sets</p>
              </NavLink>
            </li>
            {moduleId !== 'nmcle' && (
              <li className="nav-item has-treeview">
                <NavLink to={`/${moduleId}/video`} className="nav-link">
                  <i className="nav-icon flaticon-play"></i>
                  <p>Video</p>
                </NavLink>
              </li>
            )}
            <li className="nav-item has-treeview d-block d-md-none">
              <NavLink to={`/${moduleId}/ar`} className="nav-link">
                <i className="nav-icon flaticon-3d"></i>
                <p>3D Learn</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/discussion`} className="nav-link">
                <i className="nav-icon  flaticon-comment"></i>
                <p>Discussion</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/revision`} className="nav-link">
                <i className="nav-icon flaticon-revision"></i>
                <p>Revision</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/live`} className="nav-link">
                <i
                  className="nav-icon fas fa-broadcast-tower"
                  style={{ fontSize: '18px', marginLeft: '20px', marginRight: '10px', color: '#4a4a4a' }}
                ></i>
                <p>Live</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <Link className="nav-link" data-widget="pushmenu" to="#" onClick={logout}>
                <i className="nav-icon  flaticon-logout"></i>
                <p>Logout</p>
              </Link>
            </li>
          </ul>
          <div className="d-flex align-items-end px-3 py-4 position-fixed" style={{ bottom: 0 }}>
            <p className="text-muted small fw-500">
              <span className="avyaas-section">Powered by</span> Avyaas<sup>TM</sup>
            </p>
          </div>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;

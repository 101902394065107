import React, { useContext } from 'react';
import { TestContext } from '../../context/TextContext';
import MemoizedStatus from './TestStatus';

function TestSidebar() {
  const [{ questions, testId, checkedCount, markCount }] = useContext(TestContext);

  return (
    <aside className="control-sidebar control-sidebar-light">
      <div className="pl-2 pr-1">
        <h6 className="font-weight-bold" style={{ whiteSpace: 'nowrap' }}></h6>
        {/* <div className="line" />
        <div className="user-panel mt-3 pb-3 mb-3 d-flex py-1 px-1">
          <div
            className="brand-link brand-image img-circle elevation-3"
            style={{ maxHeight: "36px" }}
          >
            <p>{currentUser && currentUser.name.substring(0, 1)}</p>
          </div>
          <div className="info">
            <p>{currentUser && currentUser.email}</p>
          </div>
        </div> */}

        {/* timer function commented */}
        {/* <div
          style={{ textAlign: "left", paddingBottom: "10px", fontSize: "14px" }}
        >
          <i className="fa fa-clock-o" />
          &nbsp;Time Left:
          <span
            className="badge badge-danger"
            id="atimer"
            style={{ fontSize: "" }}
          >
            {end &&
              <TestCounter end={end} callback={() => setTimeout(handleSubmit("Time has been completed. So, test has been sucessfully submitted automatically."), 100)} />}
          </span>
        </div> */}
        <div className="help-texts mb-2">
          <div className="row d-flex" style={{ fontSize: '14px' }}>
            <div>
              <i className="fa fa-circle" style={{ color: '#6c757d' }} />
              &nbsp;
              <span className="text-muted small" id="aremainCnt">
                {questions && questions.length - checkedCount}&nbsp;unanswered
              </span>
            </div>

            <div>
              <i className="fa fa-circle" style={{ paddingLeft: '10px', color: '#20c997' }} />
              &nbsp;
              <span className="text-muted small" id="aattemptCnt">
                {checkedCount}&nbsp;answered
              </span>
            </div>

            <div>
              <i className="fa fa-circle" style={{ paddingLeft: '10px', color: '#FFAD3B' }} />
              &nbsp;
              <span className="text-muted small" id="abookmarkCnt">
                {markCount}&nbsp;marked
              </span>
            </div>

            {/* <i
                      className="fa fa-circle"
                      style={{ paddingLeft: "10px", color: "#f5378e" }}
                    />
                    &nbsp;
                    <span className="text-muted small" id="aflagCnt">
                      {flagCount}&nbsp;flagged
                    </span> */}
          </div>
        </div>

        <div>
          <div className="w-100">
            <h6 className="font-weight-bold">Questions</h6>
          </div>

          <div className="d-flex flex-wrap justify-content-start mb-3 quizCard">
            {questions.map((q, index) => (
              <MemoizedStatus key={index} testId={testId} q={q} index={index} />
            ))}
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      {/* <div>
          <div style={{ fontSize: "16px" }}>
          <span className="mb-2">
            <Link to="#">Help</Link> | <Link to="#">Instructions</Link>
          </span>
        </div> */}
    </aside>
  );
}

export default TestSidebar;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useModuleContext } from "context/ModuleProvider";

function DashboardModal({ handleFilter, type }) {
  const {  url } = useModuleContext();
  const [options, setOptions] = useState([]);
  const [subjectId, setSubjectId] = useState(-1);
  const [correct, setCorrect] = useState(false);
  const [wrong, setWrong] = useState(false);

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/subject`,
      withCredentials: true
    }).then(res => {
      setOptions(res.data.subjects);
    });
  }, [url]);


  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5 className="modal-title" id="exampleModalLongTitle">
              <i className="fa fa-filter" />
              &nbsp;Filters
            </h5>
            {type === "taq" &&
              <React.Fragment>
                <div
                  className="text-muted"
                  style={{ fontSize: "12px", paddingBottom: "15px" }}
                >
                  ONLY SHOW QUESTIONS FROM
            </div>
                <div style={{ paddingBottom: "20px" }}>
                  <div className="row">
                    <div className="col-sm-9 col-9">
                      <h6>Total Correct Answered</h6>
                    </div>
                    <div className="col-sm-3 col-3">
                      <button
                        type="button"
                        id="correct"
                        className="btn btn-toggle"
                        data-toggle="button"
                        aria-pressed={correct}
                        autoComplete="off"
                        onClick={() => setCorrect(!correct)}
                      >
                        <div className="handle" />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-9 col-9">
                      <h6>Total Wrong Answered</h6>
                    </div>
                    <div className="col-sm-3 col-3">
                      <button
                        type="button"
                        id="wrong"
                        className="btn btn-toggle"
                        data-toggle="button"
                        aria-pressed={wrong}
                        autoComplete="off"
                        onClick={() => setWrong(!wrong)}
                      >
                        <div className="handle" />
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>}
            <div
              className="text-muted"
              style={{ fontSize: "12px", paddingBottom: "15px" }}
            >
              ONLY SHOW QUESTIOINS FROM
            </div>
            <div style={{ paddingBottom: "20px" }}>
              <div className="row">
                <div className="col-sm-6 col-6" style={{ paddingTop: "10px" }}>
                  <h6>Selected Subject</h6>
                </div>
                <div className="col-sm-6 col-6">
                  <select
                    name="subject"
                    id="subject"
                    className="form-control subject"
                    required
                    defaultValue={subjectId}
                    onChange={e => setSubjectId(e.target.value)}
                  >
                    <option key={-1} value={-1}>
                      Select
                    </option>
                    {options.map(o => (
                      <option key={o.subject_id} value={o.subject_id}>
                        {o.subject_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="pull-right">
              <button
                type="button"
                className="btn btn-secondary btn-round mx-2"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => handleFilter(subjectId, correct, wrong)}
                // id="submit_filter"
                className="btn btn-success"
                data-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DashboardModal, () => {
  return false;
});

import React, { useState } from "react";

export const NetworkContext = React.createContext();

const NetworkContextProvider = ({ children }) => {
  const [online, setOnline] = useState(navigator.onLine);

  const updateIndicator = () => {
    setOnline(navigator.onLine);
  };

  window.addEventListener("online", updateIndicator);
  window.addEventListener("offline", updateIndicator);

  return (
    <NetworkContext.Provider
      value={{
        online
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export default NetworkContextProvider;

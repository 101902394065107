import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bootbox from 'bootbox';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useModuleContext } from '../../context/ModuleProvider';

function Notification() {
  const server = process.env.REACT_APP_SERVER_URL;
  const { moduleId } = useModuleContext();
  const [notifications, setNotifications] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [finish, setFinish] = useState(false);
  let perPage = 10;

  const fetchNotifications = () => {
    setLoading(true);
    let finalUrl = `${server}/${moduleId}/notification/all?limit=${perPage}&offset=${perPage * (page - 1)}`;
    axios({
      method: 'get',
      url: `${finalUrl}`,
    }).then((res) => {
      setLoading(false);
      setNotifications(res.data.notifications);
      if (res.data.notifications.length < 10) {
        setFinish(true);
      }
    });
  };

  const loadMore = async (e) => {
    e.preventDefault();
    setLoadingMore(true);
    setPage(page + 1);
    let finalUrl = `${server}/${moduleId}/notification/all?limit=${perPage}&offset=${perPage * page}`;
    axios({
      method: 'get',
      url: `${finalUrl}`,
    }).then((res) => {
      setNotifications([...notifications, ...res.data.notifications]);
      if (res.data.notifications.length < 10) {
        setFinish(true);
      }
    });
    setLoadingMore(false);
  };

  const handleRead = async (e, id, read) => {
    e.preventDefault();
    if (!read) {
      await readNotification(id);
    }
  };

  const readNotification = async (id) => {
    let finalUrl = `${server}/${moduleId}/notification/read/${id}`;
    axios({
      method: 'get',
      url: `${finalUrl}`,
    }).then((res) => {
      if (res.data.notification) {
        let newNotification = notifications.map((notification) => {
          if (notification.id === id) {
            return res.data.notification;
          } else {
            return notification;
          }
        });
        setNotifications(newNotification);
      }
    });
  };

  const deleteNotification = async (e, id) => {
    e.preventDefault();
    let finalUrl = `${server}/${moduleId}/notification/${id}`;
    bootbox.confirm({
      size: 'small',
      title: '',
      message: 'Are you sure you want to delete the notification?',
      callback: function (result) {
        if (result) {
          axios({
            method: 'delete',
            url: `${finalUrl}`,
          }).then((res) => {
            if (res.data.deleted) {
              let newNotification = notifications.filter((notification) => {
                return notification.id !== id;
              });
              setNotifications(newNotification);
              bootbox.alert('Notification deleted successfully.');
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleScroll = () => {
    if (!finish && !loading) {
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return window.removeEventListener('scroll', handleScroll);
  }, []);

  let getItem = (type) => {
    switch (type) {
      case 'error':
        return {
          color: 'red',
          icon: <i style={{ color: 'red' }} className="fas fa-exclamation-circle"></i>,
        };
      case 'warning':
        return {
          color: 'orange',
          icon: <i style={{ color: 'orange' }} className="fas fa-exclamation-triangle"></i>,
        };
      case 'success':
        return { color: 'blue', icon: <i style={{ color: 'blue' }} className="fas fa-check-square"></i> };
      case 'info':
        return { color: 'green', icon: <i style={{ color: 'green' }} className="fas fa-question-circle"></i> };
      default:
        return { color: 'green', icon: <i class="fas fa-question-circle"></i> };
    }
  };

  return (
    <div style={{ margin: '0 auto', maxWidth: '800px' }}>
      <div className="">
        <h4>Notification</h4>
        <div>
          {loading && <div className="center">Loading...</div>}

          {!loading && notifications && notifications.length === 0 && (
            <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '160px' }} className=" center">
              There is not any notification currently.
              <br /> Please check later.
            </div>
          )}

          {notifications &&
            notifications.map((notification, index) => {
              let { id, title, message, created_at, type, read, expireDate } = notification;
              return (
                <div
                  key={index}
                  onDoubleClick={(e) => handleRead(e, id, read)}
                  style={{
                    width: '100%',
                    backgroundColor: !read ? '#eff6ff' : 'white',
                    borderLeft: `8px solid ${getItem(type).color}`,
                    padding: '10px 10px 10px 0px',
                  }}
                  className={`card d-flex flex-row`}
                >
                  <div style={{ fontSize: '36px', margin: '0px 30px' }}>{getItem(type).icon}</div>
                  <div style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-baseline">
                        <h5>{title}</h5>
                        <div style={{ color: 'gray' }}>{moment.utc(created_at).fromNow()}</div>
                      </div>

                      <div className="dropdown">
                        <button
                          className="btn btn-transparent dropdown-toggle"
                          type="button"
                          id={`notificationMenu${id}`}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fas fa-ellipsis-v" />
                        </button>
                        <div className="dropdown-menu" aria-labelledby={`notificationMenu${id}`}>
                          <div className="dropdown-item" onClick={(e) => deleteNotification(e, id)}>
                            <i className="far fa-trash-alt mr-2"></i>
                            Delete
                          </div>
                          {!read && (
                            <div className="dropdown-item" onClick={(e) => handleRead(e, id, read)}>
                              <i className="far fa-eye mr-1"></i> Mark as read
                            </div>
                          )}
                          <Link className="dropdown-item" to="#">
                            <i className="far fa-flag mr-2"></i> Report
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div style={{ color: 'gray' }}>{message}</div>

                    <div style={{ fontSize: '12px', fontWeight: '500' }} className="pt-1">
                      {moment.utc(created_at).toLocaleString().slice(0, 24)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="d-flex justify-content-center">
        {!loading && !finish && (
          <button onClick={loadMore} className="btn btn-primary">
            {!loadingMore ? 'Load More' : 'Loading More'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Notification;

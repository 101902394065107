import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M22.1 13.7H2.39999C1.79999 13.7 1.39999 13.3 1.39999 12.7C1.39999 12.1 1.79999 11.7 2.39999 11.7H22.1C22.7 11.7 23.1 12.1 23.1 12.7C23.1 13.3 22.7 13.7 22.1 13.7Z"
        fill="#2B4832"
      />
      <path
        d="M14.5 10.8C14.2 10.8 14 10.7 13.8 10.5C13.4 10.1 13.4 9.49998 13.8 9.09998L19.5 3.39998C19.9 2.99998 20.5 2.99998 20.9 3.39998C21.3 3.79998 21.3 4.39998 20.9 4.79998L15.2 10.5C15 10.7 14.8 10.8 14.5 10.8Z"
        fill="#2B4832"
      />
      <path
        d="M21.7 3.6C21.4 3.6 21.2 3.5 21 3.3C20.6 2.9 20.6 2.3 21 1.9L21.6 1.3C22 0.9 22.6 0.9 23 1.3C23.4 1.7 23.4 2.3 23 2.7L22.4 3.3C22.2 3.5 22 3.6 21.7 3.6Z"
        fill="#2B4832"
      />
      <path
        d="M15.5 23.3H8.89999C8.39999 23.3 7.89999 22.9 7.89999 22.3C7.89999 21.7 8.29999 21.3 8.89999 21.3H15.5C16 21.3 16.5 21.7 16.5 22.3C16.5 22.9 16.1 23.3 15.5 23.3Z"
        fill="#42C074"
      />
      <path
        d="M12.2 21.2C6 21.2 1 16.1 1 9.90002C1 9.40002 1.4 8.90002 2 8.90002H22.5C23 8.90002 23.5 9.30002 23.5 9.90002C23.5 12.4 22.7 14.7 21.2 16.7C20.9 17.1 20.3 17.2 19.8 16.9C19.4 16.6 19.3 16 19.6 15.5C20.6 14.2 21.2 12.6 21.4 10.9H3C3.5 15.5 7.4 19.2 12.2 19.2C14.3 19.2 16.4 18.5 18.1 17.1C18.5 16.8 19.1 16.8 19.5 17.2C19.8 17.6 19.8 18.2 19.4 18.6C17.4 20.3 14.8 21.2 12.2 21.2Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.5"
          height="22.3"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

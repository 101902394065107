import React, { useContext, useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import { useForm } from 'react-hook-form';

function RegisterFrom({ history }) {
  const { registerUser, verifyUser } = useContext(AuthContext);
  const { register, handleSubmit, errors, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [send, setSend] = useState(false);

  const submitForm = async (data, e) => {
    e.preventDefault();
    if (!send) {
      setLoading(true);
      data.name = data.email.split('@')[0];
      const res = await registerUser(data);
      setLoading(false);
      if (res.data.error) {
        return setError(res.data.message);
      } else {
        setSend(true);
      }
    } else {
      const res = await verifyUser(data, history);
    }
  };
  return (
    <form className="authForm" id="registerForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        {/* <div className="back-element d-sm-none d-block " style={{ position: "relative", padding: "15px 10px" }}>
          <Link to="/" className="text-dark "><i className="fa fa-long-arrow-left"></i>&nbsp;BACK</Link>
        </div> */}
        <div
          className="back-element d-block"
          style={{ position: 'relative', padding: '15px 0px', fontSize: '18px', color: 'black' }}
        >
          <Link class="text-muted" to="/login">
            <i class="fas fa-arrow-left" style={{ color: 'black' }}></i>
          </Link>
        </div>
        <div className="overlay__img d-lg-none d-md-none d-none">
          <img style={{ position: 'relative' }} alt="Avyaas Logo" src="/images/logo.png" />
        </div>

        <h2 className="welcom pb-0 mb-0" style={{ padding: '10px 0px 0px' }}>
          Welcome!
        </h2>
        <p className="small text-muted">Please fill the information below to sign up.</p>
      </div>
      <div className="error">{error}</div>

      <div className={errors.email ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Email is invalid',
            },
            maxLength: {
              value: 254,
              message: 'Email should be not more than length of 254',
            },
          })}
          disabled={send}
          name="email"
          type="email"
          id="name"
          placeholder="Email"
        />
        <label className="input-icon-label" htmlFor="email">
          <i class="far fa-envelope"></i>
        </label>
      </div>
      <div className="input-error">{errors.email && errors.email.message}</div>

      <div className={errors.phone ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: 'Phone number is required',
            minLength: {
              value: 10,
              message: 'Invalid phone number',
            },
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              message: 'Invalid phone number',
            },
          })}
          name="phone"
          type="phone"
          disabled={send}
          id="phone"
          placeholder="Phone number"
        />
        <label className="input-icon-label" htmlFor="email">
          <i class="fas fa-mobile-alt"></i>
        </label>
      </div>
      <div className="input-error">{errors.phone && errors.phone.message}</div>

      <div className={errors.collegename ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: false,
            maxLength: {
              value: 254,
              message: 'College Name should be not more than length of 254',
            },
          })}
          disabled={send}
          name="collegename"
          type="text"
          id="collegename"
          placeholder="College Name"
        />
        <label className="input-icon-label" htmlFor="collegename">
          <i class="fa fa-graduation-cap" aria-hidden="true"></i>
        </label>
      </div>
      <div className="input-error">{errors.collegename && errors.collegename.message}</div>

      <div className={errors.address ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: false,
            maxLength: {
              value: 254,
              message: 'Address should be not more than length of 254',
            },
          })}
          disabled={send}
          name="address"
          type="text"
          id="address"
          placeholder="Address"
        />
        <label className="input-icon-label" htmlFor="address">
          <i class="fa fa-map-marker"></i>
        </label>
      </div>
      <div className="input-error">{errors.address && errors.address.message}</div>

      {send && (
        <div className={errors.token ? 'errorfield inputGroup' : 'inputGroup'}>
          <input
            ref={register({ required: 'Otp code is required' })}
            name="token"
            type="text"
            id="token"
            placeholder="Otp code"
          />
          <label className="input-icon-label" htmlFor="token">
            <i class="fas fa-mobile-alt"></i>
          </label>
        </div>
      )}

      <div className="inputGroup d-inline ">
        <div className="btn btn-block p-0 my-2 pt-3">
          <button id="register" className="btn btn-dark rounded shadow register modified">
            {loading ? 'Loading' : 'Register'}
          </button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(RegisterFrom);

import React from "react";
import ReactMarkdown from "react-markdown";

function PracticeResultCard({ question, count, index }) {
  let { answer } = question;
  let selected = (question.result && question.result.check) || null;
  console.log(`option${answer}`);
  return (
    <div className="col-12 px-sm-2 px-0">
      <div className="card border-none" style={{ boxShadow: "none" }}>
        <div className="radius-15">
          {question && (
            <form  className="radius-15" style={{ border: "none" }}>
              <div className="test-question">
                <span>
                  Test Question {index} of {count}
                </span>
                <h3 className="card-title pb-2">
                  <ReactMarkdown
                    source={question.question}
                    escapeHtml={false}
                  />
                </h3>
                <small className="text-muted">
                  Choose the correct answer below: &nspbs;
                </small>
              </div>
              <Option
                value={1}
                name="A"
                option={question.optionA}
                selected={selected}
                answer={answer}
              />
              <Option
                value={2}
                name="B"
                option={question.optionB}
                selected={selected}
                answer={answer}
              />
              <Option
                value={3}
                name="C"
                option={question.optionC}
                selected={selected}
                answer={answer}
              />
              <Option
                value={4}
                name="D"
                option={question.optionD}
                selected={selected}
                answer={answer}
              />
              <br />
              <div className="boxed">
                <div>
                  <strong style={{ color: "#2ecc71" }}>
                    The correct answer is:
                  </strong>
                  <ReactMarkdown
                    source={question[getOptionByValue(answer - 1)]}
                    escapeHtml={false}
                  />
                </div>
                <div>
                  <ReactMarkdown
                    source={question.description}
                    escapeHtml={false}
                  />
                </div>
              </div>
              <br />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default PracticeResultCard;

function Option({ value, option, selected, answer, name }) {
  let wrong = selected === value && answer !== name;
  let right = answer === value;
  let addClass = wrong ? "wrong" : "";
  addClass = right ? "correct" : addClass;
  let id = "answer" + value;
  return (
    <div className={`form-check ${addClass}`}>
      <input
        className="col-2 form-check-input option-input radio answer"
        type="radio"
        name="answer"
        id={id}
        defaultValue={value}
        checked={value === selected}
        disabled={true}
      />
      <label className="col-10 form-check-label" htmlFor={id}>
        <ReactMarkdown source={option} escapeHtml={false} />
      </label>
    </div>
  );
}

const getOptionByValue = (value) => {
  const options = ["optionA", "optionB", "optionC", "optionD"];
  return options[value];
};

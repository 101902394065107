import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import bootbox from 'bootbox';
import queryString from 'query-string';
import Swiper from 'swiper';
import moment from 'moment';
import VideoComment from './VideoComment';
import { getProfileLink, concatText } from '../helper';
import VideoResource from './VideoResource';
import { Tab, TabItems, TabContents, TabItem, TabContent } from '../ui/Tab';
import ShareButton from '../ui/ShareButton';
import { useModuleContext } from 'context/ModuleProvider';
import ReactMarkdown from 'react-markdown';
import Plyr from 'plyr-react';
import { useAuthContext } from '../../context/AuthContextProvider';

function VideoSingle({ location }) {
  let { moduleId, url } = useModuleContext();
  let { currentUser, streakUpdated, setStreakUpdated } = useAuthContext();
  const { id, subjectId } = queryString.parse(location.search);
  const [video, setVideo] = useState(null);
  const [next, setNext] = useState(null);
  const shareUrl = `${url}/video/single?id=${id}&subjectId=${subjectId}`;

  const fetchVideo = () => {
    let finalUrl = `${url}/video/single?id=${id}&subjectId=${subjectId}`;
    axios({
      method: 'get',
      url: finalUrl,
      withCredentials: true,
    }).then((res) => {
      if (res.data.error) {
        bootbox.alert(res.data.error);
      }
      setVideo(res.data.video);
      setNext(res.data.next);
      handleUpdateStreak();
    });
  };
  useEffect(() => {
    fetchVideo();
    new Swiper('.swiper-container', {
      slidesPerView: 3,
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }, [id, subjectId]);

  const handleUpdateStreak = () => {
    if (currentUser && !streakUpdated) {
      axios.post(`${url}/user-streak?userId=${currentUser.id}`).then(() => {
        setStreakUpdated(true);
      });
    }
  };

  return (
    <div className="row" style={{ marginTop: '-13px' }}>
      <div className="col-sm-12 col-12 order-first order-sm-1 px-1">
        {video ? (
          <div className="row">
            <div className="col-sm-8  px-0 col-12">
              <div className="pr-sm-0 bg-white radius-15">
                <VideoDisplay key={video.id} video={video} moduleId={moduleId} />
                <div className="videoInstructions bg-white px-sm-3 px-2 pt-sm-3 pt-2 radius-15">
                  <div className="v-title">
                    <h5 className="d-inline-block">{video.title}</h5>
                    <div className="float-right">
                      <ShareButton size={22} title={video.title} url={shareUrl} />
                    </div>
                  </div>
                  <div className="v-author mb-2">
                    <img
                      src={getProfileLink(video.user.imageId)}
                      alt=""
                      className="img-circle circle d-inline-block bg-cover m-0"
                    />
                    <div className="d-inline-block pl-1">
                      <p className="text-muted">{video.user.name}</p>
                    </div>
                  </div>
                  <div className="row justify-content-between text-center pb-4 pt-1 d-sm-flex">
                    <div className="col-sm-4 col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">{video.level}</div>
                        Category
                      </div>
                    </div>
                    <div className="col-sm-4  col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">
                          {moment.utc(video.length * 1000).format('HH:mm:ss')}
                        </div>
                        Duration
                      </div>
                    </div>
                    <div className="col-sm-4  col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">{video.view}</div>
                        Views
                      </div>
                    </div>
                  </div>
                  {/* <div className="v-author-sub">
                    <div className="spoiler">
                      <p className="hidden-text">{video.description}</p>
                    </div>
                  </div> */}
                  <VideoOption video={video} moduleId={moduleId} next={next} />
                </div>
              </div>
            </div>
            {window.innerWidth > 574 && (
              <div className="video-sidebar col-sm-4  px-sm-2 px-0">
                <Tab>
                  <TabItems tabId="course">
                    <TabItem id="nextClass" title={`All Classes (${next && next.length})`} selected={true} />
                    <TabItem id="discussion" title="Discussions" />
                  </TabItems>
                  <TabContents tabId="course">
                    <TabContent id="nextClass" selected={true}>
                      <NextVideo moduleId={moduleId} next={next} />
                    </TabContent>
                    <TabContent id="discussion">
                      <VideoComment />
                    </TabContent>
                  </TabContents>
                </Tab>
              </div>
            )}
          </div>
        ) : (
          'Loading...'
        )}
      </div>
    </div>
  );
}

function VideoOption({ video, next, moduleId }) {
  let mobile = window.innerWidth < 574;
  return (
    <>
      {!mobile ? (
        <Tab>
          <TabItems tabId="sidebar">
            <TabItem id="classDetail" title="Class details" selected={true} />
            <TabItem id="resources" title="Resources" />
          </TabItems>

          <TabContents tabId="sidebar">
            <TabContent id="classDetail" selected={true}>
              <div className="class_details_instructions">
                <p className="m-2 pl-1 my-3">
                  <ReactMarkdown source={video.detail} escapeHtml={false} />
                </p>
              </div>
            </TabContent>
            <TabContent id="resources">
              {video && video.resources && <VideoResource resources={video.resources} />}
            </TabContent>
          </TabContents>
        </Tab>
      ) : (
        <Tab>
          <TabItems className="nav-fill" tabId="sidebar">
            <TabItem id="nextClass" icon="far fa-2x px-2 text-center fa-play-circle" selected={true} />
            <TabItem id="classDetail" icon="fab fa-ioxhost fa-2x px-2 text-center" />
            <TabItem id="resources" icon="fab fa-2x px-2 text-center fa-audible" />
            <TabItem id="discussion" icon="far fa-2x px-2 text-center fa-comments" />
          </TabItems>

          <TabContents tabId="sidebar">
            <TabContent id="classDetail" selected={true}>
              <div className="class_details_instructions">
                <p className="m-2 pl-1 my-3">{video.detail}</p>
              </div>
            </TabContent>
            <TabContent id="resources">
              {video && video.resources && <VideoResource resources={video.resources} />}
            </TabContent>
            <TabContent id="nextClass">
              <NextVideo moduleId={moduleId} next={next} />
            </TabContent>
            <TabContent id="discussion">
              <VideoComment />
            </TabContent>
          </TabContents>
        </Tab>
      )}
    </>
  );
}

function NextVideo({ next, moduleId }) {
  return (
    <div className="video-lists">
      <div className="video-contents">
        <div className="video-containers">
          {next && next.length !== 0
            ? next.map((video) => {
                return <VideoSmall key={video.id} video={video} moduleId={moduleId} />;
              })
            : 'There are no more videos to show at the moment. Please check back again later.'}
        </div>
      </div>
    </div>
  );
}

function VideoDisplay({ moduleId, video }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let link = video.link ? video.signedUrl : `${serverUrl}/videos/${video.id}.mp4`;

  return (
    <div className="radius-15" style={{ overflow: 'hidden' }}>
      {video.link && video.link.includes('youtube') ? (
        <div class="yt_container">
          <iframe
            sandbox="allow-same-origin allow-scripts"
            title={video.title}
            width="100%"
            height={500}
            src={`//www.youtube.com/embed/${video.link.split('=')[1]}`}
            allowFullScreen
            className="yt_video"
          ></iframe>
        </div>
      ) : (
        <Plyr
          controls
          preload="auto"
          width={640}
          height={264}
          source={{
            type: 'video',
            sources: [
              {
                src: link,
                type: 'video/mp4',
                size: 720,
              },
            ],
            poster: `${serverUrl}/images/videos/${video.id}.png`,
          }}
          options={{
            disableContextMenu: true,
          }}
        />
      )}
    </div>
  );
}

function VideoSmall({ video, moduleId }) {
  let server = process.env.REACT_APP_SERVER_URL;
  let { id, title, length } = video;
  return (
    <Link className="d-block py-2" to={`/${moduleId}/video/single?id=${id}`}>
      <div className="row video-card lists">
        <div className="col-3 pl-0 pl-sm-2">
          <span className="spinner-grow spinner-grow-sm" />
          <img
            src={`${server}/images/videos/${id}.jpg`}
            alt="video"
            className="img-rounded"
            style={{ height: '50px' }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/defaultthumbnail.jpg';
            }}
          />
        </div>
        <div className="col-9">
          <h6 className="video-title mb-0 d-block">{concatText(title, 70)}</h6>
          <small className="text-muted d-block pl-0">
            <i className="far fa-clock"></i>&nbsp;{getDuration(length)}
          </small>
        </div>
      </div>
    </Link>
  );
}

const getDuration = (length) => {
  return moment.utc(length * 1000).format('HH:mm:ss');
};

export default withRouter(VideoSingle);

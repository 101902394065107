import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.6917 6.14579C11.1167 6.14579 10.7333 5.72913 10.7333 5.10413V2.08329C10.7333 1.45829 11.1167 1.04163 11.6917 1.04163C12.2667 1.04163 12.65 1.45829 12.65 2.08329V5.10413C12.65 5.72913 12.2667 6.14579 11.6917 6.14579Z"
        fill="#2B4832"
      />
      <path
        d="M6.51667 6.14579C5.94167 6.14579 5.55833 5.72913 5.55833 5.10413V2.08329C5.55833 1.45829 5.94167 1.04163 6.51667 1.04163C7.09167 1.04163 7.475 1.45829 7.475 2.08329V5.10413C7.475 5.72913 7.09167 6.14579 6.51667 6.14579Z"
        fill="#2B4832"
      />
      <path
        d="M11.9792 17.2916C11.8833 17.2916 11.8833 17.2916 11.9792 17.2916C11.5 17.2916 11.1167 16.9791 10.925 16.4583L9.58333 11.875L9.29583 12.9166C9.19999 13.4375 8.72083 13.75 8.24166 13.75H2.01249C1.43749 13.75 0.958328 13.2291 0.958328 12.6041C0.958328 11.9791 1.43749 11.4583 2.01249 11.4583H7.37916L8.43333 7.49996C8.52916 6.97913 9.00833 6.66663 9.48749 6.66663C9.96666 6.66663 10.35 6.97913 10.5417 7.49996L12.075 12.7083L13.4167 8.85413C13.6083 8.43746 13.9917 8.12496 14.4708 8.12496C14.95 8.12496 15.3333 8.43746 15.525 8.85413L16.4833 11.5625H18.0167C18.5917 11.5625 19.0708 12.0833 19.0708 12.7083C19.0708 13.3333 18.5917 13.8541 18.0167 13.8541H15.7167C15.2375 13.8541 14.8542 13.5416 14.6625 13.125L14.4708 12.5L12.9375 16.5625C12.7458 17.0833 12.3625 17.2916 11.9792 17.2916Z"
        fill="#2B4832"
      />
      <path
        d="M16.8667 6.14579C16.2917 6.14579 15.9083 5.72913 15.9083 5.10413V2.08329C15.9083 1.45829 16.2917 1.04163 16.8667 1.04163C17.4417 1.04163 17.825 1.45829 17.825 2.08329V5.10413C17.825 5.72913 17.4417 6.14579 16.8667 6.14579Z"
        fill="#2B4832"
      />
      <path
        d="M20.4125 23.7048H2.97083C2.39583 23.7048 2.0125 23.2881 2.0125 22.6631V14.6423C2.0125 14.0173 2.39583 13.6006 2.97083 13.6006C3.54583 13.6006 3.92916 14.0173 3.92916 14.6423V21.6214H19.4542V3.80892H19.0708C18.4958 3.80892 18.1125 3.39225 18.1125 2.76725C18.1125 2.14225 18.4958 1.72559 19.0708 1.72559H20.4125C20.9875 1.72559 21.3708 2.14225 21.3708 2.76725V22.6631C21.3708 23.2881 20.9875 23.7048 20.4125 23.7048Z"
        fill="#42C074"
      />
      <path
        d="M14.8542 3.91304H13.8C13.225 3.91304 12.8417 3.49638 12.8417 2.87138C12.8417 2.24638 13.225 1.82971 13.8 1.82971H14.8542C15.4292 1.82971 15.8125 2.24638 15.8125 2.87138C15.8125 3.49638 15.3333 3.91304 14.8542 3.91304Z"
        fill="#42C074"
      />
      <path
        d="M2.97083 10.4756C2.39583 10.4756 2.0125 10.0589 2.0125 9.43392V2.76725C2.0125 2.14225 2.39583 1.72559 2.97083 1.72559H4.3125C4.8875 1.72559 5.27083 2.14225 5.27083 2.76725C5.27083 3.39225 4.8875 3.80892 4.3125 3.80892H3.92916V9.43392C3.92916 9.95475 3.54583 10.4756 2.97083 10.4756Z"
        fill="#42C074"
      />
      <path
        d="M9.67916 3.91305H8.62499C8.04999 3.91305 7.66666 3.49638 7.66666 2.87138C7.66666 2.24638 8.04999 1.82971 8.62499 1.82971H9.67916C10.2542 1.82971 10.6375 2.24638 10.6375 2.87138C10.6375 3.49638 10.1583 3.91305 9.67916 3.91305Z"
        fill="#42C074"
      />
      <path
        d="M7.66666 20.9964C7.09166 20.9964 6.70833 20.5797 6.70833 19.9547V15.6839C6.70833 15.0589 7.09166 14.6422 7.66666 14.6422C8.24166 14.6422 8.62499 15.0589 8.62499 15.6839V19.9547C8.62499 20.4755 8.14583 20.9964 7.66666 20.9964Z"
        fill="#42C074"
      />
      <path
        d="M9.58333 18.8089H5.65416C5.07916 18.8089 4.69583 18.3923 4.69583 17.7673C4.69583 17.1423 5.07916 16.7256 5.65416 16.7256H9.58333C10.1583 16.7256 10.5417 17.1423 10.5417 17.7673C10.5417 18.3923 10.0625 18.8089 9.58333 18.8089Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20.4125"
          height="23.3333"
          fill="white"
          transform="translate(0.958328 1.04163)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bootbox from 'bootbox';
import PollCard from '../PollCard';
import Nodata from '../../ui/Nodata';
import { useAuthContext } from '../../../context/AuthContextProvider';

function RecentPollTap({ moduleId }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { currentUser } = useAuthContext();
  const [poll, setPoll] = useState(null);
  let url = `${server}/${moduleId}/poll?recent=1`;

  useEffect(() => {
    axios({
      method: 'get',
      url,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setPoll(res.data.poll);
    });
  }, []);

  const votePoll = (pollId, pollOptionId) => {
    let data = { pollId, pollOptionId };
    axios({
      method: 'post',
      url: `${server}/${moduleId}/poll/vote`,
      withCredentials: true,
      data,
    }).then((res) => {
      let newPoll = poll.map((p) => {
        if (p.id === pollId) {
          p = res.data.poll;
        }
        return p;
      });
      setPoll(newPoll);
    });
  };

  const deletePoll = (e, pollId) => {
    e.preventDefault();
    axios({
      method: 'delete',
      url: `${server}/${moduleId}/poll/${pollId}`,
      withCredentials: true,
    }).then((res) => {
      let newPoll = poll.filter((p) => p.id !== pollId);
      setPoll(newPoll);
      bootbox.alert('Poll deleted successfully.');
    });
  };

  return (
    <>
      {poll ? (
        poll.length !== 0 ? (
          poll.map((p) => (
            <PollCard key={p.id} poll={p} votePoll={votePoll} userId={currentUser.id} deletePoll={deletePoll} />
          ))
        ) : (
          <Nodata title="You have not posted the poll yet." src="/images/noComment.jpg" />
        )
      ) : (
        'Loading...'
      )}
    </>
  );
}

export default RecentPollTap;

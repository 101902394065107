import React, { useState, useRef } from 'react'
import queryString from "query-string";
import { Link, withRouter } from "react-router-dom";
import { useForm }  from "react-hook-form";
import axios from "axios";
import SocialLogin from './SocialLogin';

function ResetForm({ history, location }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  const [type, setType] = useState(1);
  const [info, setInfo] = useState({ error: null, loading: false });
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const submitForm = async data => {
    const token = queryString.parse(location.search).token;
    setInfo({ loading: true })
    const res = await axios({
      method: "post",
      url: `${serverUrl}/auth/reset?token=${token}`,
      withCredentials: true,
      data
    })
    console.log(res.data, errors);
    setInfo({ loading: false, error: res.data.message || "" })
    return;
  };
  let { loading, error } = info;
  return (
    <form className="authForm" id="loginForm" onSubmit={handleSubmit(submitForm)}
    >
      <div className="instructions__login">
        <div className="overlay__img d-lg-none d-md-none d-">
          <Link to="/">
            <img style={{ position: "relative" }} alt="Avyaas Logo" src="/images/logo.png" />
          </Link>
        </div>
        <h2 style={{ padding: "10px" }} className="d-flex">
          <Link to="/login" className="back-link" >
            <i className="fa fa-chevron-left" />
          </Link>
          Reset Password?</h2>
        <p className="small text-muted">Enter the new password you want to chnage.</p>
      </div>
      <div className="error" >{error}</div>
      <div className={errors.password ? "errorfield inputGroup inputGroup2" : "inputGroup inputGroup2"}>
        <input name="password" type={type ? "password" : "text"}
          ref={register({
            required: "Password is required",
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
              ,
              message: "Password should contain at least one upercase letter, number and special character"
            },
            minLength: {
              value: 8,
              message: 'Password should be at least of length 8'
            },
          })}
          id="loginPassword" placeholder="New Password" />
        <label id="showPasswordToggle" htmlFor="showPasswordCheck"><i onClick={() => setType(!type)} className="fas fa-eye" />
          <input id="showPasswordCheck" type="checkbox" placeholder="Password" />
        </label>
      </div>
      <div className="input-error">{errors.password && errors.password.message}</div>

      <div className={errors.re_pass ? "errorfield inputGroup" : "inputGroup"}>
        <input ref={register({
          required: "Confirm password is required", validate: {
            confirm: value => value === password.current || "Password do not match"
          }
        })}
          name="re_pass" type="password" id="re_pass" placeholder="Confirm Password" />
      </div>
      <div className="input-error">{errors.re_pass && errors.re_pass.message}</div>


      <div className="inputGroup pt-3 pb-3 center-block">
        <button type="submit" id="send" className="w-100 btn btn-dark rounded shadow">{loading ? "Loading..." : "Reset Password"}</button>
      </div>
      <div className="forget-footer">
        <Link to="/login">Login</Link>
        <Link to="/login/register">Register</Link>
      </div>
      <SocialLogin />
    </form>
  )
}

export default withRouter(ResetForm);

import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Status({ q, index, testId }) {

  let color = "";
  color = q.selected !== undefined ? "#20c99770" : color;
  color =
    q.flag !== undefined ? (q.recent === "flag" ? "#f5378e" : color) : color;
  color =
    q.mark !== undefined ? (q.recent === "mark" ? "var(--warning)" : color) : color;
  // console.log(color && index + 1, color && color);
  
  return (
    <Link
      className={`elevation-2 ${q.mark ? "buttonmark": "" }`}
      style={{ background: color, color: "#333", borderRadius: "3px" }}
      id={`button${q.id}`}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      type="button"
      to={`q${q.id}`}
    >
      {index + 1}
      <br />
    </Link>
  );
}

// a#button1:after {
//   content: "",
//   height: "0",
//   borderRight: "5px solid var(--warning)",
//   position: "absolute",
//   bottom: "-7px",
//   right: "-4px",
//   borderTop: "5px solid var(--warning)",
//   borderBottom: "7px solid transparent",
//   borderLeft: "5px solid var(--warning)",
//   outline: "1px solid #fff";
// }


const MemoizedStatus = React.memo(Status, (prevProps, nextProps) => {
  return (
    prevProps.q.recent === nextProps.q.recent
  );
});

export default MemoizedStatus;

import React, { useEffect } from "react";
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from "react-katex";
import { renderMathInElement } from "katex";
import MathMarkdown from "../ui/MathMarkdown";
import unified from "unified";
import parse from "remark-parse";
import katex from "rehype-katex";
import remark2react from "remark-react";
import texmath from "markdown-it-texmath";
import markdownit from "markdown-it";

{
  /* <link rel="stylesheet" type="text/css" href="./lib/katex/katex.min.css">
<link rel="stylesheet" href="./motion/style.css">
<script defer src="./motion/motionSVG.js"></script>
<script defer src='./lib/matter.min.js'></script>
<script defer src="./motion/mattersim.js"></script>
<script src='./lib/function-graph-SVG.js'></script> */
}

const article = `
  $$\Delta t = t_{f}-t_{i}$$
  $$\Delta t = 49\,\mathrm{min}-30\, \mathrm{min}$$
  $$\Delta t = 19\, \mathrm{min}$$
`;

function Detail() {
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const tm = texmath.use(katex);
      const md = markdownit().use(tm, {
        engine: katex,
        delimiters: "dollars",
        katexOptions: { macros: { "\\RR": "\\mathbb{R}" } },
      });
      document.getElementById("out").innerHTML = md.render(
        "Euler's identity $e^{ipi}+1=0$ is a beautiful formula in //RR 2."
      );
    });
  }, []);
  return (
    <div className="w-100" id="dash__wrapper">
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card" style={{ padding: "20px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    paddingBottom: "10px",
                  }}
                >
                  Kinetics
                </h3>
                {
                  unified()
                    .use(parse)
                    .use(remark2react)
                    .use(katex)
                    .processSync(article).result
                }
                {/* <MathMarkdown>{article}</MathMarkdown> */}
                <div style={{ textAlign: "center" }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Detail;

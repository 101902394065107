import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import KhaltiCheckout from 'khalti-checkout-web';

import { useModuleContext } from 'context/ModuleProvider';
import { useAuthContext } from '../../context/AuthContextProvider';

import PaymentList from './PaymentList';

import './Payment.css';

function Payment() {
  let { url, moduleId } = useModuleContext();
  let [submitting, setSubmitting] = useState(false);
  let [proceedError, setProceedError] = useState();
  const { register, handleSubmit, errors } = useForm();
  let { updateCurrentUser, currentUser } = useAuthContext();
  let clientUrl = process.env.REACT_APP_CLIENT_URL;

  let [subscriptionTypes, setSubscriptionTypes] = useState(false);
  let [selectedType, setSelectedType] = useState();
  let [selectedPayment, setSelectedPayment] = useState();

  const { state } = useLocation();
  const { id, name, price } = state;

  let esewaPath =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://esewa.com.np/epay/main'
      : 'https://uat.esewa.com.np/epay/main';
  let su = `${clientUrl}/${moduleId}/esewa/test-success`;
  let fu = `${clientUrl}/${moduleId}/payment-failed?fu=test`;

  let history = useHistory();
  let khlatiRef = useRef();

  const paymentTypes = [
    { id: 'khalti', title: 'Khalti', logo: '/images/subscribe/khalti.png' },
    { id: 'esewa', title: 'Esewa', logo: '/images/subscribe/esewa.png' },
  ];

  let time = moment().unix();
  let pid = `${id}-${currentUser.id}-${price}-${time}`;
  var params = {
    tAmt: price,
    amt: price,
    psc: 0,
    pdc: 0,
    txAmt: 0,
    pid: pid,
    scd: process.env.REACT_APP_ESEWA_SCD,
    su: su,
    fu: fu,
  };

  useEffect(() => {
    khlatiRef.current = new KhaltiCheckout(config);
  }, []);

  const handlePayment = (data) => {
    console.log('Handle Payment');

    if (data.payment === 'khalti') {
      //checkout.show({ amount: selectedType.amount * 100 });
      khaltiPaymentGateway();
    } else if (data.payment === 'esewa') {
      esewaPayment();
    }
  };

  const esewaPayment = () => {
    var form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', esewaPath);
    for (var key in params) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  };

  let config = {
    // replace this key with yours
    publicKey: process.env.REACT_APP_KHALTI_PUBLIC_KEY,
    productIdentity: '1234567892',
    productName: 'Avyaas Subscription',
    productUrl: `${clientUrl}/${moduleId}/payment`,
    eventHandler: {
      onSuccess(payload) {
        // hit merchant api for initiating verfication
        console.log(payload, 'Success');
        khaltiPayment(payload);
      },
      // onError handler is optional
      onError(error) {
        // handle errors
        console.log(error);
      },
      onClose() {
        console.log('widget is closing');
      },
    },
    paymentPreference: ['KHALTI'],
  };

  const khaltiPayment = (payload) => {
    console.log(payload, selectedType, 'Payload----------');
    setSubmitting(true);
    axios({
      method: 'post',
      url: `${url}/payment/khalti`,
      data: { payload, selectedType },
      withCredentials: true,
    }).then((res) => {
      setSubmitting(false);
      console.log(res.data, 'payment data');
      if (res.data.error) {
        setProceedError(res.data.message);
      } else {
        setProceedError();
        updateCurrentUser(res.data.user);
        let paymentId = res.data.payment && res.data.payment.id;
        history.push(`/${moduleId}/payment/detail?paymentId=${paymentId}`);
      }
    });
  };

  const khaltiPaymentGateway = () => {
    let khaltiPath =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://khalti.com/api/v2/epayment/initiate/'
        : 'https://a.khalti.com/api/v2/epayment/initiate/';

    let purchase_order_id = pid;

    const payload = {
      return_url: `${clientUrl}/${moduleId}/khalti/test-success`,
      website_url: `${clientUrl}/${moduleId}/dashboard`,
      amount: price * 100,
      purchase_order_id,
      purchase_order_name: `test${id}`,
    };

    axios
      .post(`${url}/payment/initiate-khalti-pay`, {
        khaltiPath,
        payload,
      })
      .then((res) => {
        window.location.href = res && res.data.payment_url;
      })
      .catch((err) => {
        console.log(err);
        alert('Something went wrong! Please try again later!');
      });
  };

  const handleClose = function () {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.pushState(`${moduleId}/dashboard`);
    }
  };

  return (
    <div className="payment_main">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-content-center align-items-center"
          style={{ minHeight: '100vh', overflow: 'hidden' }}
        >
          <div className="col-12 text-left px-0">
            <div className="back-element d-block py-2" style={{ position: 'relative' }}>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <i className="fas fa-arrow-left"></i> <b>Back</b>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 d-md-block d-none">
            <article className="payment__img">
              <div className="swiper-slide">
                <img src="/images/examination.png" alt="Video courses" loading="lazy" />
                <div className="pt-5 text-center">
                  <h5 className="d-block fw-500 pr-0 mb-0">MCQ Examination: Test Your Knowledge</h5>
                  <p className="small text-muted">Live MCQ Tests, ranking and answer check.</p>
                </div>
              </div>
            </article>
          </div>
          <div className="col-md-8 col-sm-12 px-md-2 px-0">
            <div className="form-box px-md-4 px-sm-2 px-0">
              <form onSubmit={handleSubmit(handlePayment)}>
                <main className="payment">
                  <article className="payment__form my-sm-4 my-1">
                    <h1
                      className="payment__question mb-1 d-flex flex-row justify-content-between"
                      style={{ fontSize: '20px', boxShadow: 'none' }}
                    >
                      Test Payment
                      <img src="/images/nameOnline.png" width="100px" alt="Video courses" loading="lazy" />
                    </h1>

                    <div className="alert alert-warning border-0 mt-3" role="alert">
                      <small style={{ fontSize: '12px' }}>
                        Payment is attributed only to selected test and duration.
                      </small>
                    </div>

                    <p className="mb-2 fw-500 mt-3">Test you are purchasing:</p>

                    <div className="row">
                      <div className="col-md-8 col-sm-7 col-6 py-sm-2 py-1 px-md-3 px-1 pr-sm-3 pr-2">
                        <input
                          type="radio"
                          className="burmanRadio__input"
                          id={`test`}
                          defaultChecked={true}
                          name="subscription"
                          ref={register({
                            required: 'Subscription field is required.',
                          })}
                        />
                        <label htmlFor={`test`} className="burmanRadio__label mb-0">
                          <span className="fw-500">{name}</span>&nbsp;
                        </label>
                      </div>
                      <div
                        className="col-md-4 col-sm-5 col-6 py-sm-2 py-1 px-md-3 px-0 px-1 border-faint"
                        htmlFor={`test`}
                      >
                        <div className="text-right">
                          <h5 className="mb-0 pr-0" style={{ fontWeight: 500 }}>
                            {/* <span className="old_amount" style={{ textDecoration: 'line-through' }}>
                              Rs. {price + price * (20 / 100)}
                            </span> */}
                            &nbsp; Rs. {price}
                          </h5>
                          <p className="text-muted d-block mb-0 pr-0">Total (Incl. of all charges)</p>
                        </div>
                      </div>
                    </div>

                    <p className="fw-500 mt-3">Pay with:</p>
                    <PaymentList
                      subscriptionTypes={subscriptionTypes}
                      paymentTypes={paymentTypes}
                      selectedPayment={selectedPayment}
                      setSelectedPayment={setSelectedPayment}
                      register={register}
                      errors={errors}
                    />
                    <ProceedToPay register={register} proceedError={proceedError} submitting={submitting} />
                    {/* <DiscountBox /> */}
                  </article>
                </main>
              </form>
            </div>
          </div>

          {/* modal box for subscription details */}
          <div
            className="modal fade"
            id="subDetail"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="subDetailTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog"
              role="document"
              style={{ width: '100%', height: '100%', marginTop: '-1px', position: 'absolute', left: '0', top: '0' }}
            >
              <div className="modal-content" style={{ height: '100%' }}>
                <div className="modal-header" style={{ minHeight: '65px' }}>
                  <h5 className="modal-title" id="AskTitle">
                    NameOnline Subscription Details | 3 mo / 6 mo / 12 mo
                  </h5>
                  <button
                    type="button"
                    className="close btn btn-link btn-sm px-2 py-1 m-1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body" style={{ minHeight: 'calc(100vh - 84px)' }}>
                  Your subscription plan includes:
                  <ul className="fa-ul my-2">
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>400+ hours of High Quality Video lessons
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>Highly yield interactive courses
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>Videos by top rated Mentors
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>Interactive discussion, polls &amp;
                      ranking
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>Updated coursework, MCQ's & Mock tests
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>CEE oriented tutorials
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>Unlimited Access of NameOnline
                    </li>
                  </ul>
                  <div className="alert alert-warning my-4 pl-0" role="alert">
                    <ul className="fa-ul my-2">
                      <li>
                        <i className="fa-li fa fa-angle-right text-secondary"></i>Billed as a One Time payment based
                        upon your subscribed plans.
                      </li>
                      <li>
                        <i className="fa-li fa fa-angle-right text-secondary"></i>Get more discounts on purchase of 6mo
                        & 12mo packages.
                      </li>
                    </ul>
                  </div>
                  <div className="disclaimer pt-4">
                    <i>
                      Note: The resources and materials in the NameOnline app is exclusively produced by the Name
                      Institute. Please contact Name Institute's administrator for course related queries.
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProceedToPay({ register, proceedError, submitting }) {
  return (
    <div className="payment__btns mt-2">
      <div className="input-group mb-2">
        <input
          type="text"
          name="referalCode"
          className="form-control referral-input"
          placeholder="Have a referral code?"
          ref={register()}
        />
        <div className="input-group-append">
          <button type={submitting ? 'button' : 'submit'} className="btn btn-dark btn--primary">
            {submitting ? 'Processing' : 'Proceed & Pay'}
            &nbsp;
            <i className="fa fa-chevron-right" />
          </button>
          {proceedError && proceedError}
        </div>
      </div>
    </div>
  );
}

export default Payment;

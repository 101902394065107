import React from 'react';

function NotificationTab() {
  return (
    <div className="px-4 ml-0" style={{background: "#fff"}}>
      <p className="profile__label mb-3">Account</p>
      <div className="row">
        <div className="col-sm-7">
          <div className="form-group d-flex justify-content-between">
            <div className="text-left">
              <label className="mb-0 d-block" for="noti-switch">
                Notification
              </label>
              <p className="small text-muted">Turn app notification alerts on / off</p>
            </div>
            <input name="noti-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div className="form-group d-flex justify-content-between">
            <div className="text-left">
              <label className="mb-0 d-block" for="ad-switch">
                Advertising
              </label>
              <p className="small text-muted">The goal of these ads is to help develop the application</p>
            </div>
            <input name="ad-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>
        </div>
      </div>

      <p className="profile__label mb-3">Others</p>
      <div className="row">
        <div className="col-sm-7">
          <div className="form-group d-flex justify-content-between">
            <div className="text-left">
              <label className="mb-0 d-block" for="mail-switch">
                Email
              </label>
              <p className="small text-muted">Subscription for occassional offers & alerts in your inbox.</p>
            </div>
            <input name="mail-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div className="form-group d-flex justify-content-between">
            <div className="text-left">
              <label className="mb-0 d-block" for="vib-switch">
                Vibration
              </label>
              <p className="small text-muted">Heptic Feedbacks for main actions within the app.</p>
            </div>
            <input name="vib-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>

          <div className="form-group d-flex justify-content-between">
            <div className="text-left">
              <label className="mb-0 d-block" for="vib-switch">
                Sound
              </label>
              <p className="small text-muted">Sound alerts in the app.</p>
            </div>
            <input name="vib-switch" type="checkbox" className="fancy-checkbox switch" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationTab;

import React, { useState, useEffect, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import PollTap from './PollTap';
import DiscussionTap from './DiscussionTap';
import { Tab, TabItems, TabContents, TabItem, TabContent } from '../../ui/Tab';
import { Sticky, StickyContainer } from 'react-sticky';
import './DiscussionCategory.css';

function DiscussionCategory({ location, match }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const moduleId = match.params.moduleId;
  const query = queryString.parse(location.search);
  const historySearch = query.search || '';
  const subjectId = query.subjectId;
  const [subject, setSubject] = useState([]);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState(historySearch);
  let selectedName = selected && selected.subject_name.toLowerCase().replace(/\s/g, '-');

  const getSubject = () => {
    axios({
      method: 'get',
      url: `${server}/${moduleId}/poll/subject`,
      withCredentials: true,
    }).then((res) => {
      let subject = res.data.subject;
      subject.forEach((s) => {
        // console.log(s.subject_id, s.subject_id === subjectId)
        if (s.subject_id === parseInt(subjectId)) {
          setSelected(s);
          return;
        }
      });
      setSubject(res.data.subject);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let search = e.target.search.value;
    setSearch(search);
  };

  useMemo(() => {
    subject.forEach((s, index) => {
      if (s.subject_id === parseInt(subjectId)) {
        setSelected(s);
        setSearch('');
        return;
      }
    });
  }, [subjectId]);

  useEffect(() => {
    getSubject();
  }, []);
  return (
    <section style={{ marginTop: '-15px' }} className="discu py-sm-2 py-0 pl-sm-2 pl-0">
      <StickyContainer>
        <div className="row">
          <div className="col-lg-3 col-md-4  col-12  px-1 pt-1">
            <Sticky>
              {({ style }) => {
                return (
                  <div style={style} className="bg-white left--options">
                    <div className="search-container-discu">
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon-discu">
                              <i className="fas fa-search" />
                            </span>
                          </div>
                          <input
                            type="text"
                            id="poll-search"
                            className="form-control border-muted bg-white"
                            placeholder="Search discussion..."
                            defaultValue={search}
                            name="search"
                            aria-label="name"
                            aria-describedby="basic-addon-discu"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="polls__discussion">
                      <div className="polls-lists">
                        <div className="main-discus" id="cours">
                          <a
                            style={{
                              height: '50px',
                              verticalAlign: 'middle',
                              lineHeight: '40px',
                            }}
                            className="text-primary d-block p-2 bg__transparent bg-white"
                            data-toggle="collapse"
                            href="#collapse__courses"
                            aria-expanded="true"
                            aria-controls="collapse__courses"
                          >
                            <strong><i className="fas fa-list-ul"></i>&nbsp;COURSES&nbsp;</strong>
                            <span className="float-right">
                              <i className="fa fa-chevron-down"></i>
                            </span>
                          </a>
                          <div
                            className={`collapse discus-container ${window.innerWidth > 640 && 'show'}`}
                            id="collapse__courses"
                          >
                            {selected && <SubjectCard s={selected} moduleId={moduleId} selected={true} />}
                            {subject &&
                              subject.map((s) => {
                                if (s.subject_id !== parseInt(subjectId)) {
                                  return <SubjectCard key={s.subject_id} s={s} moduleId={moduleId} />;
                                }
                                return null;
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Sticky>
          </div>
          <div className="col-lg-9 col-md-8 col-12 pl-sm-1 pr-sm-1 pl-0 pr-0 radius-15 overflow-hidden">
            {selected && (
              <div className="bg-white">
                <div className="p-sm-3 p-2 border-bottom">
                  <div className="d-inline-block">
                    <img
                      src={`${server}/images/subjectLight/icons/${moduleId}/${selectedName}.png`}
                      alt=""
                      className="rounded-5 vote-photo mr-2"
                    />
                  </div>
                  <div className="d-inline-block">
                    <p className="pt-1 mb-0">{selected.subject_name}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="px-2 bg-white">
              <Tab>
                <TabItems tabId="course">
                  <TabItem id="poll" title="Poll" selected={true} />
                  <TabItem id="discussion" title="Discussion" />
                </TabItems>
                <TabContents tabId="course">
                  <TabContent id="poll" selected={true}>
                    <PollTap subjectId={subjectId} search={search} moduleId={moduleId} />
                  </TabContent>
                  <TabContent id="discussion">
                    <DiscussionTap subjectId={subjectId} search={search} moduleId={moduleId} />
                  </TabContent>
                </TabContents>
              </Tab>
            </div>
          </div>
        </div>
      </StickyContainer>
    </section>
  );
}

function SubjectCard({ s, moduleId, selected }) {
  let { subject_name, subject_id } = s;
  let name = subject_name.toLowerCase().replace(/\s/g, '-');
  let server = process.env.REACT_APP_SERVER_URL
  return (
    <Link
      key={subject_id}
      className="d-block quizes__labels active"
      to={`/${moduleId}/discussion/detail?subjectId=${subject_id}`}
    >
      <div className="row vote-modal justify-content-around">
        <div className="col-md-3 col-sm-3 col-2">
          <img src={`${server}/images/subjectLight/icons/${moduleId}/${name}.png`} alt={name} className="rounded-5 vote-photo" />
        </div>
        <div className="col-md-9 col-sm-9 col-10">
          <div className="px-2">
            <h6 className="polls_title mb-0 p-0">{subject_name}</h6>
            {/* <small className="text-muted">Section 28, (Winter 2019)</small> */}
          </div>
          {selected && (
            <div className="ninja__block px-2">
              <span className="badge badge-light d-inline-block mr-1">Poll</span>
              <span className="badge badge-primary d-inline-block">Discussion</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default withRouter(DiscussionCategory);

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, withRouter, Link } from 'react-router-dom';

import { useModuleContext } from '../../context/ModuleProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import Preloader from '../Preloader/Preloader';
import DataNotFound from '../Live/DataNotFound';
import Plyr from 'plyr-react';
import { useAuthContext } from '../../context/AuthContextProvider';

import moment from 'moment';
import { concatText } from '../helper';

const toReadableDate = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    return `${date.toDateString()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
  } else return '';
};

function PlayVideo() {
  const location = useLocation();
  let history = useHistory();
  const { moduleId } = useParams();

  const { url } = useModuleContext();

  const queryParams = new URLSearchParams(location.search);
  const record_id = queryParams.get('record_id');

  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(null);
  const [nextRecordings, setNextRecordings] = useState(null);
  const [paid, setPaid] = useState(false);
  let { currentUser, streakUpdated, setStreakUpdated } = useAuthContext();

  useEffect(() => {
    if (!record_id || !moduleId) {
      history.replace('/', {});
    } else {
      setLoading(true);
      axios({
        method: 'get',
        url: `${url}/recording/single?id=${record_id}`,
      })
        .then((res) => {
          if (res.data.recording) {
            setRecording(res.data.recording);
          } else {
            setRecording(null);
          }
          if (res.data.next) setNextRecordings(res.data.next);
          if (res.data.paid) setPaid(res.data.paid);
          setLoading(false);
          handleUpdateStreak();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [record_id, moduleId]);

  const handleUpdateStreak = () => {
    if (currentUser && !streakUpdated) {
      axios.post(`${url}/user-streak?userId=${currentUser.id}`).then(() => {
        setStreakUpdated(true);
      });
    }
  };

  return !loading ? (
    paid ? (
      recording ? (
        <section className="content recording">
          <div className="video">
            <VideoDisplay moduleId={moduleId} video={recording} />
            <div className="content">
              <div className="top">
                <p className="title">{recording.title}</p>
                <p>Views: {recording.view}</p>
              </div>
              <p className="date">Created at : {toReadableDate(recording.created_at)}</p>
              <p className="desc">
                Description:
                <span>{recording.description}</span>
              </p>
            </div>
          </div>
          <div className="next-videos">
            <div className="title">
              <p>Similar Recordings</p>
            </div>
            {nextRecordings && nextRecordings.length !== 0 ? (
              <div className="list">
                {nextRecordings.map((item, i) => {
                  return <VideoSmall moduleId={moduleId} video={item} />;
                })}
              </div>
            ) : (
              <p className="no-videos">No more recordings found in this live.</p>
            )}
          </div>
        </section>
      ) : (
        <DataNotFound />
      )
    ) : (
      <div className="not-paid">
        <Link to={`/${moduleId}/zoom-payment`}>Buy Live Access</Link>
      </div>
    )
  ) : (
    <Preloader />
  );
}

function VideoDisplay({ moduleId, video }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let link = video.link ? video.signedUrl : `${serverUrl}/videos/${video.id}.mp4`;

  return (
    <div className="radius-15" style={{ overflow: 'hidden' }}>
      {video.link.includes('youtube') ? (
        <div class="yt_container">
          <iframe
            sandbox="allow-same-origin allow-scripts"
            title={video.title}
            width="100%"
            height={500}
            src={`//www.youtube.com/embed/${video.link.split('=')[1]}`}
            allowFullScreen
            className="yt_video"
          ></iframe>
        </div>
      ) : (
        <Plyr
          controls
          preload="auto"
          width={640}
          height={264}
          source={{
            type: 'video',
            sources: [
              {
                src: link,
                type: 'video/mp4',
                size: 720,
              },
            ],
            poster: `${serverUrl}/images/videos/${video.id}.png`,
          }}
          options={{
            disableContextMenu: true,
          }}
        />
      )}
    </div>
  );
}

function VideoSmall({ video, moduleId }) {
  let server = process.env.REACT_APP_SERVER_URL;
  let { id, title, length, thumbnail } = video;
  return (
    <Link className="small-item" to={`/${moduleId}/recording?record_id=${id}`}>
      <div className="image">
        <img
          src={`${server}/images/recordings/${thumbnail}`}
          alt="video"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/images/defaultthumbnail.jpg';
          }}
        />
      </div>

      <div className="content">
        <p className="head">{concatText(title, 70)}</p>
        <p>&nbsp;{getDuration(length)}</p>
      </div>
    </Link>
  );
}

const getDuration = (length) => {
  return moment.utc(length * 1000).format('HH:mm:ss');
};

export default withRouter(PlayVideo);

import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M13.7 15H10.5C9.9 15 9.5 14.6 9.5 14V11.4C9.5 10.8 9.9 10.4 10.5 10.4C11.1 10.4 11.5 10.8 11.5 11.4V13H12.7V11.5C12.7 10.9 13.1 10.5 13.7 10.5C14.3 10.5 14.7 10.9 14.7 11.5V14C14.7 14.6 14.2 15 13.7 15Z"
        fill="#2B4832"
      />
      <path
        d="M17.3 19.9H13.4C12.8 19.9 12.4 19.5 12.4 18.9C12.4 18.3 12.8 17.9 13.4 17.9H17.3C17.9 17.9 18.3 18.3 18.3 18.9C18.3 19.5 17.9 19.9 17.3 19.9Z"
        fill="#2B4832"
      />
      <path
        d="M15.4 21.9C14.8 21.9 14.4 21.5 14.4 20.9V17C14.4 16.4 14.8 16 15.4 16C16 16 16.4 16.4 16.4 17V20.9C16.4 21.4 15.9 21.9 15.4 21.9Z"
        fill="#2B4832"
      />
      <path
        d="M17.3 19.9H13.4C12.8 19.9 12.4 19.5 12.4 18.9C12.4 18.3 12.8 17.9 13.4 17.9H17.3C17.9 17.9 18.3 18.3 18.3 18.9C18.3 19.5 17.9 19.9 17.3 19.9Z"
        fill="#2B4832"
      />
      <path
        d="M15.4 21.9C14.8 21.9 14.4 21.5 14.4 20.9V17C14.4 16.4 14.8 16 15.4 16C16 16 16.4 16.4 16.4 17V20.9C16.4 21.4 15.9 21.9 15.4 21.9Z"
        fill="#2B4832"
      />
      <path
        d="M12.1 6.20001C9.7 6.20001 8.1 5.60001 8 5.60001C7.4 5.40001 7.2 4.90001 7.3 4.40001C7.5 3.90001 8 3.60001 8.6 3.80001C8.7 3.80001 11.9 4.90001 15.6 3.80001C16.1 3.60001 16.7 3.90001 16.8 4.50001C17 5.00001 16.7 5.60001 16.1 5.70001C14.7 6.10001 13.3 6.20001 12.1 6.20001Z"
        fill="#42C074"
      />
      <path
        d="M20.2 23.5C19.6 23.5 19.2 23.1 19.2 22.5V19.2C19.2 15.5 16.8 15 15.4 15H8.8C7.4 15 5 15.6 5 19.2V22.5C5 23.1 4.6 23.5 4 23.5C3.4 23.5 3 23.1 3 22.5V19.2C3 14.6 6 13 8.8 13H15.4C18.2 13 21.2 14.6 21.2 19.2V22.5C21.2 23.1 20.7 23.5 20.2 23.5Z"
        fill="#42C074"
      />
      <path
        d="M12.1 12.5C8.9 12.5 6.3 9.9 6.3 6.7C6.3 3.5 8.9 1 12.1 1C15.3 1 17.9 3.6 17.9 6.8C17.9 10 15.3 12.5 12.1 12.5ZM12.1 3C10 3 8.3 4.7 8.3 6.8C8.3 8.9 10 10.5 12.1 10.5C14.2 10.5 15.9 8.8 15.9 6.7C15.9 4.6 14.2 3 12.1 3Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="18.2"
          height="22.5"
          fill="white"
          transform="translate(3 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React, { useContext, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import { useForm } from 'react-hook-form';

function LoginFrom({ history }) {
  const { login, verifyUser } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(null);
  const [send, setSend] = useState(false);

  const submitForm = async (data) => {
    if (!send) {
      const res = await login(data);
      if (res && res.data.error) {
        setError(res.data.message);
      } else {
        setSend(true);
      }
    } else {
      await verifyUser(data, history);
    }
  };

  return (
    <form className="authForm" id="loginForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        {/* <div className="back-element d-sm-none d-block " style={{ position: "relative", padding: "15px 10px" }}>
          <a href="https://avyaas.com/see/login/courses" className="text-dark "><i className="fa fa-long-arrow-left"></i>&nbsp;BACK</a>
        </div> */}
        <div className="overlay__img d-lg-none d-md-none d-none">
          <Link to="/">
            <img style={{ position: 'relative' }} alt="Avyaas Logo" src="/images/logo.png" />
          </Link>
        </div>

        <h2 className="Welcom mb-0 pb-0">Get Started!</h2>
        <p className="small text-muted">To continue, please enter your user credentials.</p>
      </div>
      <div className="error">{error}</div>
      <div className={errors.phone ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: 'Phone number is required',
            minLength: {
              value: 10,
              message: 'Invalid phone number',
            },
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              message: 'Invalid phone number',
            },
          })}
          disabled={send}
          name="phone"
          type="number"
          id="phone"
          placeholder="Phone number"
        />
        <label className="input-icon-label" htmlFor="email">
          <i className="fas fa-mobile-alt"></i>
        </label>
      </div>
      <div className="input-error">{errors.phone && errors.phone.message}</div>

      {send && (
        <div className={errors.token ? 'errorfield inputGroup' : 'inputGroup'}>
          <input
            ref={register({ required: 'Otp code is required' })}
            name="token"
            type="text"
            id="token"
            placeholder="Otp code"
          />
          <label className="input-icon-label" htmlFor="token">
            <i className="fas fa-mobile-alt"></i>
          </label>
        </div>
      )}
      <div className="input-error">{errors.token && errors.token.message}</div>

      <div className="forgot__acc">
        <div className="remember d-inline-block">
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="remember" name="remember" />
            <label className="custom-control-label text-muted small" htmlFor="remember">
              Remember Me
            </label>
          </div>
        </div>
      </div>
      <div className="pt-3 ">
        <div className="btn btn-block p-0 my-2">
          <button type="submit" id="login" className="btn btn-dark rounded shadow  mb-2">
            Login
          </button>
          <Link to="/login/register">
            <button className="btn btn-dark rounded shadow d-block" id="register">
              Create new account&nbsp;<i className="fas fa-arrow-circle-right"></i>
            </button>
          </Link>
        </div>
      </div>
    </form>
  );
}

export default withRouter(LoginFrom);

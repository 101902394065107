import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Swiper from "react-id-swiper";
import moment from "moment";
import { courseImgLink, concatText } from "../helper";
import "./VideoMenu.css";

function VideoMenu({ match }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  const [tswiper, updateTswiper] = useState(null);
  const [menu, setMenu] = useState(null);

  const [subject, setSubject] = useState(null);
  let moduleId = match.params.moduleId;
  const timelineParams = {
    direction: "horizontal",
    loop: false,
    speed: 1600,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (index, className) => {
        console.log(className, index, "renderBullet");
        return (
          '<span  class="' +
          className +
          '">' +
          `${menu[index] && menu[index].subject_name}` +
          "</span>"
        );
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      768: {
        direction: "vertical",
      },
    },
  };

  const courseParams = {
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  const fetchSubject = () => {
    axios({
      method: "get",
      url: `${serverUrl}/${moduleId}/video/`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Subject");
      let { subject, youtubeLive } = res.data;
      if (subject) {
        let menu = subject.filter((s) => {
          if (s.videos && s.videos[0]) {
            return s;
          }
          return false;
        });
        let live;
        if (youtubeLive) {
          live = {
            id: "live",
            subject_name: "Live class",
            videos: [youtubeLive],
          };
          menu = [live, ...menu];
        }
        setMenu(menu);
        setSubject(subject);
        console.log(menu);
      }
    });
  };
  tswiper && tswiper.update();

  useEffect(() => {
    fetchSubject();
  }, []);

  return (
    <section id="video-menu" className="w-100">
      <div className="timeline">
        {menu && (
          <Swiper getSwiper={updateTswiper} {...timelineParams}>
            {menu &&
              menu.map(
                (s) =>
                  s.videos[0] && (
                    <TimeLineSlider
                      key={s.id}
                      subject={s}
                      moduleId={moduleId}
                    />
                  )
              )}
          </Swiper>
        )}
      </div>
      <div className="w-100 options bg-white p-2">
        {/* Swiper */}
        <div className="v-gallery-title">
          <div className="row">
            <div className="col-sm-8">
              <div className="slider-maintitle">
                <h6 className="d-inline-block" style={{ fontWeight: 600 }}>
                  Courses
                </h6>
              </div>
            </div>
          </div>
        </div>
        {subject && (
          <div className="row">
            {subject &&
              subject.map((s) => {
                return (
                  <CourseSlider key={s.id} subject={s} moduleId={moduleId} />
                );
              })}
          </div>
        )}
      </div>
    </section>
  );
}

function CourseSlider({ subject, moduleId }) {
  return (
    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3">
      <div
        className="course-item"
        style={{
          backgroundImage: `url(${courseImgLink(subject.id)})`,
        }}
      >
        <small className="course-title v-course-text text-white d-block">
          &nbsp;{subject.subject_name}
        </small>

        <div className="abs-item">
          <Link to={`/${moduleId}/video/category?subjectId=${subject.id}`}>
            <button className="btn btn-cricle-light">View all</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function TimeLineSlider({ subject, moduleId }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let { videos, subject_name, id: subId } = subject;
  let { id, title, description, view, videoTags, length, imageUrl, ent_date } =
    videos[0];
  const live = subId === "live";

  return (
    <div
      className="swiper-slide"
      style={{
        backgroundImage: !live
          ? `url(${serverUrl}/images/videoMenu/${subject.id}.jpg`
          : `url(${serverUrl}/${imageUrl}`,
      }}
      data-year="Zoology"
    >
      <div className="swiper-slide-content">
        {/* {live && (
          <img
            alt="Live"
            src={`${serverUrl}/images/youtube/livelogo.png`}
            style={{ maxWidth: "120px" }}
          />
        )} */}
        {live && (
          <div className="go_live">
            <Link
              to={`/${moduleId}/video/youtube-live`}
              className="btn btn-danger"
              href="#"
              style={{ fontFamily: `"open sans", "sans-serif;"` }}
            >
              <i className="fas fa-circle" />
              &nbsp;LIVE
            </Link>
          </div>
        )}
        <span className="timeline-year">{subject_name}</span>
        <h4 className="timeline-title">{title}</h4>
        <div className="tags">
          <span className="pr-1">
            <i className="fa fa-clock" />
          </span>
          <span className="text-white pr-2">
            {live ? "Live" : moment.utc(length * 1000).format("HH:mm:ss")}
          </span>
          <span className="pr-1">
            <i className="fa fa-calendar-alt" />
          </span>
          <span className="text-white pr-2">
            {moment(ent_date).format("DD MMMM, YYYY")}
          </span>
          {!live && (
            <div className="pt-2">
              {videoTags &&
                videoTags.map((t) => (
                  <span key={t.id} className="tags-content">
                    {t.title}
                  </span>
                ))}
            </div>
          )}
        </div>
        <p className="timeline-text d-block">{concatText(description, 60)}</p>
        <Link
          to={
            live
              ? `/${moduleId}/video/youtube-live`
              : `/${moduleId}/video/single?id=${id}&subjectId=${subject.id}`
          }
          className="btn btn-outline-light"
        >
          Watch now
        </Link>
      </div>
      {!live && (
        <div className="w-100">
          <div className="abs-contents">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <div className="visual-box text-center">
                      <div className="rounded-circle circle">4.5</div>
                      <span className="small labels ml-4">ratings</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="visual-box text-center">
                      <div className="rounded-circle circle">{view}</div>
                      <span className="small labels ml-4">views</span>
                    </div>
                  </div>
                  {/* <div className="col-4">
                  <div className="visual-box text-right">
                    <div className="rounded-circle circle">
                      20
                    </div>
                  </div>
                  <span className="small labels">joined this course</span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(VideoMenu);

import React from "react";
import { Link } from "react-router-dom";
import Icon from "./icons/icon";

function CourseUi({ category, history }) {
  return (
    <div className="background">
      <div className="container">
        <div className="row panel new__categories-table">
          <div className="col-12 p-md-4 p-2">
            <div className="row">
              <div
                className="back-element d-md-none d-block"
                style={{ position: "relative", padding: "15px 0px" }}
              >
                <div onClick={() => history.goBack()} className="text-muted pointer" to="/">
                  <i className="fas fa-arrow-left"></i>
                </div>
              </div>
              <div className="col-md-6 col-12 px-0 pb-2 text-md-left">
                <img
                  src="/images/landing/nameplus.png"
                  alt="Name Nursing Plus"
                  height={100}
                />
              </div>
              <div className="col-md-6 col-12 px-0 pb-2">
                <h3 className="d-block mb-3">Explore by category</h3>
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
          </div>
          {category.map((c) => {
            return <Category key={c.id} course={c} history={history} />;
          })}
        </div>
      </div>
    </div>
  );
}

function Category({ course, history }) {
  let { id, name, count, link, subCourse } = course;
  return (
    <div
      onClick={() => history.push(link)}
      className="col-md-3 col-6 new__categories-plan"
    >
      <div className="text-right">
        <Link to={link} className="btn btn-outline-dark">
          <i className="fas fa-plus" />
        </Link>
      </div>

      <Icon id={id} />

      <Link className="new__categories-price" to={link}>
        {name}
      </Link>
      {count && <span className="text-muted small">{count} Courses</span>}
      {subCourse && (
        <div className="absolute-reveal">
          <div className="row">
            <Link className="col-6 d-block border" to={link}>
              CTVT
            </Link>
            <Link className="col-6 d-block border" to={link}>
              Others
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseUi;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useModuleContext } from 'context/ModuleProvider';
import { isIOS } from '../helper';

function MobileMenu({ mainroute }) {
  const { moduleId } = useModuleContext();

  if (!mainroute) return <div></div>;

  return (
    <div id="footer-menu" className="footer-menu-5-icons footer-menu-style-1 div-only-mobile">
      <NavLink to={`/${moduleId}/dashboard`} activeClassName="active-nav" className="foot-nav">
        <i className="flaticon-home"></i>
        <span>Dashboard</span>
      </NavLink>
      {/* {!isIOS() ? (
        <NavLink
          to={`/${moduleId}/practice`}
          activeClassName="active-nav"
          className="foot-nav"
        >
          <i className="flaticon-spots"></i>
          <span>Courses</span>
        </NavLink>
      ) : (
        <NavLink
          to={`/${moduleId}/ar`}
          activeClassName="active-nav"
          className="foot-nav"
        >
          <i className="flaticon-spots"></i>
          <span>3D Learn</span>
        </NavLink>
      )} */}
      <NavLink to={`/${moduleId}/practice`} activeClassName="active-nav" className="foot-nav">
        <i className="flaticon-spots"></i>
        <span>Courses</span>
      </NavLink>

      {moduleId === 'nmcle' ? (
        <NavLink to={`/${moduleId}/ar`} activeClassName="active-nav" className="foot-nav">
          <i className="nav-icon flaticon-3d"></i>
          <span>3d Learn</span>
        </NavLink>
      ) : (
        <NavLink to={`/${moduleId}/video`} activeClassName="active-nav" className="foot-nav">
          <i className="nav-icon flaticon-play"></i>
          {/* <BiBook style={{marginTop: "7px", opacity: "0.7" }} color="#1f1f1f" size="22px" /> */}
          <span>Video</span>
        </NavLink>
      )}
      <NavLink to={`/${moduleId}/discussion`} activeClassName="active-nav" className="foot-nav">
        <i className="flaticon-comment"></i>
        <span>Discussion</span>
      </NavLink>
      <NavLink to={`/${moduleId}/test`} activeClassName="active-nav" className="foot-nav">
        <i className="flaticon-tube"></i>
        <span>Model Exam</span>
      </NavLink>
      <div className="clear" />
    </div>
  );
}

export default MobileMenu;

import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { ZoomMtg } from '@zoom/meetingsdk';
import { useAuthContext } from '../../context/AuthContextProvider';
import { useModuleContext } from '../../context/ModuleProvider';

import { MeetingContext } from '../../context/MeetingContextProvider';
import { useHistory } from 'react-router-dom';

function ZoomSDK() {
  let { currentUser, streakUpdated, setStreakUpdated } = useAuthContext();
  const { meeting, setMeeting } = useContext(MeetingContext);
  const { url } = useModuleContext();
  const history = useHistory();

  const leaveURL = process.env.REACT_APP_CLIENT_URL;

  const getMdkSignature = async (e) => {
    if (e) {
      e.preventDefault();
    }

    axios({
      method: 'post',
      url: `${url}/zoom/getMdkSignature`,
      data: {
        meetingNumber: meeting.meetingId,
        role: currentUser.role === 'student' ? 0 : 1,
      },
    })
      .then((res) => {
        startMeeting(res.data.signature);
        handleUpdateStreak();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateStreak = () => {
    if (currentUser && !streakUpdated) {
      axios.post(`${url}/user-streak?userId=${currentUser.id}`).then(() => {
        setStreakUpdated(true);
      });
    }
  };

  const startMeeting = (signature) => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();

    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl: leaveURL,
      disablePreview: true,
      disableInvite: true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: meeting.meetingMsdkKey,
          meetingNumber: meeting.meetingId,
          passWord: meeting.meetingPw,
          userName: currentUser.name || 'user',
          userEmail: currentUser.email || 'user@user.com',
          success: (success) => {},
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (meeting.meetingId && meeting.meetingPw && meeting.meetingMsdkKey && currentUser) {
      getMdkSignature();
    } else {
      history.replace('/');
    }
  }, [meeting, currentUser]);

  useEffect(() => {
    const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
    stylesheets.forEach(function (stylesheet) {
      stylesheet.parentNode.removeChild(stylesheet);
    });

    // class="join-audio-by-phone"

    const scripts = document.querySelectorAll('script');
    const originalScripts = Array.from(scripts);
    scripts.forEach(function (script) {
      script.parentNode.removeChild(script);
    });

    const styles = document.querySelectorAll('style[type="text/css"]');
    const originalStyles = Array.from(styles);
    styles.forEach(function (style) {
      style.parentNode.removeChild(style);
    });

    const newStyle = document.createElement('style');
    newStyle.type = 'text/css';
    newStyle.innerHTML = `
    #zmmtg-root .dropdown.btn-group .participants-header__participants-pop-btn.ax-outline-blue-important.dropdown-toggle.btn.btn-default,
    #zmmtg-root .chat-header__chat-pop-btn.ax-outline-blue-important.dropdown-toggle.btn.btn-default{
      border: none;
    }

    #zmmtg-root .chat-receiver-list__receiver.dropdown-toggle.btn.btn-default{
      padding: 0 10px;
    }

    #zmmtg-root .dark-dropdown.new-LT__main-dropdown.custom-dropdown-menu.dropdown-menu{
        background-color: rgba(0, 0, 0, 0.8);
    }
      
    #zmmtg-root .dark-dropdown.new-LT__main-dropdown.custom-dropdown-menu.dropdown-menu li a{
        color: #fafbfc;
    }

    #zmmtg-root .meeting-info-container.meeting-info-container--left-side{
      display: none;
    }
    #zmmtg-root .join-audio-by-phone {
    display: none;
    pointer-events: none;
    }
    `;
    document.head.appendChild(newStyle);

    const link = document.createElement('link');
    link.href = 'https://source.zoom.us/2.17.0/css/bootstrap.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const link2 = document.createElement('link');
    link2.href = 'https://source.zoom.us/2.17.0/css/react-select.css';
    link2.rel = 'stylesheet';
    document.head.appendChild(link2);

    return () => {
      document.head.removeChild(link2);
      document.head.removeChild(link);
      document.head.removeChild(newStyle);

      stylesheets.forEach(function (stylesheet) {
        stylesheet.parentNode.appendChild(stylesheet);
      });

      originalScripts.forEach(function (script) {
        document.body.appendChild(script.cloneNode(true));
      });

      originalStyles.forEach(function (style) {
        document.head.appendChild(style.cloneNode(true));
      });
    };
  }, []);

  window.addEventListener('popstate', function (event) {
    endZoomMeeting();
    setMeeting({
      meetingId: '',
      meetingPw: '',
      meetingMsdkKey: '',
    });
  });

  function endZoomMeeting() {
    ZoomMtg.leaveMeeting({
      success: function (data) {
        console.log('Leave meeting success', data);
      },
      error: function (error) {
        console.error('Leave meeting error', error);
      },
    });
  }

  return (
    <div
      className={`zoomsdk`}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}
    >
      <h1>Joining...</h1>
    </div>
  );
}

export default ZoomSDK;

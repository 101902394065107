import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import $ from 'jquery';
import bootbox from 'bootbox';
import { AuthContext } from '../../../context/AuthContextProvider';
import PollCard from '../PollCard';
import { FormGroup, FormGroupSelect, FormGroupArea, FormButton, ModalForm } from '../../ui/ModalForm';
import Nodata from '../../ui/Nodata';

function PollTap({ moduleId, subjectId, search }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const [poll, setPoll] = useState(null);
  const [filter, setFilter] = useState('Active');
  const filterArr = ['New', 'Active', 'Popular'];
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    getPoll();
  }, [subjectId, search]);

  const getPoll = () => {
    let url = `${server}/${moduleId}/poll?search=${search}&filter=${filter.toLowerCase()}`;
    subjectId && (url = `${url}&subjectId=${subjectId}`);
    axios({
      method: 'get',
      url,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setPoll(res.data.poll);
    });
  };
  const votePoll = (pollId, pollOptionId) => {
    let data = { pollId, pollOptionId };
    axios({
      method: 'post',
      url: `${server}/${moduleId}/poll/vote?subjectId=${subjectId}`,
      withCredentials: true,
      data,
    }).then((res) => {
      console.log(res.data);
      let newPoll = poll.map((p) => {
        if (p.id === pollId) {
          p = res.data.poll;
        }
        return p;
      });
      console.log(newPoll);
      setPoll(newPoll);
    });
  };

  const deletePoll = (e, pollId) => {
    e.preventDefault();
    axios({
      method: 'delete',
      url: `${server}/${moduleId}/poll/${pollId}`,
      withCredentials: true,
    }).then((res) => {
      bootbox.alert('Poll deleted successfully.');
    });
  };

  const createPoll = (pollData) => {
    let { optionA, subjectId, optionB, optionC, optionD, question } = pollData;
    let data = {
      subjectId,
      question,
      options: [{ option: optionA }, { option: optionB }, { option: optionC }, { option: optionD }],
    };
    axios({
      method: 'post',
      url: `${server}/${moduleId}/poll?subjectId=${subjectId}`,
      withCredentials: true,
      data,
    }).then((res) => {
      console.log('create poll called');
      console.log(res.data);
      res.data.poll.user = currentUser;
      $('#pollModal').hide();
      $('.modal-backdrop').hide();
      setPoll([res.data.poll, ...poll]);
    });
  };
  return (
    <div className="tab-pane fade show active" id="poll" role="tabpanel" aria-labelledby="poll-tab">
      <PollPopup createPoll={createPoll} moduleId={moduleId} />
      <div className="polls__discussion ">
        <div className="polls-lists p-3">
          <div className="row d-flex justify-content-between ">
            <div className="col-3">
              <div className="btn-group ">
                <button
                  type="button"
                  className="btn btn-light rounded dropdown-toggle py-2 px-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filter} <i className="fas fa-chevron-down" />
                </button>
                <div className="dropdown-menu">
                  {filterArr.map((f) => {
                    if (f !== filter) {
                      return (
                        <div className="dropdown-item" key={f} onClick={() => setFilter(f)}>
                          {f}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-light rounded py-2 px-3"
                  data-toggle="modal"
                  data-target="#pollModal"
                  data-whatever="@poll Question"
                >
                  <i className="fas fa-plus"></i>&nbsp; POLL
                </button>
              </div>
            </div>
          </div>
        </div>
        {poll ? (
          poll.length !== 0 ? (
            poll.map((p) => (
              <PollCard key={p.id} poll={p} votePoll={votePoll} deletePoll={deletePoll} userId={currentUser.id} />
            ))
          ) : (
            <Nodata title="No poll found." src="/images/noComment.jpg" />
          )
        ) : (
          'Loading...'
        )}
      </div>
    </div>
  );
}

function PollPopup({ createPoll, moduleId }) {
  const { register, handleSubmit, errors, setError, control } = useForm();
  const [subject, setSubject] = useState([]);
  const server = process.env.REACT_APP_SERVER_URL;
  const url = `${server}/${moduleId}`;
  console.log(errors);
  let optionType = {
    required: 'Please specify your options.',
    maxLength: {
      value: 200,
      message: 'Option should not be more than 200 characters.',
    },
  };
  let questionType = {
    ...optionType,
    minLength: {
      value: 5,
      message: 'Question should be at least of length 4.',
    },
  };

  const handlePoll = (poll) => {
    console.log(poll);
    if (!isNaN(poll.subjectId)) {
      createPoll(poll);
    } else {
      setError('subjectId', 'required', 'Please select the subject');
    }
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/poll/subject`,
      withCredentials: true,
    }).then((res) => {
      setSubject(res.data.subject);
    });
  }, [url]);

  return (
    <ModalForm id="pollModal" title="Create a new Poll">
      <form style={{ minHeight: '90vh' }} className="bg-white" onSubmit={handleSubmit(handlePoll)}>
        <div
          className="px-4 mb-2"
          style={{ height: '73vh', overflowY: 'auto', boxShadow: 'rgb(233 236 239) -1px -2px 12px 2px inset' }}
        >
          <FormGroupArea
            id="question"
            title="Question"
            control={control}
            register={register(questionType)}
            error={errors.question}
          />

          <FormGroupSelect
            list={subject}
            title="Choose a subject"
            value="subject_id"
            display="subject_name"
            control={control}
            id="subjectId"
          />

          <FormGroup title="Option A" id="optionA" register={register(optionType)} error={errors.optionA} />

          <FormGroup title="Option B" id="optionB" register={register(optionType)} error={errors.optionB} />

          <FormGroup title="Option C" id="optionC" register={register(optionType)} error={errors.optionC} />

          <FormGroup title="Option D" id="optionD" register={register(optionType)} error={errors.optionD} />
        </div>
        <FormButton />
      </form>
    </ModalForm>
  );
}

export default PollTap;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link, useLocation } from 'react-router-dom';
import TestNotFound from './TestNotFound';
import moment from 'moment';
import Preloader from '../Preloader/Preloader';
import TestCounter from './TestCounter';
import { useModuleContext } from 'context/ModuleProvider';
import './Test.css';

function Test() {
  const { url, moduleId } = useModuleContext();
  const [test, setTest] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('test_type');

  const [type, setType] = useState(query || 'all');

  let types = [
    { id: 'all', title: 'All' },
    { id: 'premium', title: 'Premium Test' },
    { id: 'daily', title: 'Daily Test' },
    { id: 'weekly', title: 'Saturday Test' },
    { id: 'series', title: 'Series Test' },
  ];

  useEffect(() => {
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        function () {
          console.log('Location recorded.');
        },
        function (err) {
          console.log('Location error!', err);
        },
      );
  }, []);

  useEffect(() => {
    if (type === 'premium') {
      axios({
        method: 'get',
        url: `${url}/test/premium`,
        withCredentials: true,
      }).then((res) => {
        setTest(res.data.premiumData || []);
      });
    } else {
      axios({
        method: 'get',
        url: `${url}/test?type=${type}`,
        withCredentials: true,
      }).then((res) => {
        setTest(res.data.test);
      });
    }
  }, [url, type]);

  return (
    <section className="content">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-7 col-12 radius-15 overflow-hidden">
          <div className="ui-tab bg-white radius-15 pl-sm-0 pr-sm-0 undefined mb-4 px-sm-3 pt-sm-0 pt-0 px-0">
            <ul
              className="nav nav-tabs d-flex justify-content-sm-center justify-content-around"
              id="testTab"
              role="tablist"
            >
              {types.map((t) => {
                let { id, title } = t;
                let selected = type === id;
                return (
                  <li className="nav-item">
                    <a
                      aria-controls={id}
                      aria-selected={selected}
                      className={`nav-link  px-sm-4 py-sm-3 px-1 py-2 pl-sm-2 pl-2 ${selected && 'active'} `}
                      href={`#${id}`}
                      id={`${id}-tab`}
                      role="tab"
                      onClick={() => setType(id)}
                    >
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <TestContainer moduleId={moduleId} test={test} />
    </section>
  );
}

function TestContainer({ test, moduleId }) {
  return test ? (
    <div className="container-fluid">
      <div className="row">
        {test.length && test.length !== 0 ? (
          test.map((t) => <SingleTest key={t.id} t={t} moduleId={moduleId} />)
        ) : (
          <TestNotFound />
        )}
      </div>
    </div>
  ) : (
    <Preloader />
  );
}

function SingleTest({ t, moduleId }) {
  let SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let [reload, setReload] = useState(false);
  let redirect = '';
  let query = '';
  let text = '';
  let header = '';
  let startDate = moment.utc(t.start);
  let endDate = moment.utc(t.end);

  let newTest = moment().subtract(1, 'days') < endDate;
  let testPaymentLink = `/${moduleId}/test-payment`;
  let startTestLink = `/${moduleId}/test/instruction`;
  let dashboardLink = `/${moduleId}/dashboard`;
  let currentDate = moment.utc();
  if (t.is_premium && !t.hasPremiumAccess) {
    redirect = `/${moduleId}/payment`;
    text = 'Buy Premium';
    header = `Ends in ${endDate.local().fromNow()}`;
    query = ``;
  } else if (startDate <= currentDate && endDate >= currentDate) {
    if (t.done) {
      if (t.type === 'instant') {
        console.log('instant');
        redirect = `/${moduleId}/test/result`;
        query = `testId=${t.id}`;
        header = 'Completed';
        text = 'View Result';
      } else {
        redirect = `/${moduleId}/dashboard`;
        text = 'Submitted';
        header = `Ends in ${endDate.local().fromNow()}`;
      }
    } else {
      redirect = t.price ? (t.hasPaid ? startTestLink : testPaymentLink) : startTestLink;
      query = `testId=${t.id}`;
      text = t.price ? (t.hasPaid ? 'Start Test' : 'Purchase Test') : 'Start Test';
      header = `Ends in ${endDate.local().fromNow()}`;
    }
  } else if (startDate > currentDate) {
    redirect = t.price ? (t.hasPaid ? dashboardLink : testPaymentLink) : dashboardLink;
    query = t.price ? (t.hasPaid ? '' : `testId=${t.id}`) : '';
    if (startDate < currentDate.add(12, 'hour')) {
      header = (
        <TestCounter
          end={t.start}
          text="Starts in "
          callback={() => {
            setTimeout(() => setReload(!reload), 1000);
          }}
        />
      );
    } else {
      header = `Starts in ${startDate.local().fromNow()}`;
    }
    text = t.price ? (t.hasPaid ? 'Check later' : 'Purchase Test') : 'Check later';
  } else if (endDate < currentDate) {
    redirect = `/${moduleId}/test/result`;
    query = `testId=${t.id}`;
    header = 'Completed';
    text = 'View Result';
  }

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-12 px-sm-2 px-0">
      <div className="card" id="test-mb">
        {newTest ? (
          t.price ? (
            <div className="ribbon ribbon-top-right">
              <span>{t.hasPaid ? 'Paid' : `Rs ${t.price}`}</span>
            </div>
          ) : (
            <div className="ribbon ribbon-top-right">
              <span>New</span>
            </div>
          )
        ) : (
          <></>
        )}
        <div className="card-">
          <Link
            className="weekly-title d--inline-block text-dark float-right"
            to={{
              pathname: redirect,
              search: query && `?${query}`,
              state: {
                id: t.id,
                name: t.name,
                price: t.price,
                isQuestionSet: false,
              },
            }}
          >
            <div className="row">
              <div className="d-block date-card text-muted px-3 pt-2" style={{ fontSize: '11px', fontWeight: '600' }}>
                {startDate.local().format('DD/MM/YYYY')}
              </div>
            </div>
          </Link>

          <div className="text-left" style={{ minHeight: '126px' }}>
            <Link
              className="row weekly-title text-dark p-2 w-100"
              to={{
                pathname: redirect,
                search: query && `?${query}`,
                state: {
                  id: t.id,
                  name: t.name,
                  price: t.price,
                  isQuestionSet: false,
                },
              }}
            >
              <div className="col-md-2 col-3">
                <div className="weekly-test pt-0">
                  <img
                    src={`${SERVER_URL}/images/subjectLight/icons/${moduleId}/chemistry.png`}
                    alt="text-icon-indicator"
                    className="media-object"
                  />
                </div>
              </div>

              <div className="col-md-10 col-9">
                <h6 className="pr-0 font-weight-bold mb-2" style={{ fontSize: '12px' }}>
                  {t.name}
                </h6>
                <div>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <span class="small text-muted">{header}</span>
                    </li>
                    <li className="list-inline float-right">
                      {text && (
                        <Link
                          to={{
                            pathname: redirect,
                            search: query && `?${query}`,
                            state: {
                              id: t.id,
                              name: t.name,
                              price: t.price,
                              isQuestionSet: false,
                            },
                          }}
                          className="btn btn-outline-dark btn-sm ad-click-event small"
                          style={{ fontSize: '11px' }}
                        >
                          {text}&nbsp;<i className="fa fa-chevron-right"></i>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Test);

import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12.2 18.4C7.4 18.4 3.5 14.5 3.5 9.7C3.5 4.9 7.4 1 12.2 1C17 1 20.9 4.9 20.9 9.7C20.9 14.5 17 18.4 12.2 18.4ZM12.2 3C8.5 3 5.5 6 5.5 9.7C5.5 13.4 8.5 16.4 12.2 16.4C15.9 16.4 18.9 13.4 18.9 9.7C18.9 6 15.9 3 12.2 3Z"
        fill="#42C074"
      />
      <path
        d="M13.8 14.9H10.6C10 14.9 9.6 14.5 9.6 13.9V12.3H8C7.4 12.3 7 11.9 7 11.3V8.2C7 7.6 7.4 7.2 8 7.2H9.6V5.5C9.6 4.9 10 4.5 10.6 4.5H13.7C14.3 4.5 14.7 4.9 14.7 5.5V7.1H16.3C16.9 7.1 17.3 7.5 17.3 8.1V11.2C17.3 11.8 16.9 12.2 16.3 12.2H14.7V13.8C14.8 14.5 14.3 14.9 13.8 14.9ZM11.6 12.9H12.7V11.3C12.7 10.7 13.1 10.3 13.7 10.3H15.3V9.2H13.7C13.1 9.2 12.7 8.8 12.7 8.2V6.5H11.6V8.1C11.6 8.7 11.2 9.1 10.6 9.1H9V10.2H10.6C11.2 10.2 11.6 10.6 11.6 11.2V12.9Z"
        fill="#2B4832"
      />
      <path
        d="M8.2 23.5C7.6 23.5 7.2 23.1 7.2 22.5V19.9C3.9 18.3 1.5 15.1 0.999999 11.4C0.899999 10.9 1.3 10.3 1.8 10.3C2.4 10.2 2.9 10.6 3 11.1C3.5 14.3 5.6 17.1 8.6 18.3C9 18.5 9.2 18.8 9.2 19.2V22.4C9.2 23 8.8 23.5 8.2 23.5Z"
        fill="#2B4832"
      />
      <path
        d="M16.2 23.5C15.6 23.5 15.2 23.1 15.2 22.5V19.3C15.2 18.9 15.4 18.5 15.8 18.4C18.8 17.1 21 14.4 21.4 11.2C21.5 10.7 22 10.3 22.5 10.4C23 10.5 23.4 11 23.3 11.5C22.8 15.2 20.4 18.4 17.1 20V22.6C17.2 23 16.7 23.5 16.2 23.5Z"
        fill="#2B4832"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.4"
          height="22.5"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

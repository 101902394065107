import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Chart from 'chart.js';

function TestHistory({ url }) {
  let isMobile = window.innerWidth < 767;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/dashboard/test`,
      withCredentials: true,
    }).then(async (res) => {
      $(document).ready(function () {
        let { score, date } = res.data.result;
        var ticksStyle = {
          fontColor: '#495057',
          fontStyle: 'bold',
        };

        var mode = 'index';
        var intersect = true;
        var $salesChart = $('#sales-chart');
        new Chart($salesChart, {
          type: 'line',
          data: {
            labels: date,
            datasets: [
              {
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                data: score,
                fill: false,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
              mode: mode,
              intersect: intersect,
            },
            hover: {
              mode: mode,
              intersect: intersect,
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  scaleEndValue: 100,
                  suggestedMax: 100,
                  // max:100,
                  // min:0,
                  // stepSize: 1,

                  // display: false,
                  // gridLines: {
                  //   display: true,
                  //   lineWidth: "4px",
                  //   color: "rgba(0, 0, 0, .2)",
                  //   zeroLineColor: "transparent"
                  // },
                  ticks: $.extend(
                    {
                      beginAtZero: true,
                    },
                    ticksStyle,
                  ),
                },
              ],
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: false,
                  },
                  ticks: ticksStyle,
                },
              ],
            },
          },
        });
      });
    });
  }, [url]);
  return (
    !isMobile && (
      <div className="bg-white p-3 chart_contain dash__shadow dash__border  dash__border">
        <div className="card-header p-0 no-border">
          <div className="d-flex justify-content-between">
            <h3 className="card-title">
              <div className="text-muted" style={{ fontSize: '12px' }}>
                Performance
              </div>
              <span className="chart__label">Test History</span>
            </h3>
          </div>
        </div>
        <div className="card-body p-2">
          <div className="position-relative mb-4" style={{ height: '275px' }}>
            <canvas id="sales-chart" height={200} />
          </div>
          <div className="d-flex flex-row justify-content-end">
            <span className="small">
              <i className="fa fa-square text-primary" /> Marks Secured
            </span>
          </div>
        </div>
      </div>
    )
  );
}

export default TestHistory;

import React, { useState } from "react";
import ReactMarkdown from "react-markdown"

function TestQuestionCard({ q, index, handleChange, handleFlag, handleMark, count }) {
  let [item, setItem] = useState(null);
  const onChange = (id, value) => {
    if (item !== value) {
      setItem(value);
      handleChange(id, value);
    }
  }
  const onMark = (id) => {
    setItem(null);
    handleMark(id)
  }
  const onFlag = (id) => {
    setItem(null);
    handleFlag(id)
  }
  return (
    <div id={`q${q.id}`} className="test-container">
      <div className="test-question">
        <span>Question {index + 1} of {count}</span>
        <h3 className="card-title pb-2">
          <ReactMarkdown
            className="t-question"
            source={q.question}
            escapeHtml={false}
          /></h3>
        <small className="text-muted">Choose the correct answer below:</small>
      </div>

      <div className="row">
      <React.Fragment>
        <Option
          id={q.id}
          value="1"
          name="A"
          handleChange={onChange}
          option={q.optionA}
          selected={item}
        />
        <Option
          id={q.id}
          value="2"
          name="B"
          handleChange={onChange}
          option={q.optionB}
          selected={item}
        />
        <Option
          id={q.id}
          value="3"
          name="C"
          handleChange={onChange}
          option={q.optionC}
          selected={item}
        />
        <Option
          id={q.id}
          value="4"
          name="D"
          handleChange={onChange}
          option={q.optionD}
          selected={item}
        />
      </React.Fragment>
      </div>
      <div align="right" className="p-2">
        {/* <div
          className="btn flag"
          id="flag_879"
          style={{ color: "#f5378e", padding: "0px" }}
          onClick={() => onFlag(q.id)}
          title="Flag this question"
        >
          <span className="fa-stack fa-lg">
            <i className="fa fa-circle fa-stack-2x" />
            <i className="fa fa-flag fa-stack-1x fa-inverse" />
          </span>
        </div> */}
        <div
          className="btn bookmark"
          id="bookmark_879"
          onClick={() => onMark(q.id)}
          style={{ color: "#FFAD3B", padding: "0px" }}
          title="Mark for review"
        >
          <span className="fa-stack fa-lg">
            <i className="fa fa-circle fa-stack-2x" />
            <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
          </span>
        </div>
      </div>
    </div>
  );
}

function Option({ id, value, option, selected, handleChange }) {
  let answerId = "answer" + value;
  return (
      <div className="col-md-6 col-sm-12 col-12 pr-sm-1 pr-2">
          <div className="form-check">
            <input
              className="col-2 form-check-input option-input radio answer879"
              type="radio"
              name={id}
              id={answerId}
              defaultValue={value}
              checked={selected === value}
              onChange={() => handleChange(id, value)}
            />
            <label className="col-10 form-check-label" htmlFor="answer879">
              <ReactMarkdown
                source={option}
                escapeHtml={false}
              />
            </label>
          </div>
        </div>
  );
}

export default TestQuestionCard;

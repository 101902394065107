import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, 
  LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon} from "react-share";
import "./ui.css";

function ShareButton({ url, title, size }) {
  return (
    <div className="d-flex share-btns">
      <div className="px-1">
        <FacebookShareButton url={url} text={title} >
          <FacebookIcon size={size} round />
        </FacebookShareButton>
      </div>
      <div className="px-1">
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={size} round />
        </TwitterShareButton>
      </div>
      <div className="px-1">
        <LinkedinShareButton url={url} title={title}>
          <LinkedinIcon size={size} round />
        </LinkedinShareButton>
      </div>
      <div className="px-1">
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={size} round />
        </WhatsappShareButton>
      </div>
      <div className="px-1">
        <TelegramShareButton url={url} title={title}>
          <TelegramIcon size={size} round />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default ShareButton

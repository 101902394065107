import React from 'react';
import "./Preloader.css";

function Preloader() {
  return (
    <div id="preloader">
      <div id="status">&nbsp;</div>
    </div>
  )
}

export default Preloader;

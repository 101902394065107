import React, {  useEffect } from "react";
import { useModuleContext } from "context/ModuleProvider";
import axios from "axios";
import KhaltiCheckout from "khalti-checkout-web";

function Khalti() {
  let { url } = useModuleContext();

  const khaltiPayment = (payload) => {
    console.log(payload);
    axios({
      method: "post",
      url: `${url}/payment/khalti`,
      data: payload,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data.youtubeLive, "Live");
    });
  };
  let config = {
    // replace this key with yours
    publicKey: "test_public_key_c5c557b938714fbf95489805d2ad2edd",
    productIdentity: "1234567890",
    productName: "Drogon",
    productUrl: "http://gameofthrones.com/buy/Dragons",
    eventHandler: {
      onSuccess(payload) {
        // hit merchant api for initiating verfication
        console.log(payload, "Success");
        khaltiPayment(payload);
      },
      // onError handler is optional
      onError(error) {
        // handle errors
        console.log(error);
      },
      onClose() {
        console.log("widget is closing");
      },
    },
    paymentPreference: [
      "KHALTI",
      "EBANKING",
      "MOBILE_BANKING",
      "CONNECT_IPS",
      "SCT",
    ],
  };

  let checkout = new KhaltiCheckout(config);
  const integrate = () => {
    console.log("Khalti ui");
    checkout.show({ amount: 10000 });
  };

  useEffect(() => {
    // checkout.show({ amount: 10000 });

  }, [])

  return (
    <div>
      Khalti Ui
      <button onClick={integrate}>Integrate</button>
    </div>
  );
}

export default Khalti;

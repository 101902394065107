import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6 14.7C5.7 14.7 5.5 14.6 5.3 14.4C4.9 14 4.9 13.4 5.3 13L6.8 11.5C7.2 11.1 7.8 11.1 8.2 11.5C8.6 11.9 8.6 12.5 8.2 12.9L6.7 14.4C6.5 14.6 6.2 14.7 6 14.7Z"
        fill="#2B4832"
      />
      <path
        d="M17.7 4.8C17.4 4.8 17.2 4.7 17 4.5L15.2 2.7C14.8 2.3 14.8 1.7 15.2 1.3C15.6 0.9 16.2 0.9 16.6 1.3L18.4 3.1C18.8 3.5 18.8 4.1 18.4 4.5C18.2 4.7 18 4.8 17.7 4.8Z"
        fill="#2B4832"
      />
      <path
        d="M13 23.4C12.7 23.4 12.4 23.3 12.2 23C11.9 22.5 12 21.9 12.4 21.6C12.7 21.4 13 21.1 13.3 20.8C16.1 18 16.1 13.6 13.3 10.8C12.9 10.4 12.9 9.79998 13.3 9.39998C13.7 8.99998 14.3 8.99998 14.7 9.39998C18.2 12.9 18.2 18.7 14.7 22.2C14.3 22.6 13.9 22.9 13.5 23.2C13.4 23.4 13.2 23.4 13 23.4Z"
        fill="#2B4832"
      />
      <path
        d="M17.2 11.2C16.9 11.2 16.5 11 16.4 10.7C16 10.1 15.6 9.60002 15.1 9.10002C14.7 8.70002 14.7 8.10002 15.1 7.70002C15.5 7.30002 16.1 7.30002 16.5 7.70002C17.1 8.30002 17.6 8.90002 18.1 9.60002C18.4 10.1 18.3 10.7 17.8 11C17.6 11.1 17.4 11.2 17.2 11.2Z"
        fill="#2B4832"
      />
      <path
        d="M16.6 23.4C16.4 23.4 16.2 23.3 16 23.2C15.6 22.9 15.5 22.2 15.8 21.8C17.9 19.2 18.4 15.7 17.3 12.6C17.1 12.1 17.4 11.5 17.9 11.3C18.4 11.1 19 11.4 19.2 11.9C20.6 15.7 19.9 19.9 17.4 23C17.1 23.3 16.8 23.4 16.6 23.4Z"
        fill="#2B4832"
      />
      <path
        d="M8.90001 14.7C8.60001 14.7 8.40001 14.6 8.20001 14.4L5.40001 11.6C5.00001 11.2 5.00001 10.6 5.40001 10.2L13 2.50001C13.2 2.30001 13.4 2.20001 13.7 2.20001C14 2.20001 14.2 2.30001 14.4 2.50001L17.2 5.30001C17.6 5.70001 17.6 6.30001 17.2 6.70001L9.60001 14.3C9.40001 14.6 9.20001 14.7 8.90001 14.7ZM7.50001 10.9L8.90001 12.3L15.1 6.10001L13.7 4.70001L7.50001 10.9Z"
        fill="#42C074"
      />
      <path
        d="M10.1 12.4C9.8 12.4 9.6 12.3 9.4 12.1L7.6 10.3C7.2 9.90004 7.2 9.30004 7.6 8.90004C8 8.50004 8.6 8.50004 9 8.90004L10.8 10.7C11.2 11.1 11.2 11.7 10.8 12.1C10.7 12.3 10.4 12.4 10.1 12.4Z"
        fill="#42C074"
      />
      <path
        d="M22.5 23.6H2C1.4 23.6 1 23.2 1 22.6C1 22 1.4 21.6 2 21.6H22.5C23.1 21.6 23.5 22 23.5 22.6C23.5 23.2 23.1 23.6 22.5 23.6Z"
        fill="#42C074"
      />
      <path
        d="M7.5 21H3.5C2.9 21 2.5 20.6 2.5 20C2.5 19.4 2.9 19 3.5 19H7.5C8.1 19 8.5 19.4 8.5 20C8.5 20.6 8 21 7.5 21Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.5"
          height="22.6"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React from "react";
import { withRouter } from "react-router-dom";
import CourseUi from "./CourseUi";

function LiscenseExam({ history }) {
  let category = [
    {
      id: "nursing",
      name: "BSc Nursing",
      count: 7,
      link: "/nursing/dashboard",
    },
    {
      id: "staffNurse",
      name: "Staff Nurse",
      count: 3,
      link: "/nursing/dashboard",
    },
  ];
  return <CourseUi category={category} history={history} />;
}

export default withRouter(LiscenseExam);

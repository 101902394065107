import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Summary({ url, moduleId }) {
  let [stat, setStat] = useState();
  useEffect(() => {
    setStat({
      total_attempts: 0,
      total_corrects: 0,
      total_wrongs: 0,
      total_question_attempted: 0
    });
    axios({
      method: 'get',
      url: `${url}/dashboard/user-stat`,
      withCredentials: true,
    }).then(async (res) => {
      setStat(res.data.results);
    });
  }, [url]);
  return (
    <div className="row" id="stat">
      <div className="col-md-12 col-12 mb-2 pl-md-2 px-0" id="stat" style={{ marginTop: '5px' }}>
        <div className="bg-white pt-3 pb-2 pl-3 pr-2  chart_contain dash__shadow dash__border">
          <h6 style={{ fontWeight: 600 }}>Your Summary</h6>
          <div className="row">
            <div className="col-md-6 col-6 pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-2">
                <div className="info-box-content pl-0 pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.total_question_attempted}
                  </span>
                  <span className="info-box-text text-muted ">Total Attempt</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#3498DB' }} />
                </span>
              </div>
            </div>
            <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-2">
                <div className="info-box-content pl-0  pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.total_corrects}{' '}
                  </span>
                  <span className="info-box-text text-muted ">Total Correct</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#1ABC9C' }} />
                </span>
              </div>
            </div>
            <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-2">
                <div className="info-box-content pl-0  pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.total_wrongs}{' '}
                  </span>
                  <span className="info-box-text text-muted">Total Wrong</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#EB984E' }} />
                </span>
              </div>
            </div>
            <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-1">
                <div className="info-box-content pl-0  pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.total_attempts}
                  </span>
                  <span className="info-box-text text-muted">Total Revised</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#BB8FCE' }} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="row stat_dash mt-2">
    //   <div className="col-12">
    //     <h6 className="mb-2">Your Summary</h6>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/taq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image">
    //           <div className="dash__points dash__primary">
    //             <p className="mb-0">{stat && stat.total}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Attempted Questions <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/tcq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image card_image2">
    //           <div className="dash__points dash__success">
    //             <p className="mb-0">{stat && stat.correct}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Correct Answers <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/twq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image card_image3">
    //           <div className="dash__points dash__danger">
    //             <p className="mb-0">{stat && (stat.total - stat.correct)}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Wrong Answers <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
  );
}

export default Summary;

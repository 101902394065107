import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import $ from 'jquery';
import './AR.css';
window.$ = $;
window.jQuery = $;

function AR() {
  let options = {
    navigation: true,
    items: 1,
    nav: true,
  };

  const models = [
    {
      name: '3D Model of Lungs',
      data: [
        {
          src: '/images/ar_thumbnail/1.png',
          name: 'Anatomy: Opaque Lungs',
          author: 'E-learning University Medical Center Groningen',
          modelId: '1cd55d26c1254ab7a5d0845fb9a207fe',
        },
        {
          modelId: 'ad7d7e16b98f421db0cda79f265fcc8d',
          name: 'Anatomy of the airways',
          author: 'E-learning University Medical Center Groningen',
          src: '/images/ar_thumbnail/2.jpeg',
        },
      ],
    },
    {
      name: 'Cardiac Anatomy',
      data: [
        {
          modelId: 'e8e8c6685e82474d8393693fd0646dc7',
          name: 'Cardiac: External View',
          author: 'E-learning University Medical Center Groningen',
          src: '/images/ar_thumbnail/5.png',
        },
        {
          modelId: '7e30590fc59547dca0ee50694281b49a',
          name: 'Half Subcostal 4 Chamber',
          author: 'E-learning University Medical Center Groningen',
          src: '/images/ar_thumbnail/3.png',
        },
      ],
    },
    {
      name: 'Brain Anatomy',
      data: [
        {
          modelId: '14800d739ecb46678d7584a401b0aa77',
          name: 'Lymphatic system',
          author: 'University of Dundee, CAHID',
          src: '/images/ar_thumbnail/8.png',
        },
      ],
    },
    {
      name: 'Biology',
      data: [
        {
          modelId: 'b7d84e5f2d5e411fbb195ab2742f2256',
          name: 'Eukaryotic Cell',
          author: 'The Center for Bio-medical Visualization',
          src: '/images/ar_thumbnail/eukaryotic_cell.png',
        },
        {
          modelId: '82bc1082b3a741749a5edac388b43478',
          name: 'Prokaryotic Cell Structure',
          author: 'Vida Systems',
          src: '/images/ar_thumbnail/prokaryotic.png',
        },
        {
          modelId: 'd637ad41b52d424c822cd211eebb6e88',
          name: 'Bacteriophage',
          author: 'Dandis',
          src: '/images/ar_thumbnail/bacteriophage.png',
        },
      ],
    },
    {
      name: 'Chemistry',
      data: [
        {
          modelId: '3738e28a7e4c4883b2424bee77bb61e8',
          name: 'Orbitals',
          author: 'Andy Todd',
          src: '/images/ar_thumbnail/orbitals.png',
        },
        {
          modelId: '0722f528033d438ba3a5c6dae7395db6',
          name: 'Zeolite Production in Lab Environment',
          author: 'Emre Baris',
          src: '/images/ar_thumbnail/lab_production.png',
        },
      ],
    },
  ];
  return (
    <div>
      {models.map((model, index) => (
        <ArCarousel key={index} model={model} options={options} />
      ))}
    </div>
  );
}

function ArCarousel({ model, ref, options }) {
  return (
    <div id="ar" className="row pl-2">
      {model.name}
      <OwlCarousel
        className="visible-slider-medium single-slider owl-carousel owl-no-dots bottom-0 owl-loaded owl-drag"
        options={options}
      >
        {model.data.map((d) => (
          <ArCard key={d.modelId} data={d} />
        ))}
      </OwlCarousel>
    </div>
  );
}

function ArCard({ data }) {
  let { name, modelId, author, src } = data;
  let server = process.env.REACT_APP_SERVER_URL;
  return (
    <div className="slider-item caption round-medium top-20 shadow-huge">
      <Link to={`ar/fullpage?model=${modelId}&name=${name}&author=${author}`}>
        <div className="card ar-card">
          <div className="ar-card-body text-center">
            <img className="thumbnail" alt="Ar Card" src={`${server}/${src}`} />
          </div>
          <div className="card-header">
            <h6>{name}</h6>
            <p>{author}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default AR;

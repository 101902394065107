import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M7.7 23.5C7.5 23.5 7.3 23.5 7 23.4C6.1 23.2 5.3 22.6 4.6 21.4C2.3 17.6 1.3 7.80001 2.5 4.20001C2.9 2.90001 3.8 1.90001 4.9 1.40001C7.9 0.200013 12.2 2.40001 12.7 2.70001C13.2 3.00001 13.4 3.60001 13.1 4.10001C12.8 4.60001 12.2 4.80001 11.7 4.50001C10.7 3.80001 7.5 2.50001 5.7 3.20001C5 3.50001 4.6 4.00001 4.4 4.70001C3.4 7.90001 4.3 17 6.3 20.4C6.7 21 7.1 21.4 7.4 21.5C8.2 21.6 8.5 21.1 9 18.7C9.4 16.8 10 14.4 12.1 13.9C12.6 13.8 13.2 14.1 13.3 14.6C13.4 15.1 13.1 15.7 12.6 15.8C11.7 16 11.3 17.6 11 19.1C10.6 21 10 23.5 7.7 23.5Z"
        fill="#42C074"
      />
      <path
        d="M20.9 18.2C20.8 18.2 20.7 18.2 20.7 18.2C20.2 18.1 19.8 17.5 20 17C21.1 12.6 21.2 7.00004 20.7 4.70004C20.5 4.10004 20.2 3.70004 19.7 3.40004C18.2 2.60004 15.4 3.40004 14.4 3.80004C14.3 3.80004 10.6 5.10004 8.7 7.30004C8.3 7.70004 7.7 7.80004 7.3 7.40004C6.8 7.00004 6.8 6.40004 7.2 5.90004C9.5 3.30004 13.5 1.90004 13.7 1.80004C14 1.70004 18 0.300042 20.5 1.60004C21.5 2.10004 22.2 3.00004 22.5 4.20004C23.2 7.10004 22.9 13.1 21.8 17.4C21.7 17.9 21.3 18.2 20.9 18.2Z"
        fill="#42C074"
      />
      <path
        d="M17.3 23.5C15 23.5 14.5 21 14 19.1C13.6 17.6 13.3 16 12.3 15.8C11.8 15.7 11.4 15.1 11.6 14.6C11.7 14.1 12.3 13.7 12.8 13.9C14.9 14.4 15.5 16.8 15.9 18.7C16.5 21.1 16.8 21.6 17.5 21.5C17.7 21.5 18.4 21.2 19.1 19.3C19.3 18.8 19.9 18.5 20.4 18.7C20.9 18.9 21.2 19.5 21 20C20.2 22.1 19.1 23.2 17.9 23.5C17.7 23.5 17.5 23.5 17.3 23.5Z"
        fill="#42C074"
      />
      <path
        d="M18.8 6.29999H18.7C18.5 6.29999 18.3 6.29999 18.1 6.29999C17.5 6.29999 17.1 5.79999 17.1 5.29999C17.1 4.79999 17.6 4.29999 18.1 4.29999C18.4 4.29999 18.6 4.29999 18.9 4.29999C19.4 4.39999 19.8 4.79999 19.8 5.39999C19.7 5.89999 19.3 6.29999 18.8 6.29999Z"
        fill="#2B4832"
      />
      <path
        d="M10.5 9.30001C10.2 9.30001 10 9.20001 9.8 9.00001C9.4 8.60001 9.5 8.00001 9.9 7.60001C10 7.50001 12.2 5.60001 15.3 4.70001C15.8 4.60001 16.4 4.90001 16.5 5.40001C16.7 5.90001 16.3 6.50001 15.8 6.60001C13.2 7.30001 11.2 9.00001 11.2 9.00001C11 9.20001 10.8 9.30001 10.5 9.30001Z"
        fill="#2B4832"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20.9"
          height="22.5"
          fill="white"
          transform="translate(2 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import 'dotenv/config';
import axios from 'axios';

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use((value) => {
  value.headers = {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  };
  return value;
});

// Add a 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let checkReg = new RegExp(`${SERVER_URL}/check`);
    if(error.message === 'Network Error'){
      return Promise.reject(error);
    }else if (checkReg && checkReg.test(error.response.config.url)) {
      return Promise.reject(error);
    } else if (401 === error.response.status) {
      window.location.reload(true);
    } else {
      return Promise.reject(error);
    }
  },
);

export default axios;

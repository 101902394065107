import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import TextEditor from "./TextEditor";
import bootbox from "bootbox";
import { useForm, Controller } from "react-hook-form";
import { useModuleContext } from "context/ModuleProvider";

function AddDiscussion({ history, location }) {
  const { moduleId, url } = useModuleContext();
  const [subject, setSubject] = useState([]);
  let [type, setType] = useState("poll");

  let question = location.state && location.state.question;
  question && (question.subjectId = question.subject);
  console.log(question, "Question");

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/poll/subject`,
      withCredentials: true,
    }).then((res) => {
      setSubject(res.data.subject);
    });
  }, [url]);

  const createDiscussion = (data) => {
    data.subject_id = data.subjectId;
    console.log(data);
    axios({
      method: "post",
      url: `${url}/discussion`,
      withCredentials: true,
      data,
    }).then((res) => {
      console.log(res.data);
      let id = res.data.discussion.discussion_id;
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      }
      history.push(`/${moduleId}/discussion/ref?id=${id}`);
    });
  };

  const createPoll = (pollData) => {
    console.log(pollData);
    let { optionA, optionB, optionC, optionD, question, subjectId } = pollData;
    let data = {
      question,
      subjectId,
      options: [
        { option: optionA },
        { option: optionB },
        { option: optionC },
        { option: optionD },
      ],
    };
    axios({
      method: "post",
      url: `${url}/poll?subjectId=${subjectId}`,
      withCredentials: true,
      data,
    }).then((res) => {
      console.log(res.data, "Poll created");
      let poll = res.data.poll;
      if (poll) {
        history.push(
          `/${moduleId}/discussion/detail?subjectId=${poll.subjectId}`
        );
      }
    });
  };

  return (
    <section className="content p-0 radius-15"  style={{marginTop: "-8px"}}>
      <div className="row">
        <div className="col-12">
          <div className="card radius-15">
            <div className="card-header">
              <h5
                className="modal-title"
                id="AskTitle"
                style={{ lineHeight: "3.5" }}
              >
                Please select your question type.
              </h5>
              <Link to={`/${moduleId}/discussion`}>
                <button
                  type="button"
                  className="close btn btn-link btn-sm px-2 py-1 m-1"
                  >
                  <span aria-hidden="true" >×</span>
                </button>
              </Link>
            </div>
            <div className="card-body top-form" style={{marginTop: "-15px"}}>
              <form className="form-row">
                <div className="form-group col-6" >
                  <div className="form-check">
                    <input
                      className="form-check-input mt-2"
                      onClick={() => setType("poll")}
                      type="radio"
                      name="poll"
                      checked={type === "poll"}
                    />
                    <label className="form-check-label" htmlFor="poll">
                      Poll
                    </label>
                    <small className="form-text text-muted" style={{ fontSize:
                    "10px",  paddingBottom: "5px"}}>
                      When you need a solution<br/> for problems
                    </small>
                  </div>
                </div>
                <div className="form-group col-6">
                  <div className="form-check ">
                    <input
                      className="form-check-input mt-2"
                      type="radio"
                      name="discussion"
                      onClick={() => setType("discussion")}
                      checked={type === "discussion"}
                    />
                    <label className="form-check-label" htmlFor="discussion">
                      Discussion
                    </label>
                    <small className="form-text text-muted" style={{fontSize: "10px", paddingBottom: "5px"}}>
                      When you want to share <br /> something
                    </small>
                  </div>
                </div>
              </form>
              {subject && type === "poll" ? (
                <PollForm
                  subject={subject}
                  question={question}
                  createPoll={createPoll}
                />
              ) : (
                <DiscussionForm
                  subject={subject}
                  createDiscussion={createDiscussion}
                  moduleId={moduleId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function DiscussionForm({ createDiscussion, subject, moduleId }) {
  const { register, handleSubmit, errors, control, setError } = useForm();
  let [editorState, setEditorState] = useState("");
  const handleChange = (e) => {
    setEditorState(e);
  };
  const handleDiscussion = (data) => {
    if (editorState === "") {
      setError("question", "required", "Field is required");
    } else {
      data.question = editorState;
      createDiscussion(data);
    }
  };
  let optionType = {
    required: "This field is required.",
    maxLength: {
      value: 200,
      message: "Field should not be at more than 200 characters.",
    },
  };
  return (
    <form className="q-form" onSubmit={handleSubmit(handleDiscussion)}>
      <div className="form-row">
        <div className="form-group col-12 ">
          <label htmlFor="title">Add an Interesting Title</label>
          <input
            ref={register(optionType)}
            type="text"
            name="title"
            className="form-control"
            id="textnam3e"
          />
          <div className="text-danger ml-2 small">
            {errors.title && errors.title.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="subjectId">Choose a Subject</label>
          <Controller
            as={
              <select name="subjectId" className="form-control custom-select">
                <option>This post is related with ...</option>
                {subject.map((s) => (
                  <option value={s.subject_id} key={s.subject_id}>
                    {s.subject_name}
                  </option>
                ))}
              </select>
            }
            name="subjectId"
            rules={{ required: "Select any subject." }}
            control={control}
          />
          <div className="text-danger ml-2 small">
            {errors.subjectId && errors.subjectId.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="question">
            Upload an image to start a discussion
          </label>
          {/* <input name="question" ref={register(optionType)} className="form-control" /> */}
          <TextEditor
            editorState={editorState}
            handleChange={handleChange}
            moduleId={moduleId}
            register={register(optionType)}
          />
          <div className="text-danger ml-2 small">
            {errors.question && errors.question.message}
          </div>
        </div>
      </div>
      <div className="float-right">
        {/* <button type="button" className="btn btn-danger mr-3" data-dismiss="modal">Cancel</button> */}
        <button type="submit" className="btn btn-dark">
          Post
        </button>
      </div>
    </form>
  );
}

function PollForm({ createPoll, subject, question }) {
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: question,
  });

  console.log(errors);
  let optionType = {
    required: "Field is required",
    minLength: {
      value: 4,
      message: "Option should be at least of length 4",
    },
  };
  return (
    <form className="q-form" onSubmit={handleSubmit(createPoll)}>
      <div className="form-row">
        <div className="form-group col-12">
          <label htmlFor="question">Add an Interesting Title</label>
          <input
            ref={register(optionType)}
            className="form-control"
            name="question"
            placeholder=""
          />
          <div className="text-danger ml-2 small">
            {errors.question && errors.question.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="subjectId">Choose a Subject</label>
          <Controller
            as={
              <select name="subjectId" className="form-control custom-select">
                <option>This post is related with ...</option>
                {subject.map((s) => (
                  <option value={s.subject_id} key={s.subject_id}>
                    {s.subject_name}
                  </option>
                ))}
              </select>
            }
            name="subjectId"
            rules={{ required: "Select any subject." }}
            control={control}
          />

          <div className="text-danger ml-2 small">
            {errors.subjectId && errors.subjectId.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="optionA">Option A</label>
          <input
            ref={register(optionType)}
            name="optionA"
            className="form-control"
            id="textname"
          />
          <div className="text-danger ml-2 small">
            {errors.optionA && errors.optionA.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="optionB">Option B</label>
          <input
            ref={register(optionType)}
            name="optionB"
            className="form-control"
            id="textname"
          />
          <div className="text-danger ml-2 small">
            {errors.optionB && errors.optionB.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="optionC">Option C</label>
          <input
            ref={register(optionType)}
            name="optionC"
            className="form-control"
            id="textname"
          />
          <div className="text-danger ml-2 small">
            {errors.optionC && errors.optionC.message}
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="optionD">Option D</label>
          <input
            ref={register(optionType)}
            name="optionD"
            className="form-control"
            id="textname"
          />
          <div className="text-danger ml-2 small">
            {errors.optionD && errors.optionD.message}
          </div>
        </div>
      </div>
      <div className="float-right">
        {/* <button type="button" className="btn btn-danger mr-3" data-dismiss="modal">Cancel</button> */}
        <button type="submit" className="btn btn-dark">
          Post
        </button>
      </div>
    </form>
  );
}

export default withRouter(AddDiscussion);

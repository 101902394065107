import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import bootbox from "bootbox";
import queryString from "query-string";
import { AuthContext } from "../../context/AuthContextProvider";
import moment from "moment";
import { getProfileLink } from "../helper";
import { useForm } from "react-hook-form";
import Nodata from "../ui/Nodata";

function VideoComment({ match, location }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let moduleId = match.params.moduleId;
  const videoId = queryString.parse(location.search).id;
  const [comment, setComment] = useState(null);
  const { currentUser } = useContext(AuthContext);

  const handleComment = (e) => {
    e.preventDefault();
    let el = e.target;
    let text = e.target.comment.value;
    axios({
      method: "post",
      url: `${serverUrl}/${moduleId}/video/comment?videoId=${videoId}`,
      withCredentials: true,
      data: { text },
    }).then((res) => {
      console.log(res.data, "Post Comment");
      if (res.data.error) {
        bootbox.alert(`Error ${res.data.error}`);
      } else {
        el.comment.value = "";
        let newComment = res.data.comment;
        newComment.user = currentUser;
        if (comment) {
          setComment([newComment, ...comment]);
        } else {
          setComment([newComment]);
        }
      }
    });
  };

  const deleteComment = (commentId) => {
    axios({
      method: "delete",
      url: `${serverUrl}/${moduleId}/video/comment?commentId=${commentId}`,
      withCredentials: true,
    }).then((res) => {
      console.log("Comment Deleted");
      if (res.data.error) {
        bootbox.alert(`${res.data.error}`);
      } else {
        let newComment = comment.filter((c) => {
          return c.id !== commentId;
        });
        console.log(newComment);
        setComment(newComment);
      }
    });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${serverUrl}/${moduleId}/video/comment?videoId=${videoId}`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Comments");
      if (res.data.error) {
        bootbox.alert(`${res.data.error}`);
      } else {
        setComment(res.data.comment);
      }
    });
  }, [videoId]);
  return (
    <div className="commentsContainer pl-2 pr-2 pt-1 pb-0">
      <form className="mb-3 pt-2 clearfix" onSubmit={handleComment}>
        <img
          src={getProfileLink(currentUser.imageId)}
          alt=""
          className="neww circle img-circle commentbox comment-photo float-left-sm float-none mr-1 d-inline-block mb-2"
        />
        {/* <p className="small d-inline-block">{currentUser.name}</p> */}
        <textarea
          className="form-control form-control-sm mt-1 mb-1 border-light video-comment-area float-right"
          rows={3}
          name="comment"
          placeholder="Add a comment."
          style={{ display: "inline", maxWidth: "calc(100% - 40px)" }}
          required
        />
        <div className="major__btn pull-right">
          <button
            style={{ maxWidth: "200px", minWidth: "100px" }}
            className="btn btn-dark"
          >
            Post
          </button>
        </div>
      </form>
      {comment && comment.length !== 0 ? (
        comment.map((c) => (
          <Comment
            key={c.id}
            comment={c}
            deleteComment={deleteComment}
            moduleId={moduleId}
          />
        ))
      ) : (
        <Nodata title="This post has no comment." />
      )}
    </div>
  );
}

function Comment({ comment, moduleId, deleteComment }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  const { currentUser } = useContext(AuthContext);
  let [reply, setReply] = useState(null);
  const [liked, setLiked] = useState(false);
  let { text, id, user, vote, answer, ent_date, videoVote } = comment;

  let type = videoVote && videoVote.type;
  const viewReply = () => {
    axios({
      method: "get",
      url: `${serverUrl}/${moduleId}/video/comment/reply?commentId=${comment.id}`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Replies");
      if (res.data.error) {
        bootbox.alert(`${res.data.error}`);
      } else {
        setReply(res.data.reply);
      }
    });
  };

  const addVote = () => {
    if (!type && !liked) {
      setLiked(true);
      axios({
        method: "post",
        url: `${serverUrl}/${moduleId}/video/comment/vote/?commentId=${comment.id}`,
        withCredentials: true,
        data: { type: 1 },
      }).then((res) => {
        console.log(res.data, "Votes");
        if (res.data.error) {
          bootbox.alert(`${res.data.error}`);
        } else {
        }
      });
    }
  };

  const addReply = (e) => {
    e.preventDefault();
    let el = e.target;
    axios({
      method: "post",
      url: `${serverUrl}/${moduleId}/video/comment/reply?commentId=${comment.id}`,
      withCredentials: true,
      data: { text: el.text.value },
    }).then((res) => {
      console.log(res.data, "Reply");
      if (res.data.error) {
        bootbox.alert(`${res.data.error}`);
      } else {
        el.text.value = "";
        let newReply = res.data.reply;
        newReply.user = currentUser;
        reply ? setReply([newReply, ...reply]) : setReply([newReply]);
      }
    });
  };

  const deleteReply = (replyId) => {
    axios({
      method: "delete",
      url: `${serverUrl}/${moduleId}/video/comment/reply?replyId=${replyId}&commentId=${comment.id}`,
      withCredentials: true,
    }).then((res) => {
      console.log("Comment Deleted");
      if (res.data.error) {
        bootbox.alert(`${res.data.error}`);
      } else {
        let newReply = reply.filter((r) => {
          return r.id !== replyId;
        });
        setReply(newReply);
      }
    });
  };

  return (
    <div className="w-100 my-2 pb-3">
      <div>
        <div className="float-left">
          <img
            src={getProfileLink(user.imageId)}
            alt=""
            className="neww circle img-circle commentbox comment-photo float-left-sm float-none mr-1 d-inline-block mb-2"
          />
        </div>
        <div className="d-inline">
          <p className="mb-0 d-block">{text}</p>
          <p className="small text-muted">
            By {user.name}, {moment(ent_date).fromNow()}
          </p>
        </div>
        <div className="float-right">
          <div className="dropdown first-list-single">
            <button
              className="btn btn-transparent dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {currentUser.id === user.id && (
                <div
                  className="dropdown-item"
                  onClick={() => deleteComment(comment.id)}
                >
                  delete
                </div>
              )}
              <div className="dropdown-item">report</div>
            </div>
          </div>
        </div>
      </div>
      <div className="reaction row justify-content-between">
        <button
          onClick={addVote}
          type="button"
          className={`btn rounded btn-transparent ${
            (type || liked) && "liked"
          }`}
        >
          {/* <img src="https://img.icons8.com/ios/50/000000/applause.png" alt="clap" width={20} /> */}
          <i className="far fa-thumbs-up"></i>
          &nbsp;{vote} votes
        </button>
        <button
          type="button"
          onClick={() => !reply && viewReply()}
          className="btn rounded btn-transparent"
          data-toggle="collapse"
          data-target={`#comment${id}`}
          aria-expanded="false"
          aria-controls={`comment${id}`}
        >
          <i className="far fa-comment" />
          &nbsp;{answer} replies
        </button>
      </div>
      <div className="collapse pl-4 ml-2" id={`comment${id}`}>
        <ReplyForm addReply={addReply} />
        {reply &&
          reply.map((r) => (
            <Reply
              key={r.id}
              reply={r}
              deleteReply={deleteReply}
              currentUser={currentUser}
            />
          ))}
      </div>
    </div>
  );
}

function Reply({ reply, deleteReply, currentUser }) {
  let {
    text,
    user: { name, imageId, id: userId },
    ent_date,
  } = reply;
  return (
    <div className="w-100 pt-1 pb-1 v-author">
      <div>
        <div className="float-left-sm float-none">
          <img
            src={getProfileLink(imageId)}
            alt=""
            className="neww circle img-circle float-left mr-1 d-inline-block"
          />
        </div>
        <div className="d-inline-block">
          <p className="mb-0 small d-block">{name}</p>
          <p className="small text-muted mb-0 pl-0 d-block">
            {moment(ent_date).fromNow()}
          </p>
        </div>
        <div className="float-right">
          <div className="dropdown first-list-single">
            <button
              className="btn btn-transparent dropdown-toggle"
              type="button"
              id="dropdownMenuButton2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton2"
            >
              {/* <div className="dropdown-item">edit</div> */}
              {currentUser.id === userId && (
                <div
                  className="dropdown-item "
                  onClick={() => deleteReply(reply.id)}
                >
                  delete
                </div>
              )}
              <div className="dropdown-item">report</div>
            </div>
          </div>
        </div>
      </div>
      <div className="questionPost pl-4 ml-1">
        <p>{text}</p>
        {/* <div className="reaction row justify-content-around">
          <button type="button" className="btn rounded btn-transparent"><i className="fab fa-angellist" />&nbsp;Upvote</button>
        </div> */}
      </div>
    </div>
  );
}

function ReplyForm({ addReply }) {
  const { currentUser } = useContext(AuthContext);
  const { register, errors } = useForm();

  return (
    <div className="w-100 pt-2 pb-1 mt-2 v-author border-top">
      <img
        src={getProfileLink(currentUser.imageId)}
        alt=""
        className="neww circle img-circle  float-left-sm float-none mr-1 d-inline-block"
      />
      <p className="small d-inline-block">{currentUser.name}</p>
      <form onSubmit={addReply} className="clearfix mt-1">
        <textarea
          ref={register({
            required: {
              message: "This is required.",
            },
            maxLength: {
              value: 200,
              message: "Should not be more than 200 cahracters.",
            },
          })}
          className="form-control form-control-sm mb-1 border-light"
          rows={2}
          name="text"
          defaultValue={null}
          placeholder="Add a reply."
          required
        />
        <div className="input-error">{errors.text && errors.text.message}</div>
        <div className="major__btn pull-right">
          <button
            style={{ maxWidth: "200px", minWidth: "100px" }}
            className="btn btn-dark px-1 py-0"
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
}

export default withRouter(VideoComment);

import {
  REQUEST_QUESTIONS,
  RECEIVE_QUESTIONS,
  HANDLE_OPTIONS_CHANGE,
  HANDLE_MARK,
  HANDLE_FLAG,
  HANDLE_SUBMIT,
  SUBMIT_FAILED,
  ADD_LOCATION,
  REQUEST_FAILED,
} from '../constants/actionTypes';

export const initialState = {
  test: {},
  questions: [],
  checkedCount: 0,
  flagCount: 0,
  loading: false,
  markCount: 0,
  fetchError: false,
  data: null,
  location: null,
  isSubmiting: false,
};

const getOptionByValue = (value) => {
  const options = ['A', 'B', 'C', 'D'];
  return options[value - 1];
};

const testReducer = (state = initialState, action) => {
  let newArr = [];
  switch (action.type) {
    case REQUEST_QUESTIONS:
      return { ...state, loading: true };
    case REQUEST_FAILED:
      return { ...state, loading: false, error: action.error };
    case RECEIVE_QUESTIONS: {
      let questions = action.test && action.test.testQuestions;
      if (questions) {
        return {
          ...state,
          loading: false,
          questions,
          test: action.test,
          count: questions.length || 0,
        };
      } else {
        return state;
      }
    }
    case HANDLE_OPTIONS_CHANGE: {
      let { checkedCount, flagCount, markCount } = state;
      let newArr = state.questions.map((q) => {
        if (q.id === action.id) {
          !q.selected && checkedCount++;
          q.flag && flagCount--;
          q.mark && markCount--;
          if (q.selected !== action.value) {
            q = {
              ...q,
              selected: action.value,
              mark: false,
              flag: false,
              recent: 'selected',
            };
          }
        }
        return q;
      });
      return {
        ...state,
        questions: newArr,
        checkedCount,
        flagCount,
        markCount,
      };
    }
    case HANDLE_MARK: {
      let { checkedCount, flagCount, markCount } = state;
      newArr = state.questions.map((q) => {
        if (q.id === action.id) {
          !q.mark && markCount++;
          q.flag && flagCount--;
          q.selected && checkedCount--;
          q = { ...q, mark: true, flag: false, selected: null, recent: 'mark' };
        }
        return q;
      });
      console.log(state.markCount);
      return {
        ...state,
        questions: [...newArr],
        flagCount,
        markCount,
        checkedCount,
      };
    }
    case HANDLE_FLAG: {
      let { checkedCount, flagCount, markCount } = state;
      newArr = state.questions.map((q) => {
        if (q.id === action.id) {
          !q.flag && flagCount++;
          q.selected && checkedCount--;
          q.mark && markCount--;
          // console.log(q);
          q = { ...q, flag: true, mark: false, selected: null, recent: 'flag' };
        }
        return q;
      });
      return {
        ...state,
        questions: [...newArr],
        flagCount,
        markCount,
        checkedCount,
      };
    }
    case HANDLE_SUBMIT: {
      let cntCorrect = 0,
        cntWrong = 0,
        resultArr = [],
        flagArr = [],
        markArr = [];
      let { questions, testId, position } = state;
      if (questions) {
        questions.forEach((q) => {
          //selected value is from 1-4
          //if not selected it is undefined or null
          if (q.selected || q.flag) {
            // console.log(
            //   q.selected,
            //   q.answer,
            //   "Printing the answer",
            //   getOptionByValue(q.selected)
            // );
            resultArr.push({ selected: q.selected, questionId: q.id, flag: q.flag });
            if (getOptionByValue(q.selected) === q.answer.trim()) {
              cntCorrect++;
            } else {
              cntWrong++;
            }
          }
          // if (q.flag) {
          //   flagArr.push(q.id);
          // }
          if (q.mark) {
            markArr.push(q.id);
          }
        });
        let data = {
          cntCorrect,
          cntWrong,
          flagArr,
          markArr,
          date: new Date(),
          testId,
          resultArr,
          position: position
            ? {
                type: 'Point',
                coordinates: [position.latitude, position.longitude],
              }
            : null,
        };
        return {
          ...state,
          data,
          isSubmiting: true,
        };
      } else {
        return state;
      }
    }
    case ADD_LOCATION: {
      let position = action.position && action.position.coords;
      return {
        ...state,
        position,
      };
    }
    case SUBMIT_FAILED: {
      return { ...state, isSubmiting: false };
    }
    default:
      return state;
  }
};
export default testReducer;

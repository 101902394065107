import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import './Course.css';

function Home({ history }) {
  let category = [
    { id: 'mbbs', name: 'MBBS', imageId: 'logo.png' },
    {
      id: 'nursing',
      name: 'Nursing Plus',
      imageId: 'nameplus.png',
    },
  ];
  return <Redirect to="/mbbs/dashboard" />

  return (
    <div className="background">
      <div className="container">
        <div className="row panel new__categories-table">
          <div className="col-12 p-md-4 p-2">
            <div className="row">
              {/* <div className="col-md-6 col-12 px-0 pb-2 text-md-left"></div> */}
              <div className="col-md-6 col-12 px-0 pb-2">
                <h3 className="d-block mb-3">Explore by category</h3>
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input type="text" className="form-control" placeholder="Search" />
                </div>
              </div>
            </div>
          </div>
          {category.map((c) => {
            return <Category key={c.id} course={c} history={history} />;
          })}
        </div>
      </div>
    </div>
  );
}

function Category({ course, history }) {
  let { id, name, imageId } = course;
  let link = `/${id}/dashboard`;
  id === 'nursing' && (link = `./courseSelect`);

  return (
    <div onClick={() => history.push(link)} className="col-md-6 col-6 new__categories-plan">
      <div className="text-right">
        <Link to={link} className="btn btn-outline-dark">
          <i className="fas fa-plus" />
        </Link>
      </div>

      <img
        src={`/images/${imageId}`}
        alt="Name Nursing Plus"
        height="100px"
        style={{ objectFit: 'contain', maxHeight: '100px', width: '350px', maxWidth: '100%' }}
      />
      <Link className="new__categories-price" to={link}>
        {name}
      </Link>
    </div>
  );
}

export default withRouter(Home);

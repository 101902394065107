import React from 'react';
import { useModuleContext } from 'context/ModuleProvider';
import ProfileUpdate from './ProfileUpdate';

function AccountTab() {
    let { moduleId } = useModuleContext();
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12">
              <div className="px-4 ml-0">
                <p className="profile__label">Account</p>
              </div>
          </div>
        </div>
        <ProfileUpdate moduleId={moduleId} />
      </div>
    );
  }

  export default AccountTab;
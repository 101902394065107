import React from "react";
import { Link } from "react-router-dom";
import { useModuleContext } from "context/ModuleProvider";

function NotFound() {
    let {moduleId} =  useModuleContext();
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div
              className="tab-content text-center"
              id="nav-tabContent"
              style={{ paddingTop: "40px" }}
            >
              <img
                src="/images/safebox.png"
                alt="NOT FOUND"
                style={{ maxWidth: "250px", paddingBottom: "80px" }}
              />
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                style={{ paddingBottom: "40px" }}
              >
                You have not started your practice!
                <br />
                Would you like to start your practice now?
              </div>
              <Link to={`/${moduleId}/practice`} className="btn btn-success">
                Start Practice &nbsp;
                <i className="nav-icon ion-ios-analytics" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;

import React, { useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { useModuleContext } from 'context/ModuleProvider';
import { useAuthContext } from '../../context/AuthContextProvider';

function EsewaSuccess({ location }) {
  const [verifing, setVerifing] = useState(false);
  const [message, setMessage] = useState('');
  const { oid, amt, refId } = queryString.parse(location.search);
  let { moduleId, url } = useModuleContext();
  let { updateCurrentUser } = useAuthContext();
  let history = useHistory();

  var params = {
    amt: amt,
    rid: refId,
    pid: oid,
  };

  const verifyPayment = () => {
    setVerifing(true);
    axios({
      method: 'post',
      url: `${url}/payment/esewa`,
      params,
      withCredentials: true,
    })
      .then((res) => {
        setVerifing(false);
        if (res.data.error) {
          setMessage(res.data.message);
        } else {
          let paymentId = res.data.payment && res.data.payment.id;
          if (res.data.user) {
            updateCurrentUser(res.data.user);
          }
          history.replace(`/${moduleId}/payment/detail?paymentId=${paymentId}`, {});
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setVerifing(false);
      });
  };

  useEffect(() => {
    if (oid) {
      verifyPayment();
    }
  }, []);

  return (
    <div>
      {verifing && (
        <div>
          Please wait verifing the payment.
          {message && (
            <Link to={`/${moduleId}/payment/esewa`}>
              <button>Please try again </button>
            </Link>
          )}
        </div>
      )}
      <div>{message}</div>
    </div>
  );
}

export default withRouter(EsewaSuccess);

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

export const AuthContext = React.createContext();

const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [streakUpdated, setStreakUpdated] = useState(false);
  let serverUrl = process.env.REACT_APP_SERVER_URL;

  const loadUser = async () => {
    return axios({
      method: 'get',
      url: `${serverUrl}/check`,
    })
      .then((res) => {
        if (res.data.user) {
          setCurrentUser(res.data.user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadUser();
  }, []);

  const checkUser = async () => {
    return axios({
      method: 'get',
      url: `${serverUrl}/check`,
      withCredentials: true,
    });
  };

  const login = (data) => {
    return axios({
      method: 'post',
      url: `${serverUrl}/auth/login`,
      withCredentials: true,
      data: data,
    });
  };

  const registerUser = (data) => {
    return axios({
      method: 'post',
      url: `${serverUrl}/auth/register`,
      withCredentials: true,
      data: data,
    });
  };

  const updateProfile = async (data, moduleId) => {
    let res = await axios({
      method: 'post',
      url: `${serverUrl}/${moduleId}/profile/`,
      withCredentials: true,
      data,
    });
    if (res.data.user) {
      setCurrentUser(res.data.user);
    }
    return res.data;
  };

  const updateCurrentUser = (currentUser) => {
    setCurrentUser(currentUser);
  };

  const logout = async () => {
    window.localStorage.setItem('token', null);
    setCurrentUser(null);
  };

  const verifyUser = (data, history) => {
    axios({
      method: 'post',
      url: `${serverUrl}/auth/verify`,
      withCredentials: true,
      data: data,
    }).then((res) => {
      if (res.data.token) {
        window.localStorage.setItem('token', res.data.token);
        setCurrentUser(res.data.user);
        history.replace('/');
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        login,
        logout,
        updateProfile,
        updateCurrentUser,
        loadUser,
        registerUser,
        verifyUser,
        streakUpdated,
        setStreakUpdated,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const state = useContext(AuthContext);
  return state;
};

export default AuthContextProvider;

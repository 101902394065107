import React, {  useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import TestNotFound from "./TestNotFound";
import Preloader from "../Preloader/Preloader";
import { useHistory, useLocation } from "react-router-dom";
import ResultQuestionCard from "./ResultQuestionCard";
import { useModuleContext } from "context/ModuleProvider";
import "./Test.css";

function UserResult() {
  const { url } = useModuleContext();
  const [questions, setQuestions] = useState(null);
  let history = useHistory();
  const { state } = useLocation();
  let testId = null;
  if(!state){
    history.replace('/');
  }else{
    const { id } = state;
    testId = id;
  }
  useEffect(() => {
    if(testId){
      axios({
        method: "get",
        url: `${url}/test/userResult?testId=${testId}`,
        withCredentials: true
      }).then(res => {
        console.log(res.data);
        setQuestions(res.data.questions || []);
      });
    }
  }, [url]);
  return (
    <section className="content p-0" style= {{marginTop: "-20px"}}>
      {questions ?
        <div className="container-fluid">
          <div className="row">
            {questions.length !== 0 ? questions.map((question, index) => (
              <ResultQuestionCard key={question.id} question={question} count={questions.length} index={index + 1} />
            )) : <TestNotFound />}
          </div>
        </div> : <Preloader />}
    </section>
  );
}

export default withRouter(UserResult);

import React, { useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { TestContext } from '../../context/TextContext';
import { postResult } from '../../context/TextAction';
import bootbox from 'bootbox';
import { useModuleContext } from 'context/ModuleProvider';
import TestCounter from './TestCounter';

function TestNav({ history }) {
  let [
    {
      isSubmiting,
      error,
      test: { end },
    },
    dispatch,
  ] = useContext(TestContext);
  let { moduleId } = useModuleContext();
  let [show, setShow] = useState(false);
  console.log(end, 'From test');
  return (
    <nav
      className="navbar fixed-top navbar-expand navbar-light bg-white"
      style={{ boxShadow: '2.5px 4.33px 30px 0 rgba(64, 87, 191, 0.1)' }}
    >
      <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
        <Link className="ml-md-5 ml-sm-4 d-block" title="go back" to={`/${moduleId}/test`} id="back">
          <i className="fa fa-chevron-left" />
        </Link>
        <Link
          className="nav-link nav-hide ml-md-5 ml-sm-4 d-block"
          data-widget="control-sidebar"
          data-slide="true"
          to="#"
        >
          {!show && <i onClick={() => setShow(true)} className="fa fa-th-large" /> }
          {show && <i onClick={() => setShow(false)} className="fa fa-close" /> }

        </Link>
        <div>
          <ul className="navbar-nav ml-auto nav-menu">
            <li className="my-nav-item1" style={{ padding: '5px' }}>
              <h5 className="px-0 font-weight-bold mb-0 d-flex align-center">
                <i className="fa fa-clock-o"></i>&nbsp;{end && <TestCounter end={end} />}
              </h5>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto"></ul>
        </div>
        <button
          id="submit"
          className="btn btn-sm btn-primary mr-md-5 mr-sm-4"
          type="submit"
          disabled={error}
          onClick={() =>
            bootbox.confirm({
              size: 'small',
              title: '',
              message: 'Are you sure you want to submit the test?',
              callback: function (result) {
                if (result) {
                  dispatch(postResult('Thank you. Your test has been sucessfully submitted.', history));
                }
              },
            })
          }
        >
          { isSubmiting ? 'Submitting ..' : 'Submit'}
        </button>
      </div>
    </nav>
  );
}

export default withRouter(TestNav);

import React from 'react';

function PaymentList({ subscriptionTypes, paymentTypes, selectedPayment, setSelectedPayment, register, errors }) {
  let hidden = subscriptionTypes && subscriptionTypes.length === 0;
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mt-md-1 mt-2 mb-2">
      <div className="d-flex flex-row">
        {paymentTypes &&
          paymentTypes.map((p) => {
            let { id, title, logo } = p;
            let selected = selectedPayment ? selectedPayment.id === id : false;
            return (
              <div key={id} className="payment-type p-2 m-2 bg-white radius-10">
                <input
                  id={`payment-${id}`}
                  onChange={(e) => {
                    console.log('Changed ', e.target.value);
                    setSelectedPayment(p);
                  }}
                  className="burmanRadio__input burmanAbsolute pr-2"
                  value={id}
                  checked={selected}
                  type="radio"
                  name="payment"
                  ref={register({
                    required: 'Please select a payment option to continue.',
                  })}
                />
                <label htmlFor={`payment-${id}`} className="burmanRadio__label paymentOptions mb-0">
                  <img src={logo} alt="{title}" style={{ maxWidth: '120px', paddingBottom: '0px' }} />
                </label>
              </div>
            );
          })}
      </div>
      {errors.payment && (
        <div className="text-danger small ml-2 bg-danger py-2 px-4 border rounded slideLeft">
          {errors.payment.message}
        </div>
      )}
    </div>
  );
}

export default PaymentList;

import React from 'react';
import { Controller } from 'react-hook-form';

function ModalForm({ id, title, children }) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-labelledby={id} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ minHeight: '65px' }}>
            <h5 className="modal-title" id={id}>
              {title}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{ maxHeight: '80vh', padding: '0px', overflow: 'auto' }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormGroup({ id, title, register, error, ...props }) {
  return (
    <div className="form-group">
      <label htmlFor={id} className="col-form-label poll-label">
        {title}
      </label>
      <input ref={register} className="form-control" id={id} name={id} {...props} />
      <div className="text-danger small">{error && error.message}</div>
    </div>
  );
}

function FormGroupSelect({ id, list, control, title, error, value, display }) {
  return (
    <div className="form-group">
      <label htmlFor={id} className="col-form-label poll-label">
        {title}
      </label>
      <Controller
        as={
          <select name={id} className="form-control custom-select">
            <option hidden>This post is related with ...</option>
            {list.map((s) => (
              <option value={s[value]} key={s[value]}>
                {s[display]}
              </option>
            ))}
          </select>
        }
        name={id}
        rules={{ required: 'Select any item.' }}
        control={control}
      />
      <div className="text-danger ml-2 small">{error && error.message}</div>
    </div>
  );
}

function FormGroupArea({ id, title, register, error, ...props }) {
  return (
    <div className="form-group">
      <label htmlFor={id} className="col-form-label poll-label">
        {title}
      </label>
      <textarea ref={register} className="form-control" id="pollquestion" name={id} {...props} />
      <div className="text-danger ml-2 small">{error && error.message}</div>
    </div>
  );
}

function FormButton({ text }) {
  return (
    <div className="float-right clearfix px-2 major__btn mt-2 text-center">
      <button type="button" className="btn btn-light btn-sm mx-1 my-0" data-dismiss="modal">
        Cancel
      </button>
      <button type="submit" className="btn btn-dark btn-sm mx-1 my-0">
        {text || 'Post'}
      </button>
    </div>
  );
}

export { ModalForm, FormGroup, FormGroupSelect, FormGroupArea, FormButton };

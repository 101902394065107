import React, { useState, useEffect } from "react";
import axios from "axios";
import DiscussionCard from "../DiscussionCard";
import Nodata from "../../ui/Nodata";
import bootbox from "bootbox";

function RecentDiscussionTap({ moduleId }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const [discussion, setDiscussion] = useState(null);
  useEffect(() => {
    axios({
      method: "get",
      url: `${server}/${moduleId}/discussion/recent`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Discussion");
      setDiscussion(res.data.discussion);
    });
  }, []);

  const addVote = (discussion_id, check) => {
    if (!check) {
      axios({
        method: "post",
        url: `${server}/${moduleId}/discussion/t/vote`,
        withCredentials: true,
        data: { type: 1, discussion_id },
      }).then((res) => {
        if (!res.data.error) {
          let data = res.data;
          let newDiscussion = discussion.map((d) => {
            if (d.discussion_id === discussion_id) {
              d.vote = data.vote;
              d.type = data.type;
            }
            return d;
          });
          setDiscussion(newDiscussion);
        } else {
          bootbox(res.data.error);
        }
      });
    }
  };

  return (
    <>
      {discussion && discussion.length !== 0 ? (
        <DiscussionCard
          discussion={discussion}
          addVote={addVote}
          moduleId={moduleId}
        />
      ) : (
        <Nodata
          title="You haven't added any discussion yet."
          src="/images/noComment.jpg"
        />
      )}
    </>
  );
}
export default RecentDiscussionTap;
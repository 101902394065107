import React from 'react';
import { isIOS } from '../helper';

function SocialLogin({ login }) {
  let url = process.env.REACT_APP_SERVER_URL;
  let clientUrl = process.env.REACT_APP_CLIENT_URL;
  // let isIOS = isIOS;
  let isMobile = window.innerWidth < 767;
  // var googleLogin = `${url}/auth/google?callbackUrl=${clientUrl}`
  return (
    <div className="other__options pt-3 text-center">
      {login ? (
        <p className="small text-muted">
          Or create account using social media
          {/* <Link
            to="/login/register"
            style={{
              textDecoration: "underline",
            }}
            className="text-primary"
          >
            here
          </Link> */}
        </p>
      ) : (
        <div></div>
      )}
      {/* <p class="small text-muted">Or Signin with</p> */}

      <div className="login__social">
        <div className="row d-flex justify-content-center">
          {/* For IOS*/}
          {isIOS() && (
            <a href={`${url}/auth/apple?callbackUrl=${clientUrl}`} className="circular-icons text-center apple">
              <i className="fab fa-apple" />
            </a>
            
          )}
          {isIOS() && (
            <a href={`${url}/auth/google?callbackUrl=${clientUrl}`} className="circular-icons text-center google">
              <i className="fab fa-google" />
            </a>
          )}  
          {isIOS() && (
            <a href={`${url}/auth/facebook?callbackUrl=${clientUrl}`} className="circular-icons text-center facebook">
              <i className="fa fa-facebook" />
            </a>
          )}  
          {/* <a href={`${url}/auth/google?callbackUrl=${clientUrl}`} className="circular-icons text-center google">
            <i className="fab fa-google" />
          </a> */}

          {/* For Android mobile view*/}
          {!isIOS() && isMobile &&(
            <a href={`${url}/auth/google?callbackUrl=${clientUrl}`} className="text-center custom__google">
              <i className="fab fa-google" /> &nbsp;&nbsp;<p style={{fontSize:"14px"}}>Login with Google</p>
            </a>
            // <a href={`${url}/auth/google?callbackUrl=${clientUrl}`}>
            //   <GoogleButton className="custom__google"/>
            // </a>
          )}

          {/* For Web view*/}
          {!isMobile && (
            <a href={`${url}/auth/google?callbackUrl=${clientUrl}`} className="circular-icons text-center google">
              <i className="fab fa-google" />
            </a>
          )}
          {!isMobile && (
            <a href={`${url}/auth/facebook?callbackUrl=${clientUrl}`} className="circular-icons text-center facebook">
              <i className="fa fa-facebook" />
            </a>
          )}
          {/* <a href={`${url}/auth/facebook?callbackUrl=${clientUrl}`} className="circular-icons text-center facebook">
            <i className="fa fa-facebook" />
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default SocialLogin;

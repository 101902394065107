import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import { ProfileImg } from "../helper";

function DiscussionCard({ discussion, addVote, moduleId }) {
  return (
    <>
      {discussion &&
        discussion.map((d) => {
          let {
            discussion_id,
            title,
            question,
            vote,
            view,
            ent_date,
            type,
            answer,
            subject_name,
            user: { name, imageId },
          } = d;
          return (
            <div key={discussion_id} className="dis-categories-1">
              <div className="first-list">
                <div className="my-2">
                  <div className="mb-3">
                    <div className="float-left">
                      <ProfileImg
                        className="img-circle comment-photo mr-2"
                        src={imageId}
                        alt="profile"
                      />
                    </div>
                    <div className="d-inline-block">
                      <p className="mb-0 name__is">{name}</p>
                      <p className="small text-muted mb-0 d-block pl-0">
                        {moment(ent_date).format("MMM DD YYYY")}
                        <span className="this__course ml-2">
                          {subject_name}
                        </span>
                      </p>
                    </div>
                  </div>
                  <Link to={`/${moduleId}/discussion/ref?id=${discussion_id}`}>
                    <div className="replyPost text-left">
                      <h6 className="d-block">{title}</h6>
                      <div className="mb-1 text-muted d-block pl-0">
                        <ReactMarkdown
                          className="d-markdown"
                          source={question}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </Link>
                  <hr />

                  <div className="reaction row justify-content-between px-2">
                    <button
                      disabled={type}
                      onClick={() => addVote(discussion_id, type)}
                      type="button"
                      className={`btn rounded ${
                        type
                          ? "btn-primary btn-clap py-1 px-3"
                          : "btn-transparent"
                      } `}
                    >
                      <img
                        alt=""
                        src="https://img.icons8.com/ios/50/000000/applause.png"
                        width={20}
                      />
                      &nbsp;{vote} claps
                    </button>
                    <Link
                      to={`/${moduleId}/discussion/ref?id=${discussion_id}`}
                    >
                      <button
                        type="button"
                        className="btn rounded btn-transparent"
                        data-toggle="collapse"
                        data-target="#commentMe"
                        aria-expanded="false"
                        aria-controls="commentMe"
                      >
                        <i className="far fa-comment-alt" />
                        &nbsp;{answer} replies
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default DiscussionCard;

import React from 'react';
import ProfileUpdate from './ProfileUpdate';

function AccountSettings() {
  return (
    <div className="bg-white" style={{marginTop: "-10px"}}>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-12">
            <div className="px-4 ml-0">
              <p className="profile__label">Account</p>
            </div>
        </div>
      </div>
      <ProfileUpdate />
    </div>
  );
}

export default AccountSettings;

import React, { useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AuthContext } from '../context/AuthContextProvider';
import axios from 'axios';
// import './NotVerify.css';

function NotVerify({ history }) {
  const { currentUser, verifyUser } = useContext(AuthContext);
  const [error, setError] = useState('');
  let email = currentUser ? currentUser.email : null;
  const server = process.env.REACT_APP_SERVER_URL;
  const resendToken = () => {
    setError('Please wait! Sending email might take a few second');
    return axios({
      method: 'get',
      url: `${server}/auth/resend`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((res) => {
      console.log(res.data.user, 'userModule');
      if (res.data.user) {
        setError('Please check your email.');
      } else {
        history.push(`/login`);
      }
    });
  };

  const hideEmail = (email) => {
    let hide = email.split('@')[0].length - 2;
    let r = new RegExp('.{' + hide + '}@', 'g');
    return email.replace(r, '****@');
  };
  const handleToken = async (e) => {
    e.preventDefault();
    let token = e.target.token.value.trim();
    console.log(token.value);
    if (token) {
      let error = await verifyUser(token);
      if (error) {
        setError(error);
      } else {
        history.push('/');
      }
    }
  };

  return (
    <section
      className="container-fluid" id="verify__email"
      style={{
      //   backgroundImage: 'url("/images/formbg.jpg")',
        // padding: '10px 20px',
        // background: '#ededed'
      //   backgroundPosition: '102% 13%',
      //   backgroundSize: 'contain',
      }}
    >
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 col-12 verify__wrapper">
          <div className="email__container text-center shadow">
            <div className="text-left free__btn">
              <Link to={'/login'}>
                <button type="button" className="btn btn-outline-dark">
                  <i className="fas fa-chevron-left" />
                </button>
              </Link>
            </div>
            {/* <img src= "logo192.png" className="custom__logo"/> */}
            <i className="fas fa-envelope-open-text fa-5x text-dark" />
            <div>
              {email ? (
                <div className="Email__contents">
                  <h2 style={{ padding: '15px' }}>Enter the token</h2>
                  {/* <h3 style={{ padding: '15px' }} className="text-muted">Hi {currentUser.name},</h3> */}
                  <p className="mb-0">
                    Almost there! We've sent a token to email {hideEmail(email)}. To verify that this is your account, please copy
                    and paste your token here.
                  </p>
                </div>
              ) : (
                'Please Login Again'
              )}
            </div>
            <div className="error">{error}</div>
            <form onSubmit={handleToken}>
              <input className="verifyInput my-2" type="text" name="token" id="token" placeholder="Token" />
              <button className="btn btn-success mt-1 mb-2 res__btn" type="submit">
                Submit
              </button>
            </form>
            
            <div className="resend__button mb-4">
              <button onClick={resendToken} type="button" className="btn btn-danger resend res__btn">
                Resend Token
              </button>
            </div>

            <span className="text-muted">Or You can go back to</span>
            <div className="forget-footer">
              <Link to="/login">Login</Link> |
              <Link to="/login/register">Register</Link>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default withRouter(NotVerify);

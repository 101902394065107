import React, { useEffect, useContext } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { AuthContext } from "../context/AuthContextProvider";
import NotVerify from "./NotVerify";
import Preloader from "./Preloader/Preloader";

function Verify({ history }) {
  const { currentUser, loading } = useContext(AuthContext);
  useEffect(() => {
    console.log("Verify component render", currentUser);
    if (!loading) {
      if (
        currentUser &&
        currentUser !== undefined &&
        currentUser.verified !== undefined
      ) {
        if (currentUser.verified === true) {
          history.push(`/`);
        }
      } else {
        history.push(`/login`);
      }
    }
  }, [loading]);
  return !loading ? (
    currentUser && verifyValue(currentUser.verified) ? (
      <Redirect to="/" />
    ) : (
        <NotVerify />
        // <VerifyToken/>
      )
  ) : (
      <Preloader />
    );
}

const verifyValue = value => {
  let type = typeof value;
  if (type === "string") {
    if (parseInt(value)) {
      return true;
    }
  } else if (type === "boolean") {
    if (value) {
      return true;
    }
  } else if (type === "number") {
    if (value) {
      return true;
    }
  }
  return false;
};

export default withRouter(Verify);

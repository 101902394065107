import React, { Component } from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import axios from "axios";
import "../Discussion/Editor.css";
import bootbox from "bootbox";
let serverUrl = process.env.REACT_APP_SERVER_URL;

const checkMimeType = (event) => {
  let files = event.target.files
  let err = []
  let val = true
  const types = ['image/png', 'image/jpeg', 'image/gif']
  for (var x = 0; x < files.length; x++) {
    if (types.every(type => files[x].type !== type)) {
      err[x] = files[x].type + ' is not a supported format\n';
      val = false
    }
  };
  for (var z = 0; z < err.length; z++) {
    bootbox.alert(err[z])
    event.target.value = null
  }
  return val;
}
const maxSelectFile = (event) => {
  let files = event.target.files
  if (files.length > 3) {
    const msg = 'Only 3 images can be uploaded at a time'
    event.target.value = null
    bootbox.alert(msg)
    return false;
  }
  return true;
}
const checkFileSize = (event) => {
  let files = event.target.files
  let size = 2000000
  let err = [];
  for (var x = 0; x < files.length; x++) {
    if (files[x].size > size) {
      err[x] = files[x].type + 'is too large, please pick a smaller file\n';
    }
  };
  for (var z = 0; z < err.length; z++) {
    bootbox.alert(err[z])
    event.target.value = null
  }
  return true;
}

class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.quillRef = null;
    this.reactQuillRef = null;
  }
  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  }
  uploadFile = (file) => {
    let url = `${serverUrl}/${this.props.moduleId}`
    const data = new FormData()
    data.append('file', file);
    return axios({
      method: "post",
      url: `${url}/discussion/upload`,
      data,
      withCredentials: true,
    }).then(res => {
      console.log(res.data);
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      }
      return res.data.imageId;
    }).catch(err => {
      console.log(err)
    })
  }

  imageHandler = (image, callback) => {
    console.log("Image handlers called");
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async function (event) {
      const file = input.files[0];
      if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
        console.log('User trying to uplaod this:', file);
        this.attachQuillRefs();
        const id = await this.uploadFile(file);
        const range = this.quillRef.getSelection();
        const link = `${serverUrl}/images/discussions/${id}`;
        console.log(link);
        this.quillRef.insertEmbed(range.index, 'image', link);
        this.quillRef.insertText(range.index + 1,
          ' ', "silent")
      }
    }.bind(this);
  }

  render() {
    const modules = {
      toolbar: {
        container: [
          [{ 'header': '1' }, { 'header': '2' },],
          [{ size: ['normal', 'large'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image'],
          ['clean']
        ],
        handlers: {
          image: this.imageHandler
        },
      },

      clipboard: {
        matchVisual: false,
      }
    }

    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video'
    ]
    const handleKey = (a) => {
      console.log(editorState)
    }

    const { editorState, handleChange } = this.props;
    return (
      <div>
        <ReactQuill
          ref={(el) => { this.reactQuillRef = el }}
          value={editorState}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          bounds={'.app'}
          name="question"
          onKeyDown={handleKey}
          id="discussion-editor"
        />
      </div>
    );
  }
}

export default TextEditor





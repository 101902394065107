import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M22.5 12.9C22.2 12.9 21.9 12.8 21.7 12.5C18.7 8.2 15.5 6 12.3 6C9 6 5.8 8.3 2.8 12.5C2.5 13 1.9 13.1 1.4 12.8C0.999999 12.4 0.899999 11.8 1.2 11.4C4.6 6.5 8.3 4 12.2 4C16.1 4 19.9 6.5 23.3 11.4C23.6 11.9 23.5 12.5 23 12.8C22.9 12.9 22.7 12.9 22.5 12.9Z"
        fill="#42C074"
      />
      <path
        d="M20.5 15.5C20.3 15.5 20 15.4 19.8 15.2C19.4 14.8 19.4 14.2 19.7 13.8C20.4 13.1 21 12.2 21.6 11.3C21.9 10.8 22.5 10.7 23 11C23.5 11.3 23.6 11.9 23.3 12.4C22.6 13.4 21.9 14.3 21.2 15.1C21 15.4 20.7 15.5 20.5 15.5Z"
        fill="#42C074"
      />
      <path
        d="M12.2 19.9C8.3 19.9 4.5 17.4 1.1 12.5C0.9 12.1 1 11.4 1.4 11.1C1.9 10.8 2.5 10.9 2.8 11.4C5.8 15.7 9 17.9 12.2 17.9C14.2 17.9 16.2 17 18.2 15.3C18.6 14.9 19.2 15 19.6 15.4C20 15.8 19.9 16.4 19.5 16.8C17.2 18.9 14.7 19.9 12.2 19.9Z"
        fill="#42C074"
      />
      <path
        d="M12.2 16.5C9.7 16.5 7.6 14.4 7.6 11.9C7.6 9.39999 9.7 7.29999 12.2 7.29999C14.7 7.29999 16.8 9.39999 16.8 11.9C16.8 14.4 14.8 16.5 12.2 16.5ZM12.2 9.39999C10.8 9.39999 9.6 10.5 9.6 12C9.6 13.5 10.7 14.6 12.2 14.6C13.6 14.6 14.8 13.5 14.8 12C14.8 10.5 13.7 9.39999 12.2 9.39999Z"
        fill="#2B4832"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.5"
          height="15.9"
          fill="white"
          transform="translate(1 4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

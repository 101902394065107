import React from 'react'
import { Link } from "react-router-dom";

function NoMatch() {
  console.log("NoMatch called");
  return (
    <div className="no-match">
      <div className="text">
        Opps! This page does not exist
      </div>
      <Link to="/">
        <button className="btn">Visit Avyaas</button>
      </Link>
    </div>
  )
}

export default NoMatch

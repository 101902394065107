import React, { useEffect, useCallback } from "react";
import testReducer, { initialState } from "./TextReducer";
import {
  fetchQuestions,
  _handleMark,
  _handleChange,
  _handleFlag,
  addLocation,
  postResult
} from "./TextAction";
import useThunkReducer from "react-hook-thunk-reducer";
import { useHistory } from "react-router-dom";

export const TestContext = React.createContext(null);

export const TestProvider = ({ children, testId, moduleId, isQuestionSet }) => {
  const [state, dispatch] = useThunkReducer(testReducer, {
    ...initialState,
    moduleId,
    testId,
    isQuestionSet,
  });
  const getLocation = () => {
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          dispatch(addLocation(pos));
        },
        function () {
          console.log("Location error");
        }
      );
  };
  let history = useHistory();
  useEffect(() => {
    getLocation();
  }, []);

  const handleMark = useCallback((id) => {
    dispatch(_handleMark(id));
  });

  const handleFlag = useCallback((id) => {
    dispatch(_handleFlag(id));
  });

  const handleChange = useCallback((id, value) => {
    dispatch(_handleChange(id, value));
  });

  const handleSubmit = useCallback((message) => {
    dispatch(postResult(message, history)) 
   });

  const dispatchProps = { handleChange, handleMark, handleFlag, handleSubmit };

  return (
    <TestContext.Provider value={[state, dispatch, dispatchProps]}>
      {children}
    </TestContext.Provider>
  );
};

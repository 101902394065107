import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

console.log(process.env.REACT_APP_ENV, "Env")
if (process.env.REACT_APP_ENV === "prod") {
  console.log = function () { };
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

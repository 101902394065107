import React, { useEffect, useRef } from "react";
import "./Test.css";
import Chart from "chart.js";
import { withRouter } from "react-router-dom";
import { useModuleContext } from "context/ModuleProvider";

function TestModal({ history, data }) {
  const { moduleId } = useModuleContext();
  const chartEl = useRef(null);
  useEffect(() => {
    data &&
      new Chart(chartEl.current, {
        type: "doughnut",
        data: {
          labels: ["Correct", "Wrong"],
          datasets: [
            {
              data: [data.cntCorrect, data.cntWrong],
              backgroundColor: ["rgba(54, 162, 235, 1)", "rgba(255,99,132,1)"]
            }
          ]
        }
      });
  }, [data]);
  return (
    <div
      className="modal fade"
      id="testModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="testModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Exam Result
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 col-md-6 test-summary">
                <p id="resultCorrect" />
                <h6>Correct Answers: </h6>
                {data && data.cntCorrect}
                <p />
                <br />
                <p id="resultWrong" />
                <h6>Wrong Answer: </h6>
                {data && data.cntWrong}
                <p />
                <br />
                <p id="resultDate" />
                <h6>Completed Date: </h6>
                {data && data.date && data.date.toLocaleString()}
                <p />
                <br />
              </div>
              <div className="col-12 col-md-6">
                <canvas
                  ref={chartEl}
                  id="testChart"
                  height={177}
                  width={355}
                  style={{ display: "block", width: "355px", height: "200px" }}
                />
              </div>
            
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="btn btn-dark"
              data-dismiss="modal"
              onClick={() => {
                history.push(`/${moduleId}/dashboard`);
              }}
            >
              Finish
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TestModal)
//This route if there is user or not
import React, { useContext } from 'react'
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContextProvider";
import Preloader from '../Preloader/Preloader'

function UserRoute({ children, ...rest }) {
  let { currentUser, loading } = useContext(AuthContext)
  console.log(!!currentUser);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !loading ? (
          currentUser &&
            currentUser.verified !== undefined ? (
              currentUser.verified ? (
                children
              ) : (
                  <Redirect
                    to={{
                      pathname: `/verify`,
                      state: { from: location }
                    }}
                  />
                )
            ) : (
              <Redirect
                to={{
                  pathname: `/login`,
                  state: { from: location }
                }}
              />
            )
        ) : (
            <Preloader/>
          )
      }
    />
  )
}



export default UserRoute

import React from 'react';

function Footer({ test }) {
  return (
    <footer
      className={`main-footer ${test && 'test-main-footer d-none'}`}
      style={{ fontSize: '11px', zIndex: 100, maxHeight: '50px' }}
    >
      <strong>
        Copyright © 2024
        <a className="text-primary" href="https://ayata.com.np/">
          {' '}
          Ayata Inc.{' '}
        </a>
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 2.0.0
      </div>
    </footer>
  );
}

export default Footer;

import React, { useContext } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import AuthContextProvider, { AuthContext } from '../context/AuthContextProvider';
import queryString from "query-string";
export function Analytics(location) {
  const { id, userId } = queryString.parse(location.search);

  return (
    <AuthContextProvider>
      <Route exact path="/a/:userId" />
    </AuthContextProvider>
  );
}

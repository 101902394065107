import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import Preloader from "../Preloader/Preloader";
import { useModuleContext } from "context/ModuleProvider";
import "./Practice.css";

function PracticeContainer() {
  const { url } = useModuleContext();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/practice`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      let subjects = res.data.subjects;
      subjects && setSubjects(subjects);
    });
  }, [url]);
  return !loading ? <Practice subjects={subjects} /> : <Preloader />;
}

function Practice({ subjects }) {
  return (
    <section className="px-2" style={{ marginTop: "-8px" }}>
      <div className="row ">
        <div className="col-12 px-0">
          <div className="card no-shadow">
            <div
              className="card-header"
              style={{ margin: "5px -10px auto", borderBottom: 0 }}
            >
              <h5 className="card-title col-md-9 select-subject-title">
                Select any subject to start
              </h5>
              <div className="line pl-2" />
            </div>
            <div className="p-card-body practice">
              <div className="row">
                {subjects.map((s) => (
                  <SubjectCard key={s.subject_id} detail={s} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SubjectCard({ detail }) {
  let server = process.env.REACT_APP_SERVER_URL;
  let { moduleId } = useModuleContext();
  let { subject_name, subject_id, module_id } = detail;
  let name = subject_name.toLowerCase().replace(/\s/g, "-");
  let link = `/${module_id}/practice/unitDetail?subjectId=${subject_id}`;

  return (
    <div className="col-xs-1-10 sub-card" align="center">
      <Link to={link}>
        <button
          type="button"
          className="btn btn-transparent btn-circle border-image"
        >
          <img
            src={`${server}/images/subjectLight/icons/${moduleId}/${name}.png`}
            className="responsive-img"
            alt={name}
          />
        </button>
      </Link>
      <p className="py-1 font-weight-bold">{subject_name}</p>
    </div>
  );
}

export default withRouter(PracticeContainer);

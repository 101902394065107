import React from "react";
import PclIcon from "./pcl";
import NursingIcon from "./nursing";
import MbbsIcon from "./mbbs";
import StaffNurseIcon from "./staffNurse";
import PharmacyIcon from "./pharmacy";
import AgvetIcon from "./agvet";
import CmltIcon from "./cmlt";
import DentalIcon from "./dental";
import HealthIcon from "./health";
import OptometryIcon from "./optometry";
import RadiographyIcon from "./radiography";

function Icon({ id }) {
  switch (id) {
    case "pcl":
      return <PclIcon />;
    case "nursing":
      return <NursingIcon />;
    case "mbbs":
      return <MbbsIcon />;
    case "staffNurse":
      return <StaffNurseIcon />;
    case "pharmacy":
      return <PharmacyIcon />;
    case "agvet":
      return <AgvetIcon />;
    case "cmlt":
      return <CmltIcon />;
    case "dental":
      return <DentalIcon />;
    case "health":
      return <HealthIcon />;
    case "optometry":
      return <OptometryIcon />;
    case "radiography":
      return <RadiographyIcon />;
    default:
      return <PclIcon />;
  }
}

export default Icon;

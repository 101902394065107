import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useModuleContext } from '../../context/ModuleProvider';
import queryString from 'query-string';

function PaymentFailed({ location }) {
  let { moduleId } = useModuleContext();
  const { fu } = queryString.parse(location.search);

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
        padding: '10px 16px',
      }}
    >
      <h1
        style={{
          color: '#1A0203',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '140%',
          letterSpacing: '0.18px',
        }}
      >
        Error
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '18px',
        }}
      >
        <p
          style={{
            color: '#333',
            fontFamily: 'Poppins,sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '120%',
            textTransform: 'capitalize',
          }}
        >
          Sorry, Your Payment has failed.
        </p>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '18px',
            flexWrap: 'wrap',
          }}
        >
          <Link
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '100px',
              padding: '8px 10px',
              backgroundColor: '#6c757d',
              borderRadius: '6px',
              color: '#fff',
              fontFamily: 'Inter,sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '120%',
              letterSpacing: '-0.16px',
            }}
            to={
              fu === 'zoom'
                ? `/${moduleId}/zoom-payment`
                : fu === 'test-series'
                ? `/${moduleId}/test-series-payment`
                : fu === 'subs'
                ? `/${moduleId}/payment`
                : fu === 'tests'
                ? {
                    pathname: `/${moduleId}/test-payment`,
                    state: {
                      id: 'comprehensive2024',
                      name: 'Test Series - 2024',
                      price: 1,
                    },
                  }
                : '/'
            }
          >
            Retry
          </Link>
          <Link
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '100px',
              padding: '8px 10px',
              backgroundColor: '#3498db',
              borderRadius: '6px',
              color: '#fff',
              fontFamily: 'Inter,sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '120%',
              letterSpacing: '-0.16px',
            }}
            to={'/'}
          >
            Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PaymentFailed);

import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "../../context/AuthContextProvider";
import { getProfileLink } from "../helper";
import io from "socket.io-client";
import Peer from "simple-peer";
import "./VideoLive.css";
import moment from "moment";
import LiveMessage from "./LiveMessage";
import { Tab, TabItems, TabContents, TabItem, TabContent } from "../ui/Tab";


let client = {};
let socket;
function VideoLive({ match, location }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let { currentUser } = useContext(AuthContext);
  const liveId = queryString.parse(location.search).id;
  let userId = currentUser.id;
  let [peerSrc, setPeerSrc] = useState(null);
  let [liveHost, setLiveHost] = useState({});
  let [video, setVideo] = useState(null);
  let [message, setMessage] = useState(null);

  function InitPeer(type) {
    let peer = new Peer({
      initiator: false,
      trickle: false,
    });
    peer.on("stream", function (stream) {
      console.log("stream of host found.", stream);
      setPeerSrc(stream);
      setMessage("Sucessfully connected to client.")
      console.log(peerSrc);
    });
    peer.on("error", (err) => console.log("Peer Error", err));
    peer.on("close", function () {
      console.log("Host is not sending data.");
      peer.destroy();
    });
    return peer;
  }

  //for peer of type not init
  function FrontAnswer(data) {
    let { offer, from } = data;
    console.log("font Offer received from host ", data);
    let peer = InitPeer("notInit");
    peer.on("signal", (answer) => {
      console.log("Back offering sending answer to host", answer);
      socket.emit("Answer", { answer, from, userId });
    });
    peer.signal(offer);
    client.peer = peer;
    client.isLiteninng = false;
  }

  function SignalAnswer(data) {
    let { answer, from } = data;
    console.log("Single answer received from client.", data);
    client.gotAnswer = true;
    let peer = client.peer;
    peer.signal(answer);
    socket.emit("UserJoined", { from, userId });
  }

  function RemovePeer() {
    console.log("Remove peer called.");
    if (client.peer) {
      client.peer.destroy();
    }
    setPeerSrc(null);
  }

  useEffect(() => {
    socket = io(serverUrl);
    socket.on("BackOffer", FrontAnswer);
    socket.on("BackAnswer", SignalAnswer);
    socket.on("LiveHost", (hosts) => setLiveHost(hosts));
    socket.on("Disconnect", RemovePeer);
    socket.on("VideoData", (data) => setVideo(data));
    socket.on("VideoMessage", ({ message }) => setMessage(message));
    socket.emit("NewClient", liveId);
  }, []);

  return (
    <div className="row" style={{ marginTop: "-13px" }} >
      <div className="col-sm-12 col-12 order-first order-sm-1 px-1">
        <div className="row">
          <div className="col-sm-8  px-0 col-12">
            <div className="pr-sm-0 bg-white">
              {/* <VideoDisplay key={video.id} video={video} moduleId={moduleId} /> */}
              {peerSrc ? <Video
                srcobject={peerSrc}
              /> : <video
                  controls
                  className="peerVideo w-100"
                />}
              {message && <div>{message}</div>}
              {video &&
                <div className="videoInstructions bg-white px-sm-3 px-2 pt-sm-3 pt-2">
                  <div className="v-title">
                    <h5 className="d-inline-block">{video.title}</h5>
                    <div className="float-right">
                      <button className="btn text-muted btn-transparent btn-sm mb-1"><i className="fa fa-share" /></button>
                    </div>
                  </div>
                  <div className="v-author mb-2">
                    <img src={getProfileLink(video.user.imageId)} alt="" className="img-circle circle d-inline-block bg-cover m-0" />
                    <div className="d-inline-block pl-1">
                      <p className="text-muted">{video.user.name}</p>
                    </div>
                  </div>
                  <div className="row justify-content-between text-center">
                    <div className="col-sm-4 col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">{video.level}</div>
                        Category
                        </div>
                    </div>
                    <div className="col-sm-4  col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">{moment.utc(video.length * 1000).format('HH:mm:ss')}</div>
                        Duration
                      </div>
                    </div>
                    <div className="col-sm-4  col-4">
                      <div className="stats">
                        <div className="rounded bg-light h4 px-2 py-2">{video.view}</div>
                        Views
                      </div>
                    </div>
                  </div>
                  <div className="v-author-sub">
                    <div className="spoiler">
                      <p className="hidden-text">
                        {video.description}
                      </p>
                    </div>
                  </div>
                  {/* <VideoOption video={video} moduleId={moduleId} next={next} /> */}
                </div>}</div>
          </div>
          <div className="video-sidebar col-sm-4 pl-2" style={{ minHeight: "100vh" }}>
            <Tab >
              <TabItems tabId="course">
                <TabItem id="videoChat" title="Chat Messages" selected={true} />
              </TabItems>
              <TabContents tabId="course">
                <TabContent id="videoChat" selected={true}>
                  {socket &&
                    <LiveMessage roomId={liveId} socket={socket} />}
                </TabContent>
              </TabContents>
            </Tab>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(VideoLive);

function Video({ srcobject }) {
  let videoRef = useRef(null);
  console.log(srcobject);
  useEffect(() => {
    videoRef.current.srcObject = srcobject;
  }, []);
  const handleClick = () => {
    let video = videoRef.current;
    console.log(video);
    video.paused ? video.play() : video.pause();
  };
  return (
    <div className="w-100">
      <video
        controls
        onClick={handleClick}
        className="peerVideo w-100"
        srcobject={srcobject}
        ref={videoRef}
      />
    </div>
  );
}

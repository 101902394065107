import React, { useEffect, useState } from 'react';
import './LiveVideos.css';
import { useLocation, useParams, useHistory, withRouter } from 'react-router-dom';
import { useModuleContext } from '../../context/ModuleProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import Preloader from '../Preloader/Preloader';
import DataNotFound from '../Live/DataNotFound';

import { FaPlay } from 'react-icons/fa';

const toReadableDate = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    return `${date.toDateString()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
  } else return '';
};

function LiveVideos() {
  const location = useLocation();
  let history = useHistory();
  const { moduleId } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const live_id = queryParams.get('live_id');
  const liveGroupId = queryParams.get('liveGroupId');
  const live_title = queryParams.get('live_title');

  const { url } = useModuleContext();

  const [isLoading, setIsLoading] = useState();

  const [recordings, setRescordings] = useState();

  useEffect(() => {
    if (!live_id || !moduleId || !liveGroupId || !live_title) {
      history.replace('/', {});
    } else {
      setIsLoading(true);
      axios({
        method: 'get',
        url: `${url}/recording/category?liveId=${live_id}&liveGroupId=${liveGroupId}`,
      })
        .then((res) => {
          setRescordings(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
          history.replace('/', {});
        });
    }
  }, []);

  return !isLoading ? (
    recordings && recordings.length !== 0 ? (
      <section className="content zoom-recordings">
        <h1>{live_title}</h1>

        <div className="recording-items">
          {recordings.map((item, i) => {
            return (
              <div
                className="item"
                key={i}
                onClick={() => {
                  history.replace(`/${moduleId}/recording?record_id=${item.id}`, {});
                }}
              >
                <div className="top">
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}/images/recordings/${item.thumbnail}`}
                    alt="Thumbnail"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/images/defaultthumbnail.jpg';
                    }}
                  />
                  <FaPlay />
                </div>

                <div className="bottom">
                  <div className="content">
                    <p className="title">{item.title}</p>
                    <p>Views: {item.view}</p>
                  </div>
                  <p className="date">{toReadableDate(item.created_at)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    ) : (
      <DataNotFound />
    )
  ) : (
    <Preloader />
  );
}

export default withRouter(LiveVideos);

import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import bootbox from "bootbox";
import "./PracticeQuestion.css";
import PracticeQuestionUI from "./PracticeQuestionUI";
import { useModuleContext } from "context/ModuleProvider";

function QuestionDayPractice({ location, history }) {
  const { moduleId, url } = useModuleContext();
  const [questionDay, setQuestionDay] = useState(null);
  const [question, setQuestion] = useState(null);
  const [selected, setSelected] = useState(null);
  const [submited, setSubmited] = useState(null);
  let id = queryString.parse(location.search).id;

  useEffect(() => {
    fetchQuestion();
  }, [moduleId]);

  const fetchQuestion = () => {
    let finalUrl = `${url}/practice/questionofday?id=${id}`;
    axios({
      method: "get",
      url: `${finalUrl}`,
      withCredentials: true
    }).then(res => {
      let result = res.data.question_day_result;
      console.log(res.data);
      setQuestionDay(res.data);
      setQuestion(res.data.regularQuestion);
      if (result) {
        console.log(result.check_attempt, "check attempt");
        setSelected(result.check_attempt.toString());
        setSubmited(true);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log("button pressed", selected);
    if (selected != null) {
      console.log("submit pressed");
      let data = {
        question_of_day_id: questionDay.id,
        check_attempt: selected
      };
      axios({
        method: "post",
        url: `${url}/practice/questionofday`,
        withCredentials: true,
        data
      }).then(res => {
        console.log(res.data);
        if (res.data) {
          setSubmited(true);
        }
      });
    } else {
      bootbox.alert("Please choose at least one of the given asnwers.");
    }
  };

  const handleChange = e => {
    console.log(e.target.value);
    if (!submited) {
      setSelected(e.target.value);
    }
  };

  const handleNext = e => {
    e.preventDefault();
    history.push(`/${moduleId}/practice/start?subjectId=random`)
  };

  const addToRevision = () => {
    let question_id = question.id;
    console.log("Add To revision clicked", question_id);
    let data = { question_id };
    axios({
      method: "post",
      url: `${url}/revision`,
      withCredentials: true,
      data
    }).then(res => {
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else if (res.data.created) {
        bootbox.alert("Added Question to the Revision");
      } else {
        bootbox.alert("Question is alredy in the revision section");
      }
    });
  };

  const answer = question && question.answer;
  return (
    <PracticeQuestionUI
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      handleChange={handleChange}
      question={question}
      answer={answer}
      addToRevision={addToRevision}
      selected={selected}
      submited={submited}
      questionOfDay={true}
    />
  );
}

export default withRouter(QuestionDayPractice);

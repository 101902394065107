import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import TestHistory from './TestHistory';
import Summary from './Summary';
import RecentAnnouncement from './RecentAnnouncement';
import { useModuleContext } from 'context/ModuleProvider';
import $ from 'jquery';
import { isIOS } from '../helper';
import './Dashboard.css';
import logo from '../../assests/logo.png';
import apk from '../../assests/nameonline.apk';

import { BiMessage } from 'react-icons/bi';
import { FaDollarSign, FaRegCalendar } from 'react-icons/fa';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

function Dashboard() {
  let { moduleId, url } = useModuleContext();

  useEffect(() => {
    $('.dropdown-el').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).toggleClass('expanded');
      $('#' + $(e.target).attr('for')).prop('checked', true);
    });
    $(document).click(function () {
      $('.dropdown-el').removeClass('expanded');
    });
  }, []);

  return (
    <div className="w-100" id="dash__wrapper">
      <div className="dashboard__container">
        <div className="main_dash">
          <div className="row dash__header">
            <div className="col-12 col-md-8 px-0">
              {/* Header  */}
              <Welcome url={url} moduleId={moduleId} />
              <TestUpdate url={url} moduleId={moduleId} />
              <TestSeries url={url} moduleId={moduleId} />
              <div className="row mt-2">
                {/* Practice History */}
                <div className="col-md-12 px-sm-0 pb-sm-2 px-1" style={{ paddingBottom: '10px' }}>
                  <TestHistory url={url} moduleId={moduleId} />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 px-0 px-md-0">
              <div className="dash__stats">
                <div className="row">
                  {/* Test Summary */}
                  <div className="p-0">
                    <Summary url={url} moduleId={moduleId} />
                  </div>
                  {/* CountDown */}
                  <CountDown />
                  {/* Notice  */}
                  <RecentAnnouncement />
                  {!isIOS() && (
                    <div
                      style={{ background: 'rgb(255 193 7 / 5%)' }}
                      className="mt-2 w-100 d-flex justify-content-between align-items-center p-3 chart_contain dash__shadow dash__border"
                    >
                      <h6 style={{ fontWeight: 600, margin: 0 }}>Download the latest app of Name Online</h6>
                      <a href={apk} download={`nameonline.apk`} target="__blank" className="btn btn-dark">
                        Download
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {/* Download Bochures */}
              {moduleId === 'nursing' && (
                <div className="dash__stats">
                  <div className="row">
                    <div className="col-md-12 col-12 mb-2 pl-md-2 px-0">
                      <div className="bg-white p-3 chart_contain dash__shadow dash__border">
                        <h6 style={{ fontWeight: 600 }}>Download Brochures</h6>
                        <img src="/images/dashboard/downloader.jpg" alt="e-book download" />
                        <div className="no-img">
                          <ul className="products-list product-list-in-card ">
                            <li className="item">
                              <div className="product-img">
                                <img className="img-circle" src="/images/dashboard/e-book.jpg" alt="E-book" />
                              </div>
                              <div className="product-info">
                                <a
                                  href={`/download/NameProspectus.pdf`}
                                  className="product-title"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  Name Nursing Prospectus
                                  <br />
                                  <p className="text-muted notice-desc">Click to download</p>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CountDown() {
  const [activeDate, setActiveDate] = useState(false);
  const [examOver, isExamOver] = useState(false);
  const { moduleId } = useModuleContext();
  let dates = [
    {
      id: 'cee',
      name: 'CEE (Common Entrance Exam)',
      date: '2022/03/15',
    },
  ];

  const handleChange = (e) => {
    let value = e.target.value;
    console.log(value, 'Changed');
    dates.map((d) => {
      if (d.id === value) {
        setActiveDate(d.date);
        return 1;
      }
      return 1;
    });
  };

  const getDateString = (date) => {
    if (date) {
      let currentDate = moment.utc();
      let examDate = moment.utc(date, 'YYYY/MM/DD').diff(currentDate);
      let durationLeft = moment.utc(examDate).format('DDD');
      console.log(date, durationLeft, 'Date ,,,,');
      return [...durationLeft.split('')];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (moment.utc(dates[0].date).diff(moment.utc()) > 0) {
      setActiveDate(dates[0].date);
    } else {
      isExamOver(true);
    }
  }, []);

  let dateString = getDateString(activeDate);

  return (
    <div className="col-md-12 col-12 mb-2 pl-md-2 px-0" style={{ marginTop: '5px' }}>
      <div className="bg-white p-3 chart_contain dash__shadow dash__border">
        <h6 style={{ fontWeight: 600 }}>CEE (Common Entrance Exam)</h6>
        {examOver ? (
          <div>
            CEE exam has already ended for this fiscal year. Please study tuned, we shall post the date as soon as its
            available.
          </div>
        ) : (
          <div>
            <div className="countdown">
              {dateString.map((s, index) => (
                <div key={index} className="countdown__digit">
                  {s}
                </div>
              ))}
            </div>
            <div className="countdown__message">days until examination(approx)</div>
          </div>
        )}

        <img src="/images/alram.png" alt="alarm" />
      </div>
      <div
        style={{ background: 'rgb(255 193 7 / 5%)' }}
        className="mt-2 d-flex justify-content-between align-items-center p-3 chart_contain dash__shadow dash__border"
      >
        <h6 style={{ fontWeight: 600, margin: 0 }}>Live Classes</h6>
        <NavLink to={`/${moduleId}/live`} className="nav-link">
          <button className="btn btn-dark">Join Now</button>
        </NavLink>
      </div>
    </div>
  );
}

const TestSeries = ({ url, moduleId }) => {
  const [testSeires, setTestSeries] = useState([]);

  const toReadableDate = (dateString) => {
    if (dateString) {
      let date = new Date(dateString);
      return `${date.toDateString()}`;
    } else return '';
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/test-series-payment/test-series`,
      withCredentials: true,
    }).then(async (res) => {
      setTestSeries(res.data.data);
    });
  }, []);
  return (
    <>
      {testSeires && testSeires.length !== 0 ? (
        <div className="test_series_container">
          <h6 style={{ fontWeight: 600 }}>Test Series</h6>
          <hr style={{ marginTop: '5px', marginBottom: '-5px' }} />
          <br />
          <div className="test_series">
            {testSeires.map((item, i) => {
              return (
                <div className="item" key={i}>
                  <h4>{item.title}</h4>
                  <div className="content">
                    <div className="left">
                      <p>
                        <FaRegCalendar style={{ color: 'skyblue' }} /> Start Date: {toReadableDate(item.start_date)}
                      </p>
                      <p>
                        <BiMessage style={{ color: 'red' }} /> {item.description}
                      </p>
                      <p>
                        <FaDollarSign style={{ color: 'green' }} /> Fee: Rs{item.amount}
                      </p>
                    </div>
                    <Link
                      className="btn btn-dark"
                      to={item.has_paid ? `/${moduleId}/test?test_type=series` : `/${moduleId}/test-series-payment`}
                    >
                      {item.has_paid ? 'See Tests' : 'Pay Now'}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function TestUpdate({ url, moduleId }) {
  let [test, setTest] = useState(null);
  let redirect = '';
  let text = '';
  let header = '';
  let query = test && `testId=${test.id}`;
  let testPaymentLink = `/${moduleId}/test-payment`;
  let startTestLink = `/${moduleId}/test/instruction`;
  if (test) {
    let startDate = moment.utc(test.start);
    let endDate = moment.utc(test.end);
    let currentDate = moment.utc();
    if (startDate <= currentDate && endDate >= currentDate) {
      if (test.done) {
        redirect = `/${moduleId}/dashboard`;
        text = 'Submitted';
        header = `Ends in ${endDate.local().fromNow()}`;
        query = '';
      } else {
        redirect = test.price ? (test.hasPaid ? startTestLink : testPaymentLink) : startTestLink;
        text = test.price ? (test.hasPaid ? 'Start' : 'Purchase Test') : 'Start';
        header = `Exam will end ${endDate.fromNow()}`;
      }
    } else if (startDate > currentDate) {
      redirect = `/${moduleId}/test`;
      if (startDate < currentDate.add(12, 'hour')) {
        header = `Exam will start ${startDate.fromNow()}`;
      } else {
        header = `Exam will start ${startDate.fromNow()}`;
      }
      text = 'Check Test';
      query = '';
    } else if (endDate < currentDate) {
      redirect = `/${moduleId}/test/result`;
      header = 'Completed';
      text = 'View Result';
    }
  }
  let testTime = moment(test && test.start).format('DD MMM');
  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/test/recent`,
      withCredentials: true,
    }).then(async (res) => {
      setTest(res.data.test);
    });
  }, []);

  return test ? (
    <div className="row mt-2">
      <div className="col-md-12 px-0">
        <div className="bg-white p-3 chart_contain dash__shadow dash__border  dash__border">
          {test && (
            <>
              <h6 style={{ fontWeight: 600 }}>Daily Exam</h6>
              <hr style={{ marginTop: '5px', marginBottom: '-5px' }} />
              <br />
              <div className="row d-flex align-items-center">
                <div className="col-md-8 pl-0">
                  <div className="row">
                    <div className="col-auto pl-0 pr-0">
                      <div
                        className="info-box-icon bg-danger"
                        style={{
                          width: '60px',
                          fontSize: '13px',
                          padding: '8px',
                          height: '60px',
                        }}
                      >
                        NOW
                        <br />
                        {testTime}
                      </div>
                    </div>
                    <div
                      className="col-lg-10 col-sm-9 col-md-9 col-8 d-flex align-items-center"
                      style={{ paddingLeft: '10px' }}
                    >
                      <div style={{ borderLeft: '2px solid var(--light)' }}>
                        <div style={{ paddingLeft: '10px' }}>
                          {test.name}
                          <br />
                          <p style={{ fontSize: '13px' }}>
                            {test.total} Questions, {test.duration} mins | {header}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-right mob-start">
                  <div>
                    <Link
                      to={{
                        pathname: redirect,
                        search: query && `?${query}`,
                        state: {
                          id: test.id,
                          name: test.name,
                          price: test.price,
                          isQuestionSet: false,
                        },
                      }}
                    >
                      <button className="btn btn-dark" style={{ minWidth: '80px', width: '100%' }}>
                        {text}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

function Welcome({ url, moduleId }) {
  let currentDate = moment().format('MMM DD YYYY, dddd');
  let isMobile = window.innerWidth < 767;
  let { currentModule } = useModuleContext();
  let expireDate = moment.utc(currentModule.expireDate);
  let remainTime = expireDate.diff(moment.utc());
  let expired = !(remainTime > 0);
  // const [seriesPayment, setSeriesPayment] = useState(false);

  // useEffect(() => {
  //   axios({
  //     method: 'get',
  //     url: `${url}/test/hasComprehensive2024Access`,
  //     withCredentials: true,
  //   }).then(async (res) => {
  //     if (res.data.paid) setSeriesPayment(true);
  //   });
  // }, []);

  return (
    <div>
      {isMobile && expired && (
        <div className="dash__subs mb-2 p-1 mt-1">
          <h5 className="text-dark pt-2 px-2" style={{ fontWeight: '500' }}>
            Get your NAME CEE subscription &amp; <br />
            unlock more
          </h5>
          <div className="px-2 pt-2">
            <span>
              <i className="fa fa-graduation-cap" style={{ color: 'rgb(57, 155, 247)' }} />
              &nbsp;<p>Nepal's best educators</p>
            </span>
            <br />
            <span>
              <i className="fas fa-stream" style={{ color: 'rgb(24, 196, 153)' }} />
              &nbsp;&nbsp;<p>Interactive online classes</p>
            </span>
            <br />
            <span>
              <i className="far fa-file-alt" style={{ color: 'rgb(255, 91, 91)', marginLeft: '1px' }} />
              &nbsp;&nbsp;&nbsp;<p>Structured Courses</p>
            </span>
            <br />
            <span>
              <i className="fas fa-clipboard-check" style={{ color: 'rgb(241, 186, 65)', marginLeft: '1px' }} />
              &nbsp;&nbsp;&nbsp;<p>Live Mock tests & Quizzes</p>
            </span>
            <br />
          </div>

          <div className="dash__plan mx-2 my-2">
            <div className="row ">
              <div className="py-3 pl-3 pr-1 d-flex align-items-center">
                <img src="/images/star.png" alt="Star icon" width="40px" />
              </div>
              <div className="p-2 d-flex">
                <div style={{ color: '#fff' }}>
                  <span>
                    <p>
                      <strong>Introducing best Subscription Plan</strong>
                    </p>
                  </span>
                  <br />
                  <p style={{ color: '#ededed' }}>Take Your CEE Preparation to Next Level</p>
                  <br />
                  <span>
                    <a href="/mbbs/payment/" style={{ color: '#fff' }} className="small">
                      Learn More &nbsp;<i className="fa fa-angle-right"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <a href="/mbbs/payment/" className="btn btn-dark py-3 w-100 radius-15" style={{}}>
                Get subscription
              </a>
            </div>
          </div>
          <div className="text-center m-2">
            <a href="/mbbs/feedback" className="small fw-500">
              Need help? talk to us.
            </a>
          </div>
        </div>
      )}

      {/* {isMobile && (
        <div className="dash__subs p-3 py-3 border border-warning">
          <h5 className="text-dark p-0" style={{ whiteSpace: 'nowrap' }}>
            Test Series - 2024
          </h5>
          <p style={{ fontSize: '16px', marginBottom: '1em' }} className="lead d-block">
            CEE Mock Test And QAD Session
          </p>
          <p>
            <i className="far fa-calendar-alt" /> &nbsp;Start Date: SHRAWAN 18
          </p>
          <br />
          <p>
            <i className="far fa-clock" /> &nbsp;Exam Time: 11am - 2pm
          </p>
          <br />
          <p>
            <i className="far fa-clock" /> &nbsp;QAD Session: (Same Day)
          </p>
          <br />
          <p>
            <i className="fa fa-tag" /> &nbsp;Fee: Rs. 750
          </p>
          <div className="major__btn mt-3">
            <Link
              to={{
                pathname: seriesPayment ? `/${moduleId}/test` : `/${moduleId}/test-payment`,
                state: {
                  id: 'comprehensive2024',
                  name: 'Test Series - 2024',
                  price: 750,
                },
              }}
              className="btn btn-dark py-1 w-100 radius-15"
            >
              <button className="btn btn-dark" style={{ minWidth: '80px' }}>
                {seriesPayment ? 'View Tests' : 'Pay Now'}
              </button>
            </Link>
          </div>
        </div>
      )} */}
      {!isMobile && (
        <div className="dash__welcome">
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              {!isIOS() && <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />}
              <li data-target="#carouselExampleIndicators" data-slide-to={1} />
              <li data-target="#carouselExampleIndicators" data-slide-to={2} />
            </ol>
            <div className="carousel-inner" style={{ height: '210px' }}>
              {/* <div className="carousel-item active">
                <div className="pb-2 pl-4 float-right clearfix text-right">
                  <p className="mb-0 text-muted small">
                    <i className="far fa-calendar-alt" />
                    &nbsp;{currentDate}
                  </p>
                  <br />
                  <br />
                  <div className="dash__image">
                    <img
                      src="/images/dashboard/exam.png"
                      alt="study"
                      className="img-fluid"
                      style={{ marginRight: '1em' }}
                    />
                  </div>
                </div>
                <h3 className="text-dark p-0" style={{ whiteSpace: 'nowrap' }}>
                  Test Series - 2024
                </h3>
                <p className="lead d-block">CEE Mock Test And QAD Session</p>
                <p className="mt-3 mb-1 text-muted ">
                  <i className="far fa-calendar-alt" /> &nbsp;Start Date: SHRAWAN 18 &emsp;&emsp;
                  <i className="far fa-clock" /> &nbsp;Exam Time: 11am - 2pm &emsp;&emsp;
                  <i className="far fa-clock" /> &nbsp;QAD Session: (Same Day) &emsp;&emsp;
                  <i className="fa fa-tag" /> &nbsp;Fee: Rs. 750
                </p>
                <div className="major__btn mt-3">
                  <Link
                    to={{
                      pathname: seriesPayment ? `/${moduleId}/test` : `/${moduleId}/test-payment`,
                      state: {
                        id: 'comprehensive2024',
                        name: 'Test Series - 2024',
                        price: 750,
                      },
                    }}
                  >
                    <button className="btn btn-dark" style={{ minWidth: '80px' }}>
                      {seriesPayment ? 'View Tests' : 'Pay Now'}
                    </button>
                  </Link>
                </div>
              </div> */}

              <div className="carousel-item active">
                <div className="pb-2 pl-4 float-right clearfix text-right">
                  <p className="mb-0 text-muted small">
                    <i className="far fa-calendar-alt" />
                    &nbsp;{currentDate}
                  </p>
                  <div className="dash__image">
                    <img src="/images/dashboard/gift.jpg" alt="study" className="img-fluid" />
                  </div>
                </div>
                <h3 className="text-dark p-0" style={{ whiteSpace: 'nowrap' }}>
                  Admission Notice
                </h3>
                <p className="lead d-block">Admission Open from date to date </p>
                <p className="mt-3 mb-1 text-muted ">
                  Grab your opportunity to study at NAME Institute of Medical Science.
                </p>
                <div className="major__btn mt-3">
                  <Link to={`/${moduleId}/admission`}>
                    <button className="btn btn-dark" style={{ minWidth: '80px' }}>
                      See More
                    </button>
                  </Link>
                </div>
              </div>

              {!isIOS() && (
                <div className="carousel-item clearfix">
                  <div className="pb-2 pl-4 text-right float-right ">
                    <p className="date__carousel mb-0 text-muted small">
                      <i className="far fa-calendar-alt" />
                      &nbsp;{currentDate}
                    </p>
                    <div className="dash__image ">
                      <img src="/images/icons/question.jpg" alt="study" className="img-fluid " />
                    </div>
                  </div>
                  <h3 className="text-dark p-0" style={{ whiteSpace: 'nowrap' }}>
                    Hello, Student!
                  </h3>
                  <p className="lead d-block">Welcome to Avyaas. </p>
                  <p className="mt-3 mb-1 text-muted ">
                    This is a new digital learning platform for NAME Institute of Medical Science.
                  </p>

                  <div className="major__btn mt-2">
                    <Link to={`/${moduleId}/practice`}>
                      <button className="btn btn-dark" style={{ minWidth: '80px' }}>
                        Start
                      </button>
                    </Link>
                  </div>
                </div>
              )}

              <div className="carousel-item">
                <div className="pb-2 pl-4 float-right clearfix text-right">
                  <p className="mb-0 text-muted small">
                    <i className="far fa-calendar-alt" />
                    &nbsp;{currentDate}
                  </p>
                  <div className="dash__image">
                    <img src="/images/dashboard/gift.jpg" alt="study" className="img-fluid" />
                  </div>
                </div>
                <h3 className="text-dark p-0" style={{ whiteSpace: 'nowrap' }}>
                  Weekly Test!
                </h3>
                <p className="lead d-block">Are you ready to compete with your peers? </p>
                <p className="mt-3 mb-1 text-muted ">
                  NAME Institute of Medical Science conducts weekly saturday test.
                </p>
                <div className="major__btn mt-3">
                  <Link to={`/${moduleId}/test`}>
                    <button className="btn btn-dark" style={{ minWidth: '80px' }}>
                      Check
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Dashboard);

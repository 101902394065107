let availableModule = ["mbbs", "nursing", "pg"];

let mbbsCourse = {
  id:"name",
  title: "NAME",
  imageId: "images/module/mbbs.jpg",
  course: [
    {
      title: "MBBS",
      id: "MBBS",
      subject: 7,
      imageId: "images/module/mbbs.jpg",
    },
    {
      title: "Nursing Plus",
      id: "nursing",
      subject: 7,
      imageId: "images/module/nursing.jpg",
    },
  ],
};

let moduleDetail = [mbbsCourse];

let institutionName = "NAME";

let name = "name";

module.exports = { availableModule, moduleDetail, institutionName, name };

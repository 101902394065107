import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContextProvider";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import TestStat from "./TestStat";
import Swiper from "react-id-swiper";
import { StickyContainer, Sticky } from "react-sticky";
import { useModuleContext } from "context/ModuleProvider";
import { ProfileImg } from "../helper";
import "swiper/css/swiper.css";
import "./TestResult.css";
import { useHistory, useLocation } from "react-router-dom";

function TestResult() {
  let history = useHistory();
  const { state } = useLocation();
  let testId = null;
  if(!state){
    history.replace('/');
  }else{
    const { id } = state;
    testId = id;
  }
  const { currentUser } = useContext(AuthContext);
  const { moduleId, url } = useModuleContext();
  const [test, setTest] = useState([]);
  const [stat, setStat] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const [eswiper, updateEswiper] = useState(null);
  const exploreParams = {
    spaceBetween: 5,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      574: {
        slidesPerView: 3,
        spaceBetween: 7,
      },
    },
  };
  eswiper && eswiper.update();

  useEffect(() => {
    if(testId){
      axios({
        method: "get",
        url: `${url}/test/result?testId=${testId}`,
        withCredentials: true,
      }).then((res) => {
        setTest(res.data);
        axios({
          method: "get",
          url: `${url}/test/stat?testId=${testId}`,
          withCredentials: true,
        }).then((res) => {
          setStat(res.data);
        });
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        return () => window.removeEventListener("resize");
      });

    }
  }, [url]);
  const topper = test && test.testResults ? test.testResults.slice(0, 3) : [];
  const loser =
    test && test.testResults
      ? test.testResults.slice(3, test.testResults.length)
      : [];
  return (
    <StickyContainer>
      <section className="leaderboard__section">
        <div className="row">
          <div className="col-sm-8 col-12 p-0  order-sm-1 order-2">
            <div className="seperatory my-2 radius-10 p-1">
              <div className="leader__title p-1">
                <h5 style={{ padding: "10px" }} className="d-inline-block">
                  Leaderboard for {test.name}
                </h5>
              </div>
              <div className="row " id="test-swiper">
                <Swiper getSwiper={updateEswiper} {...exploreParams}>
                  {topper.map((t, index) => {
                    let {
                      id,
                      score,
                      cntCorrect,
                      cntWrong,
                      user: { imageId, name },
                    } = t;
                    return (
                      <div key={id} className="col-sm-4 col-12">
                        <div className="leaderboard__cards">
                          <div className="leader__cards">
                            <div className="card-body leader__body text-center">
                              <ProfileImg
                                style={{ display: "block", margin: "auto" }}
                                alt={"p"}
                                src={imageId}
                                className=" img-fluid ledaer__img bg-cover"
                              />
                              <h6
                                className="pt-2 pr-0"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {name}
                              </h6>
                              <br />
                              <p className="text-muted small">Total Score</p>

                              <div className="leader__bottom">
                                <h3>{score}</h3>
                                <p className="text-muted small">
                                  OUT OF
                                  {parseInt(cntWrong) + parseInt(cntCorrect)}
                                </p>
                                <div className="w-100 text-center">
                                  <h5 className="pr-0">RANK {index + 1} </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="seperatory my-2 radius-10 ">
              <div className="Other__leaders">
                <div>
                  <h6 className="text-uppercase">Other Leaders</h6>
                </div>
                <div className="table__container">
                  <div className="row py-1">
                    <div className="col-12">
                      {/* <ResultTable data={loser} /> */}
                      <table
                        id="data__inTable"
                        className="table table-hover responsive nowrap"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loser.map((l, index) => {
                            let {
                              id,
                              score,
                              cntCorrect,
                              user: { name, imageId },
                            } = l;
                            return (
                              <tr key={id}>
                                <td>
                                  <p className="rankings">{index + 4}</p>
                                </td>
                                <td>
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <ProfileImg
                                        className="img-fluid mr-2 small__circle bg-cover"
                                        alt={"p"}
                                        src={(imageId)}
                                      />
                                      <div>
                                        <p className="font-weight-bold mb-0">
                                          {name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{score}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {stat && (
            <div
              className={`px-0 mt-2 mb-1 radius-10 col-sm-4 col-12 order-sm-3 order-1 `}
            >
              {width > 575 ? (
                <Sticky topOffset={10}>
                  {({ style }) => (
                    <div style={style}>
                      <TestStat
                        stat={stat}
                        currentUser={currentUser}
                        moduleId={moduleId}
                        testId={testId}
                      />
                    </div>
                  )}
                </Sticky>
              ) : (
                <TestStat
                  stat={stat}
                  currentUser={currentUser}
                  moduleId={moduleId}
                  testId={testId}
                />
              )}
            </div>
          )}
        </div>
      </section>
    </StickyContainer>
  );
}

export default withRouter(TestResult);

import React, { useState, useEffect } from "react";
import axios from "axios";
import DiscussionCard from "../DiscussionCard";
import { useForm } from "react-hook-form";
import {
  FormGroup,
  FormGroupSelect,
  FormGroupArea,
  FormButton,
  ModalForm,
} from "../../ui/ModalForm";
import $ from "jquery";
import bootbox from "bootbox";
import Nodata from "../../ui/Nodata";

function DiscussionTap({ subjectId, moduleId, search }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const [discussion, setDiscussion] = useState(null);
  const [filter, setFilter] = useState("Active");
  const filterArr = ["New", "Active", "Popular"];

  const createDiscussion = (data) => {
    data.subject_id = data.subjectId;
    console.log(data);
    axios({
      method: "post",
      url: `${server}/${moduleId}/discussion`,
      withCredentials: true,
      data,
    }).then((res) => {
      console.log(res.data);
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else {
        $("#disModal").hide();
        $(".modal-backdrop").hide();
      }
    });
  };

  useEffect(() => {
    let url = `${server}/${moduleId}/discussion?search=${search}&filter=${filter.toLowerCase()}`;
    subjectId && (url = `${url}&subjectId=${subjectId}`);
    axios({
      method: "get",
      url,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Discussion");
      setDiscussion(res.data.discussion);
    });
    return () => {
      $("#pollModal").hide();
      $(".modal-backdrop").hide();
    };
  }, [subjectId, search]);

  const addVote = (discussion_id, check) => {
    if (!check) {
      axios({
        method: "post",
        url: `${server}/${moduleId}/discussion/t/vote`,
        withCredentials: true,
        data: { type: 1, discussion_id },
      }).then((res) => {
        if (!res.data.error) {
          let data = res.data;
          let newDiscussion = discussion.map((d) => {
            if (d.discussion_id === discussion_id) {
              d.vote = data.vote;
              d.type = data.type;
            }
            return d;
          });
          setDiscussion(newDiscussion);
        } else {
          alert(res.data.error);
        }
      });
    }
  };

  return (
    <div className="discu__wrapper">
      <div className="polls-lists p-3">
        <div className="row d-flex justify-content-between ">
          <div className="col-3">
            <div className="btn-group ">
              <button
                type="button"
                className="btn btn-light rounded dropdown-toggle py-2 px-3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {filter} <i className="fas fa-chevron-down" />
              </button>
              <div className="dropdown-menu">
                {filterArr.map((f) => {
                  if (f !== filter) {
                    return (
                      <div
                        className="dropdown-item"
                        key={f}
                        onClick={() => setFilter(f)}
                      >
                        {f}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="float-right">
              <button
                type="button"
                className="btn btn-light rounded py-2 px-3"
                data-toggle="modal"
                data-target="#disModal"
                data-whatever="@poll Question"
                style={{ fontSize : "13px"}}
              >
                <i className="fas fa-plus"></i>&nbsp; DISCUSSION
              </button>
            </div>
          </div>
        </div>
      </div>
      {discussion ? (
        discussion.length !== 0 ? (
          <DiscussionCard
            moduleId={moduleId}
            discussion={discussion}
            addVote={addVote}
          />
        ) : (
          <Nodata title="No discussion found." src="/images/noComment.jpg" />
        )
      ) : (
        "Loading..."
      )}
      <DiscussionPopup
        createDiscussion={createDiscussion}
        moduleId={moduleId}
      />
    </div>
  );
}

function DiscussionPopup({ createDiscussion, moduleId }) {
  const { register, handleSubmit, errors, setError, control } = useForm();
  const [subject, setSubject] = useState([]);
  const server = process.env.REACT_APP_SERVER_URL;
  const url = `${server}/${moduleId}`;
  console.log(errors);
  let optionType = {
    required: "Please specify your options.",
    maxLength: {
      value: 200,
      message: "Option should not be more than 200 characters.",
    },
  };
  let questionType = {
    required: "Question is required.",
    minLength: {
      value: 5,
      message: "Question should be at least of length 4.",
    },
  };

  const handleDis = (dis) => {
    console.log(dis);
    if (!isNaN(dis.subjectId)) {
      createDiscussion(dis);
    } else {
      setError("subjectId", "required", "Please select the subject");
    }
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/poll/subject`,
      withCredentials: true,
    }).then((res) => {
      setSubject(res.data.subject, "Subject");
    });
  }, [url]);

  return (
    <ModalForm id="disModal" title="Create a new Discussion">
      {subject && (
        <form
          style={{ minHeight: "90vh" }}
          className="bg-white"
          onSubmit={handleSubmit(handleDis)}
        >
          <div
            className="px-4 mb-2"
            style={{ height: "73vh", overflowY: "auto", boxShadow: "rgb(233 236 239) -1px -2px 12px 2px inset" }}
          >
            <FormGroup
              title="Title"
              id="title"
              register={register(optionType)}
              error={errors.title}
            />

            <FormGroupSelect
              list={subject}
              value="subject_id"
              display="subject_name"
              control={control}
              id="subjectId"
              error={errors.subjectId}
            />

            <FormGroupArea
              id="question"
              title="Question"
              control={control}
              register={register(questionType)}
              error={errors.question}
            />
          </div>
          <FormButton  />
        </form>
      )}
    </ModalForm>
  );
}

function NoDiscussion() {
  return (
    <h4 style={{ textAlign: "center", height: "400px", paddingTop: "50px" }}>
      No Discussion Found
    </h4>
  );
}

export default DiscussionTap;

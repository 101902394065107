import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Preloader from "../Preloader/Preloader";
import queryString from "query-string";
import { useModuleContext } from "context/ModuleProvider";
import PracticeResultCard from "./PracticeResultCard";

function PraticeArchive({ location }) {
  const { url } = useModuleContext();
  const [questions, setQuestions] = useState(null);
  let { chapterId, unitId, subjectId } = queryString.parse(location.search);

  useEffect(() => {
    subjectId = axios({
      method: "get",
      url: `${url}/practice/archive`,
      params: { chapterId, unitId, subjectId },
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setQuestions(res.data.questions || []);
    });
  }, [url]);
  return (
    <section className="content">
      {questions ? (
        <div className="container-fluid">
          <div className="row">
            {questions.length !== 0 ? (
              questions.map((question, index) => (
                <PracticeResultCard
                  key={question.id}
                  question={question}
                  count={questions.length}
                  index={index + 1}
                />
              ))
            ) : (
              <div>Not any question practice yet!</div>
            )}
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </section>
  );
}

export default withRouter(PraticeArchive);

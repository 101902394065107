import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContextProvider';
import { withRouter } from 'react-router-dom';

function ModuleHome({ history }) {
  const { currentUser, logout } = useContext(AuthContext);
  console.log(currentUser.email);
  console.log('ModuleHome is running');
  return (
    <div className="App">
      <div className="main">
        {currentUser ? (
          <div>
            <h2>Current User</h2>
            <div>{currentUser.name}</div>
            <div>{currentUser.email}</div>
            <div>
              <button onClick={logout}>Logout</button>
            </div>
            <div>
              <button onClick={() => history.push(`/pg/discussion`)}>Discussion</button>
            </div>
          </div>
        ) : (
          <div> No currentUser</div>
        )}
      </div>
    </div>
  );
}

export default withRouter(ModuleHome);

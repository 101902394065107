import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import bootbox from "bootbox";
import { Tab, TabItems, TabContents, TabItem, TabContent } from "../ui/Tab";
import { useModuleContext } from "context/ModuleProvider";
import "./Feedback.css";

function Feedback({ location, history }) {
  let { moduleId, url } = useModuleContext();
  let data = location.state;
  data || (data = { title: "Add the feedback" });
  let headers = [{ icon: "fa fa-bug", placeholder: "Please describe your problem here.", title: "Problem" }, { icon: "fa fa-question-circle", title: "Question", placeholder: "Please explain any inquiries that you might have." }, { icon: "fa fa-lightbulb-o", title: "Suggestion", placeholder: "Please help us by providing your suggestion here." }, { icon: "fa fa-heart", title: "Message", placeholder: "Please input your message." }];
  let [description, setDescription] = useState("");

  const submitFeedback = (e) => {
    e.preventDefault();
    let data = { description, rating: 2 }
    axios({
      method: "post",
      url: `${url}/feedback`,
      data,
      withCredentials: true,
    })
      .then(res => {
        if (res.data.error) {
          return bootbox.alert(res.data.message);
        }
        bootbox.alert("Feedback successfully submitted.")
        history.push(`/${moduleId}/dashboard`, {});
      })
  }
  return (
    <div style={{paddingTop:"-20px", marginTop:"-8px" }} className="feedback__section mx-sm-2 mx-2">
      <div className="row bg-white radius-10">
        <div className="col-12 col-md-6 d-md-block d-none">
          <div className="feed__image">
            <img src="/images/feedback.jpg" alt="feedback" />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <form onSubmit={submitFeedback} className="feedback radius-10">
            <h4>Feedback</h4>
            <p className="text-muted">What would you like to share with us?</p>

            <Tab >
              <TabItems tabId="feedback">
                {headers.map((h, index) => {
                  return <TabItem key={h.title} selected={index === 0} id={h.title} title={h.title}  />
                })}
              </TabItems>
              <TabContents tabId="feedback">
                {headers.map((h, index) => {
                  return <TabContent key={h.title} id={h.title} selected={index === 0} >
                    <div >
                      <textarea className="form-control w-100" rows={10} name="feedback_text" placeholder={h.placeholder} value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </div>
                  </TabContent>
                })}
              </TabContents>
            </Tab>
            <div className="major__btn mt-3">
              <button type="submit" className="btn btn-dark">Submit</button>
            </div>
            <img width="100%" src="/images/minion.webp" alt="minions" />
          </form>
        </div>
      </div>
    </div>

  );
}


export default withRouter(Feedback);

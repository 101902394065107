import React from 'react'
import "./Tab.css"

function Tab({children, className, ...props }) {
  return (
    <div {...props} className={`ui-tab bg-white radius-15 pl-sm-0 pr-sm-0 ${className && className}`}>
      {children}
    </div>
  )
}

function TabItems({ children, tabId, className }) {
  return (
    <ul className={`nav nav-tabs ${className && className} `} id={`${tabId}Tab`} role="tablist">
      {children}
    </ul>
  )
}

function TabContents({ children, tabId }) {
  return (
    <div className={`tab-content`}  id={`${tabId}Content`} >
      {children}
    </div>
  )
}

function TabItem({ id, title, selected, icon, className, linkClass }) {
  return (
    <li className={`nav-item  ${className && className}`}>
      <a className={`nav-link  ${selected ? "active": ""} ${linkClass} `} id={`${id}-tab`} data-toggle="tab" href={`#${id}`} role="tab" aria-controls={id} aria-selected={selected ? selected : false}>{ title && title } {icon && <i className={icon}></i>}</a>
    </li>
  )
}


function TabContent({ children, id, selected, className }) {
  return (
    <div className={`tab-pane fade ${selected ? "active show" : ""} ${className && className}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`}>
      {children}
    </div>
  )
}


export { Tab, TabItems, TabContents, TabItem, TabContent }

import React from "react";
import { withRouter } from "react-router-dom";
import CourseUi from "./CourseUi";
import "./Course.css";
// import "./HomeIndex.css";

function CourseMain({ history }) {
  let category = [
    {
      name: "Staff Nurse",
      id: "staffNurse",
      count: 2,
      subCourse: ["CTVT", "OTHERS"],
      link: "/nursing/dashboard",
    },
    {
      id: "pharmacy",
      name: "D. Pharmacy",
      count: 1,
      link: "/nursing/dashboard",
    },
    { id: "cmlt", name: "CMLT", count: 1, link: "/nursing/dashboard" },
    {
      id: "nursing",
      name: "Health Assistant",
      count: 1,
      link: "/nursing/dashboard",
    },
    {
      id: "radiography",
      name: "Radiography",
      count: 1,
      link: "/nursing/dashboard",
    },
    {
      id: "optimetry",
      name: "Optimetry",
      count: 1,
      link: "/nursing/dashboard",
    },
    { id: "dental", name: "Dental", count: 1, link: "/nursing/dashboard" },
    { id: "agvet", name: "AG / VET", count: 1, link: "/nursing/dashboard" },
    {
      id: "overseer",
      name: "Overseer",
      count: 1,
      link: "/nursing/dashboard",
    },
    { id: "others", name: "Others", count: 1, link: "/nursing/dashboard" },
  ];

  return <CourseUi category={category} history={history} />;
}

export default withRouter(CourseMain);

import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import RecentDiscussionTap from "./RecentDiscussionTap";
import { Tab, TabItems, TabContents, TabItem, TabContent } from "../../ui/Tab";
import ExploreTap from "./ExploreTap";
import RecentPollTap from "./RecentPollTap";
import "./Discussion.css";

function Discussion({ match }) {
  let moduleId = match.params.moduleId;
  const server = process.env.REACT_APP_SERVER_URL;
  const [subject, setSubject] = useState(null);
  const [achievers, setAchievers] = useState(null);
  const showByType = moduleId === "pg" || moduleId === "nmcle";

  const getAchivers = () => {
    axios({
      method: "get",
      url: `${server}/${moduleId}/poll/achievers`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setAchievers(res.data.achievers);
    });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${server}/${moduleId}/poll/subject${showByType ? "/type" : ""}`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data, "Subject");
      setSubject(res.data.subject);
    });
    getAchivers();
  }, []);
  return (
    <section
      id="dash__wrapper"
      className="content py-sm-2 py-0 pl-sm-2 pl-0 pr-sm-2 pr-0"
      style={{ background: "#eff0f5" }}
    >
      <div className="row">
        <div className="col-12 pl-0 pr-0">
          <div className="bg-white pt-3 pb-2 radius-15 no-radius-mobile mb-2">
            <Link to={`/${moduleId}/discussion/add`} className="w-100">
              <div className="dis__title d-sm-block">
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      {/* <div className="col-sm-1 ">
                        <div className="dis__left">
                          
                        </div>
                      </div> */}
                      <div className="col-sm-10 col-12 pl-0">
                        <div className="dis_prettytitle">
                          <p className="text-dark p-2 pl-1 pl-sm-2">
                          <img
                            style={{ width: "70px" }}
                            src="/images/talk.png"
                            alt=""
                            className="d-none d-sm-block pr-0 float-right"
                          />
                            Ask your Question or
                            <br /> Discuss a topic
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-4">
                    <div className="dis__align pt-sm-2 pt-2">
                      <button
                        type="button"
                        className="btn btn-dark banner-btn float-right mr-2"
                        data-toggle="modal"
                      >
                        <div className="d-flex justify-content-center">
                          {" "}
                          <i className="fas fa-plus" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <Tab>
            <TabItems tapId="dis">
              <TabItem
                id="explore"
                title="Explore"
                className="pl-3"
                selected={true}
              />
              <TabItem id="discussion" title="Recent Discussion" />
              <TabItem id="poll" title="Recent Poll" />
            </TabItems>
            <TabContents tapId="dis">
              <TabContent id="explore" selected={true}>
                <ExploreTap
                  moduleId={moduleId}
                  achievers={achievers}
                  subject={subject}
                />
              </TabContent>
              <TabContent id="discussion">
                <RecentDiscussionTap moduleId={moduleId} />
              </TabContent>
              <TabContent id="poll" className="p-2">
                <RecentPollTap moduleId={moduleId} />
              </TabContent>
            </TabContents>
          </Tab>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Discussion);

import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import { AuthContext } from "../../context/AuthContextProvider";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import Preloader from "../Preloader/Preloader";
import ReactMarkdown from "react-markdown";
import { getProfileLink, ProfileImg } from "../helper";
import RichTextEditor from "../Discussion/RichTextEditor";
import bootbox from "bootbox";
import { useModuleContext } from "context/ModuleProvider";

function DiscussionRef({ history, location }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { currentUser } = useContext(AuthContext);
  const { moduleId } = useModuleContext();
  let id = queryString.parse(location.search).id;
  const url = `${server}/${moduleId}/discussion`;
  const [discussion, setDiscussion] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const deleteDiscussion = () => {
    let id = discussion.discussion_id;
    axios({
      method: "post",
      url: `${url}/delete?id=${id}`,
      withCredentials: true,
    }).then((res) => {
      if (res.data.error) {
        setError("Error deleting the discussion");
        bootbox.alert(error);
      } else {
        console.log("hhjkjad");
        history.replace(`/${moduleId}/discussion/`);
      }
    });
  };
  const deleteAnswer = (id) => {
    axios({
      method: "delete",
      url: `${url}/answer?id=${id}`,
      withCredentials: true,
    }).then((res) => {
      if (res.data.error) {
        setError("Error adding the discussion");
        bootbox.alert(error);
      } else {
        let newanswer = [];
        discussion.discussionAnswers.forEach((d) => {
          if (d.discussionAnswer_id === id) {
          } else {
            newanswer.push(d);
          }
        });
        let newDiscussion = {
          ...discussion,
          discussionAnswers: [...newanswer],
        };
        console.log(newDiscussion);
        setDiscussion(newDiscussion);
      }
    });
  };

  const addVote = (discussion_id, check) => {
    if (!check) {
      axios({
        method: "post",
        url: `${server}/${moduleId}/discussion/t/vote`,
        withCredentials: true,
        data: { type: 1, discussion_id },
      }).then((res) => {
        if (!res.data.error) {
          let { vote, type } = res.data;
          let newDiscussion = { ...discussion, vote, type };
          setDiscussion(newDiscussion);
        } else {
          bootbox.alert(res.data.error);
        }
      });
    }
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/ref?id=${id}`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setDiscussion(res.data.discussion);
      setLoading(false);
    });
  }, [url, id]);

  const addAnswer = (answer) => {
    let data = {
      discussion_id: discussion.discussion_id.toString(),
      discussion_answer: answer,
    };
    if (answer) {
      axios({
        method: "post",
        url: `${url}/answer`,
        withCredentials: true,
        data,
      }).then((res) => {
        console.log(res.data);
        res.data.user = currentUser;
        discussion.discussionAnswers = [
          ...discussion.discussionAnswers,
          res.data,
        ];
        let newDiscussion = { ...discussion, answer: discussion.answer + 1 };
        // target.answer.value = "";
        setDiscussion(newDiscussion);
      });
    }
  };
  let {
    discussion_id,
    title,
    question,
    vote,
    subject_name,
    view,
    ent_date,
    type,
    answer,
    user,
    user_id,
    discussionAnswers,
  } = discussion || {};

  return (
    <section className="content" style = {{ marginTop : "-10px"}}>
      {!loading ? (
        <div className="row">
          <div className="col-12 px-sm-2 px-0">
            <div className="card radius-15">
              {discussion || error ? (
                <div className="px-3">
                  <div className="my-2">
                    <div className="mb-3">
                      <div className="float-left">
                        <ProfileImg
                          src={user.imageId}
                          alt="user"
                          className="img-circle  comment-photo mr-2"
                        />
                      </div>
                      <div className="d-inline-block">
                        <p className="mb-0 name__is">{user.name}</p>
                        {/* <span className="text-muted small">25 Reviews</span> */}
                        <p className="small text-muted mb-0 d-block pl-0">
                          {moment(ent_date).format("MMM DD YYYY")}
                          <span className="this__course ml-2">
                            {subject_name}
                          </span>
                        </p>
                      </div>
                      <div className="float-right">
                        <div className="dropdown first-list-single">
                          <button
                            className="btn btn-transparent dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton3"
                          >
                            {parseInt(user_id) === currentUser.id && (
                              <div
                                className="dropdown-item"
                                onClick={deleteDiscussion}
                              >
                                delete
                              </div>
                            )}
                            <Link className="dropdown-item" to="#">
                              report
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="replyPost text-left">
                      <h6 className="d-block">{title}</h6>
                      <p className="mb-1 text-muted d-block pl-0">
                        <ReactMarkdown
                          className="d-markdown"
                          source={question}
                          escapeHtml={false}
                        />
                      </p>
                    </div>

                    <div className="reaction row justify-content-between px-2">
                      <button
                        disabled={type}
                        onClick={() => addVote(discussion_id, type)}
                        type="button"
                        className={`btn rounded ${
                          type
                            ? "btn-primary btn-clap py-1 px-3"
                            : "btn-transparent"
                        } `}
                      >
                        <img
                          alt=""
                          src="https://img.icons8.com/ios/50/000000/applause.png"
                          width={20}
                        />
                        &nbsp;{vote} claps
                      </button>
                      <button
                        type="button"
                        className="btn rounded btn-transparent"
                      >
                        <i className="far fa-comment-alt" />
                        &nbsp;{answer} replies
                      </button>
                    </div>
                    {discussionAnswers.map((a) => {
                      return (
                        <DiscussionAnswer
                          key={a.discussionAnswer_id}
                          answer={a}
                          deleteAnswer={deleteAnswer}
                        />
                      );
                    })}
                    <div className="w-100 pt-2 pb-1 mt-2 comment-author border-top">
                      {/* <img alt="" src={getProfileLink(currentUser.imageId)} className="neww circle img-circle  float-left-sm float-none mr-1 d-inline-block" />
                      <p className="small d-inline-block">{currentUser.name}</p> */}
                      <RichTextEditor addAnswer={addAnswer} />
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading Discussion Detail...</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </section>
  );
}

function DiscussionAnswer({ answer, deleteAnswer }) {
  let {
    discussionAnswer_id,
    discussion_answer,
    ent_date,
    user_id,
    user: { name, imageId },
  } = answer;
  let { currentUser } = useContext(AuthContext);
  return (
    <div>
      <div className="w-100 pt-2 pb-1 mt-2 comment-author">
        <div>
          <div className="float-left">
            <img
              alt=""
              src={getProfileLink(imageId)}
              className="neww circle img-circle mr-1 d-inline-block"
            />
          </div>
          <div className="d-inline-block">
            <p className="mb-0 small d-block">{name}</p>
            <p className="small text-muted mb-1 mb-0 d-block p-0">
              {moment(ent_date).fromNow()}
            </p>
          </div>
          <div className="float-right">
            <div className="dropdown first-list-single">
              <button
                className="btn btn-transparent dropdown-toggle"
                type="button"
                id="dropdownMenuButton4"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </button>
              <div
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton4"
              >
                {parseInt(user_id) === currentUser.id && (
                  <div
                    className="dropdown-item"
                    onClick={() => deleteAnswer(discussionAnswer_id)}
                  >
                    delete
                  </div>
                )}
                <a className="dropdown-item" href="# ">
                  report
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactMarkdown
        className="d-markdown"
        source={discussion_answer}
        escapeHtml={false}
      />
    </div>
  );
}

export default withRouter(DiscussionRef);

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useModuleContext } from 'context/ModuleProvider';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { StickyContainer, Sticky } from 'react-sticky';
import './QBank.css';

function QBank() {
  const history = useHistory();
  const { url, moduleId } = useModuleContext();
  const [subjects, setSubjects] = useState(null);
  const [expandId, setExpandId] = useState(null);
  const { register, handleSubmit, errors, control } = useForm();

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/qbank/subject`,
      withCredentials: true,
    }).then((res) => {
      let { subjects } = res.data;
      if (subjects) {
        subjects = subjects.map((s) => {
          s.selected = true;
          s.length = s.units.length || 0;
          s.selectlength = s.length;
          s.units =
            s.units &&
            s.units.map((u) => {
              u.selected = true;
              return u;
            });
          return s;
        });
        setSubjects(res.data.subjects || []);
        // setExpandId(first && first.id);
      }
    });
  }, [url]);

  const handleTest = (data) => {
    let { count, difficulty } = data;
    let { subSelected: subjects, unitSelected: units } = getSelected();

    history.push({
      pathname: `/${moduleId}/qbank/start`,
      state: { subjects, units, limit: count, difficulty },
    });
  };

  const handleUnit = (value, unitId, subjectId) => {
    let newSubject = subjects.map((s) => {
      let selectlength = s.selectlength;
      if (s.id === subjectId) {
        let units = s.units.map((u) => {
          if (u.id === unitId && u.selected !== value) {
            value ? (selectlength = s.selectlength + 1) : (selectlength = s.selectlength - 1);
            u.selected = value;
          }
          return u;
        });
        return { ...s, units, selectlength };
      }
      return s;
    });
    setSubjects(newSubject);
  };

  const handleSubjectClick = (id) => {
    if (expandId === id) {
      setExpandId(false);
    } else {
      setExpandId(id);
    }
  };

  const getSelected = () => {
    let subSelected = [],
      unitSelected = [];
    subjects.forEach((s) => {
      if (s.length === s.selectlength) {
        subSelected.push(s.subject_id);
      } else {
        s.units.forEach((u) => {
          unitSelected.push(u.unit_id);
        });
      }
    });
    console.log(subSelected, unitSelected);
    return { subSelected, unitSelected };
  };

  return (
    <StickyContainer>
      <div className="row">
        <div className="col-lg-4 col-md-5 col-12 px-sm-2 px-0 pt-sm-1 pt-sm-2 pt-0"  style={{marginTop : "-20px"}}>
          <Sticky>
            {({ style }) => {
              return (
                <div className="bg-white left--options radius-10" style={{ ...style }}>
                  <div className="search-container-discu">
                    <form>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon-discu">
                            <i className="fas fa-search" />
                          </span>
                        </div>
                        <input
                          type="text"
                          id="poll-search"
                          className="form-control border-muted bg-white"
                          placeholder="Search chapters..."
                          name="search"
                          aria-label="name"
                          aria-describedby="basic-addon-discu"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="polls__discussion">
                    <div className="polls-lists">
                      <div className="main-discus" id="cours"></div>
                  <a
                    style={{
                      height: '50px',
                      verticalAlign: 'middle',
                      width: '100%',
                      border: 'none',
                      textAlign: 'left',
                    }}
                    aria-controls="collapse__courses"
                    aria-expanded="true"
                    className="text-primary d-block p-3 bg__transparent bg-white"
                    data-toggle="collapse"
                    href="#collapse__courses"
                  >
                    <strong><i class="fas fa-list-ul"></i>&nbsp;SELECT SUBJECTS</strong>&nbsp;
                    <span className="float-right">
                      <i className="fa fa-chevron-down"></i>
                    </span>
                  </a>
                  <div
                    className={`collapse discus-container ${window.innerWidth > 640 && 'show'}`}
                    id="collapse__courses"
                  >
                    {subjects ? (
                      subjects.map((s) => {
                        let expanded = s.id === expandId;
                        return (
                          <MemoizedSubject
                            key={s.id}
                            subject={s}
                            expanded={expanded}
                            handleSubjectClick={handleSubjectClick}
                            moduleId={moduleId}
                            handleUnit={handleUnit}
                          />
                        );
                      })
                    ) : (
                      <div className="mx-auto"> Loading Subjects... </div>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              );
            }}
          </Sticky>
        </div>
        <div className="col-lg-8 col-md-7 col-12 bg__transparent pt-sm-2 pt-2 liquid-margin">
          <div className="row">
            <div className="col-md-12 col-12 mb-2 px-0">
              <div className="bg-white p-3 chart_contain dash__shadow dash__border">
                <h5 style={{ fontWeight: 600 }}>Daily Test Series</h5>
                <p className="badge badge-light d-inline-block mr-1">Active</p>
                <div className="float-right clearfix ">
                  <a href="# " className="addNewTest d-flex justify-content-center"></a>
                </div>
                <div className="dash__question full__block">
                  <p className="test__name mb-0 d-block text-dark font-weight-bold">
                    Mini Mock Test - Preparation for MBBS
                  </p>
                  <p className="text-muted date d-block px-0">Started 21 May, 2020 | Thursday | 11:00 AM </p>
                  <p className="text-muted date d-block px-0">
                    Expiry Date <i className="fas fa-angle-double-right px-1" />
                    21 June, 2020
                  </p>
                  <hr />
                  <button
                    className="btn btn-dark ml-2 "
                    data-toggle="modal"
                    data-target="#QuestionOfTheDay"
                    style={{ minWidth: '80px' }}
                    onClick={handleSubmit(handleTest)}
                    >
                    Start
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2 px-0">
            <div className="bg-white p-3 chart_contain dash__shadow dash__border">
              <TestOptionFrom handleTest={handleTest} handleSubmit={handleSubmit} errors={errors} control={control} register={register} />
            </div>
          </div>
        </div>
      </div>
    </StickyContainer>
  );
}

function Subject({ subject, expanded, handleSubjectClick, moduleId, handleUnit }) {
  let SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let name = subject.subject_name.toLowerCase().replace(/\s/g, '-');
  return (
    <div className="sidebar-item" key={subject.id}>
      <div onClick={() => handleSubjectClick(subject.id)} className="sidebar-header">
        <div className="d-block quizes__labels ">
          <div className="row vote-modal justify-content-around">
            <div className="col-xl-2 col-sm-4 col-3 text-center">
              <img
                className="subject-icon rounded-5 vote-photo"
                src={`${SERVER_URL}/images/subjectLight/icons/${moduleId}/${name}.png`}
                alt=""
              />
            </div>
            <div className="col-xl-10 col-sm-8 col-9">
              <div className="px-2">
                <h6 className="polls_title mb-0 p-0 font-12 mb-2">
                  {/* <input className="none hidden" id={`s${subject.id}`} type="checkbox" defaultChecked />
                <label className="pl-1" htmlFor={`s${subject.id}`}>
                  {subject.subject_name}
                </label> */}
                  {subject.subject_name}
                </h6>
              </div>
              <div className="ninja__block px-2">
                <span className="badge badge-light d-inline-block mr-1">{subject.length} Subjects</span>
                <br />
                <span className="badge badge-basic d-inline-block"><i class="fas fa-check"></i>&nbsp;{subject.selectlength} Units</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`bg__transparent sidebar-body ${expanded && 'active'}`}>
        {subject.units &&
          subject.units.map((u) => {
            return <Unit key={u.id} unit={u} subjectId={subject.id} handleUnit={handleUnit} />;
          })}
      </div>
    </div>
  );
}

function Unit({ unit, handleUnit, subjectId }) {
  const [selected, setSelected] = useState(unit.selected);
  const onUnitChange = () => {
    setSelected(!selected);
    handleUnit(!selected, unit.id, subjectId);
  };

  return (
    <div className="px-2 pl-4 child-padding">
      <h6 className="polls_title mb-0 p-0 font-12">
        <input
          className="fancy-checkbox"
          onChange={onUnitChange}
          id={`u${unit.id}`}
          type="checkbox"
          checked={selected}
        />
        <label className="pl-2" htmlFor={`u${unit.id}`}>
          {unit.unit_name}
        </label>
      </h6>
    </div>
  );
}

const MemoizedSubject = React.memo(Subject, (prevProps, nextProps) => {
  return prevProps.subject.selectlength === nextProps.subject.selectlength && prevProps.expanded === nextProps.expanded;
});

function TestOptionFrom({ handleTest, errors, control, register, handleSubmit  }) {
  return (
    <form>
      <div className="test__result__class">
        <h6 style={{ fontWeight: 600 }}>Customize your practice</h6>
        <br />
        <p className="text-muted">You can customize your practice based on your preference</p>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="font-12">Difficulty level of questions</label>
            <Controller
              as={
                <select name="difficulty" className="form-control ">
                  <option value={-1} hidden>
                    No of questions
                  </option>
                  <option hidden value={-1}>
                    Select Difficulty
                  </option>
                  <option value={1}>1 Mark</option>
                  <option value={2}>2 Mark</option>
                </select>
              }
              name="difficulty"
              rules={{ required: 'Select the difficulty level.' }}
              control={control}
            />
            {errors.difficulty && <div className="text-danger ml-2 small">{errors.difficulty.message}</div>}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="font-12">Number of questions</label>
            <Controller
              as={
                <select name="count" className="form-control ">
                  <option value={-1} hidden>
                    No of questions
                  </option>
                  <option value={10}>10 Set</option>
                  <option value={20}>20 Set</option>
                  <option value={30}>30 Set</option>
                  <option value={40}>40 Set</option>
                </select>
              }
              name="count"
              rules={{ required: 'Select the no of question.' }}
              control={control}
            />
            {errors.count && <div className="text-danger ml-2 small">{errors.count.message}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <input ref={register} name="highYeild" type="checkbox" className="fancy-checkbox switch" />
            <label className="font-12" htmlFor="s1">
              High Yield Questions
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input ref={register} name="recentYear" type="checkbox" className="fancy-checkbox switch" />
            <label className="font-12" htmlFor="recentYear">
              Recent Year Questions
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input ref={register} name="imageBased" type="checkbox" className="fancy-checkbox switch" />
            <label className="font-12" htmlFor="imageBased">
              Image Based Questions
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input ref={register} name="aboveAverage" type="checkbox" className="fancy-checkbox switch" />
            <label className="font-12" htmlFor="aboveAverage">
              Above Average Questions
            </label>
          </div>
        </div>
      </div>

      <div className="addNewTest d-flex justify-content-center">
        <button
          type="submit"
          onClick={handleSubmit(handleTest)}
          className="btn btn-dark mb-0 mt-0"
          style={{ minWidth: '80px' }}
        >
          Start
        </button>
      </div>
    </form>
  );
}

export default QBank;

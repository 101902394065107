import React from "react";
import { Link } from "react-router-dom";
import { useModuleContext } from "context/ModuleProvider";
import { isIOS } from "../helper";

function RNotFound() {
  let { moduleId } = useModuleContext();

  return (
    <section
      className="content"
      style={{ backgroundColor: "white", marginTop: "-25px", height: "100vh" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="text-center" style={{ paddingTop: "40px" }}>
            <img
              src="/images/revision.gif"
              alt="NOT FOUND"
              style={{ width: "350px" }}
            />
          </div>
          <br />
          <div className="text-center text-muted">
            <div>
              <h2
                style={{ padding: "10px", fontFamily: "inherit" }}
                className="text-header"
              >
                Sorry, No Bookmark found.
              </h2>
            </div>
            You have not saved any questions to display! Saving questions are
            completely free!
            <br />
            <br />
            {!isIOS() && (
              <Link
                style={{ marginBottom: "60px" }}
                to={`/${moduleId}/practice`}
                className="btn btn-dark"
              >
                Try them!
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RNotFound;

import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.3 9.1C8.1 9.1 7.8 9 7.6 8.8L4.5 5.7C4.1 5.3 4.1 4.7 4.5 4.3C4.9 3.9 5.5 3.9 5.9 4.3L9 7.4C9.4 7.8 9.4 8.4 9 8.8C8.8 9 8.6 9.1 8.3 9.1Z"
        fill="#2B4832"
      />
      <path
        d="M5.4 20.1C5.1 20.1 4.9 20 4.7 19.8C4.3 19.4 4.3 18.8 4.7 18.4L8 15.1C8.4 14.7 9 14.7 9.4 15.1C9.8 15.5 9.8 16.1 9.4 16.5L6.1 19.8C5.9 20 5.6 20.1 5.4 20.1Z"
        fill="#2B4832"
      />
      <path
        d="M17.3 18.3C17 18.3 16.8 18.2 16.6 18L14.7 16.1C14.3 15.7 14.3 15.1 14.7 14.7C15.1 14.3 15.7 14.3 16.1 14.7L18 16.6C18.4 17 18.4 17.6 18 18C17.8 18.2 17.6 18.3 17.3 18.3Z"
        fill="#2B4832"
      />
      <path
        d="M15.8 9.70001C15.5 9.70001 15.3 9.60001 15.1 9.40001C14.7 9.00001 14.7 8.40001 15.1 8.00001L17.1 6.00001C17.5 5.60001 18.1 5.60001 18.5 6.00001C18.9 6.40001 18.9 7.00001 18.5 7.40001L16.5 9.40001C16.3 9.60001 16.1 9.70001 15.8 9.70001Z"
        fill="#2B4832"
      />
      <path
        d="M11.9 15.1C10.1 15.1 8.7 13.6 8.7 11.9C8.7 10.2 10.2 8.70001 11.9 8.70001C13.6 8.70001 15.1 10.2 15.1 11.9C15.1 13.6 13.7 15.1 11.9 15.1ZM11.9 10.6C11.2 10.6 10.7 11.2 10.7 11.8C10.7 12.4 11.3 13 11.9 13C12.5 13 13.1 12.4 13.1 11.8C13.1 11.2 12.5 10.6 11.9 10.6Z"
        fill="#2B4832"
      />
      <path
        d="M4 23.5C2.4 23.5 1 22.2 1 20.5C1 18.9 2.3 17.5 4 17.5C5.7 17.5 7 18.8 7 20.5C7 22.1 5.6 23.5 4 23.5ZM4 19.5C3.5 19.5 3 19.9 3 20.5C3 21.1 3.4 21.5 4 21.5C4.6 21.5 5 21.1 5 20.5C5 19.9 4.5 19.5 4 19.5Z"
        fill="#42C074"
      />
      <path
        d="M3.8 6.6C2.3 6.6 1 5.3 1 3.8C1 2.3 2.3 1 3.8 1C5.3 1 6.6 2.3 6.6 3.8C6.6 5.3 5.4 6.6 3.8 6.6ZM3.8 3C3.4 3 3 3.4 3 3.8C3 4.2 3.4 4.6 3.8 4.6C4.2 4.6 4.6 4.2 4.6 3.8C4.6 3.4 4.3 3 3.8 3Z"
        fill="#42C074"
      />
      <path
        d="M18.9 8.09999C18.2 8.09999 17.6 7.79999 17.1 7.39999C16.1 6.39999 16.1 4.79999 17.1 3.79999C18.1 2.79999 19.7 2.79999 20.7 3.79999C21.7 4.79999 21.7 6.39999 20.7 7.39999C20.2 7.79999 19.6 8.09999 18.9 8.09999ZM18.9 4.99999C18.8 4.99999 18.6 5.09999 18.5 5.19999C18.3 5.39999 18.3 5.69999 18.5 5.89999C18.7 6.09999 19 6.09999 19.2 5.89999C19.4 5.69999 19.4 5.39999 19.2 5.19999C19.2 5.09999 19 4.99999 18.9 4.99999Z"
        fill="#42C074"
      />
      <path
        d="M20 22.7C18.1 22.7 16.5 21.1 16.5 19.2C16.5 17.3 18.1 15.7 20 15.7C21.9 15.7 23.5 17.3 23.5 19.2C23.5 21.1 21.9 22.7 20 22.7ZM20 17.7C19.2 17.7 18.5 18.4 18.5 19.2C18.5 20 19.2 20.7 20 20.7C20.8 20.7 21.5 20 21.5 19.2C21.5 18.4 20.8 17.7 20 17.7Z"
        fill="#42C074"
      />
      <path
        d="M11.9 17.7C8.6 17.7 6 15.1 6 11.8C6 8.50002 8.6 5.90002 11.9 5.90002C15.2 5.90002 17.8 8.50002 17.8 11.8C17.8 15.1 15.1 17.7 11.9 17.7ZM11.9 7.90002C9.7 7.90002 8 9.70002 8 11.8C8 13.9 9.7 15.7 11.9 15.7C14.1 15.7 15.8 14 15.8 11.8C15.8 9.70002 14 7.90002 11.9 7.90002Z"
        fill="#42C074"
      />
      <path
        d="M20 20.3C20.6075 20.3 21.1 19.8075 21.1 19.2C21.1 18.5925 20.6075 18.1 20 18.1C19.3925 18.1 18.9 18.5925 18.9 19.2C18.9 19.8075 19.3925 20.3 20 20.3Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.5"
          height="22.5"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
  width={width}
  style={style}
  height={height}
  className={className}
  viewBox={viewBox}
  fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.8 7.6C8.4 7.6 8 7.3 7.8 6.9L6.8 3.5C6.7 3.2 6.8 3 6.9 2.7C7 2.4 7.3 2.3 7.5 2.2L11.8 1C12 1 12.2 1 12.3 1L16.6 2.1C16.9 2.2 17.1 2.3 17.2 2.6C17.3 2.8 17.4 3.1 17.3 3.4L16.3 6.9C16.1 7.4 15.6 7.7 15.1 7.6C14.6 7.4 14.3 6.9 14.4 6.4L15.1 3.9L12.1 3L9 3.9L9.7 6.3C9.9 6.8 9.6 7.4 9 7.5C9 7.6 8.9 7.6 8.8 7.6Z"
        fill="#2B4832"
      />
      <path
        d="M17.3 19.9H13.4C12.8 19.9 12.4 19.5 12.4 18.9C12.4 18.3 12.8 17.9 13.4 17.9H17.3C17.9 17.9 18.3 18.3 18.3 18.9C18.3 19.5 17.9 19.9 17.3 19.9Z"
        fill="#2B4832"
      />
      <path
        d="M15.4 21.8C14.8 21.8 14.4 21.4 14.4 20.8V17C14.4 16.4 14.8 16 15.4 16C16 16 16.4 16.4 16.4 17V20.9C16.4 21.4 15.9 21.8 15.4 21.8Z"
        fill="#2B4832"
      />
      <path
        d="M12.1 14.9C9.2 14.9 6.8 12.5 6.8 9.59999C6.8 6.69999 9.2 4.29999 12.1 4.29999C15 4.29999 17.4 6.69999 17.4 9.59999C17.4 12.5 15 14.9 12.1 14.9ZM12.1 6.29999C10.3 6.29999 8.8 7.79999 8.8 9.59999C8.8 11.4 10.3 12.9 12.1 12.9C13.9 12.9 15.4 11.4 15.4 9.59999C15.4 7.69999 13.9 6.29999 12.1 6.29999Z"
        fill="#42C074"
      />
      <path
        d="M20.2 23.5C19.6 23.5 19.2 23 19.2 22.5V19.2C19.2 16.9 18.3 15.5 16.5 15.1C16 15 15.6 14.4 15.7 13.9C15.8 13.4 16.4 13 16.9 13.1C19.6 13.7 21.2 15.9 21.2 19.2V22.5C21.2 23.1 20.7 23.5 20.2 23.5Z"
        fill="#42C074"
      />
      <path
        d="M4 23.5C3.4 23.5 3 23 3 22.5V19.2C3 16 4.6 13.8 7.3 13.1C7.8 13 8.4 13.3 8.5 13.9C8.6 14.4 8.3 15 7.7 15.1C5.9 15.5 5 16.9 5 19.2V22.5C5 23.1 4.6 23.5 4 23.5Z"
        fill="#42C074"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="18.2"
          height="22.5"
          fill="white"
          transform="translate(3 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React from 'react';
import UserResult from '../Test/UserResult';
import { withRouter } from 'react-router-dom';

function SeriesUserResult(){

    return (
        <>
        <UserResult/>
        </>
    );
}

export default withRouter(SeriesUserResult)
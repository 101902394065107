import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'chart.js';
import $ from 'jquery';
import { ProfileImg } from '../helper';

function SeriesTestStat({ stat, currentUser, moduleId, testId, style }) {
  let {
    userStat,
    testStat,
    data: { correct, labels },
  } = stat;
  let isMobile = window.innerWidth < 767;
  useEffect(() => {
    $(document).ready(function () {
      var ticksStyle = {
        fontColor: '#495057',
        fontStyle: 'bold',
      };

      var mode = 'index';
      var intersect = true;
      var $salesChart = $('#sales-chart');
      new Chart($salesChart, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              backgroundColor: '#007bff',
              borderColor: '#007bff',
              data: correct,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            mode: mode,
            intersect: intersect,
          },
          hover: {
            mode: mode,
            intersect: intersect,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  lineWidth: '4px',
                  color: 'rgba(0, 0, 0, .2)',
                  zeroLineColor: 'transparent',
                },
                ticks: $.extend(
                  {
                    beginAtZero: true,
                  },
                  ticksStyle,
                ),
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: ticksStyle,
              },
            ],
          },
        },
      });
    });
  }, [stat]);
  return (
    <>
      {stat && (
        <div id="test-stat" style={style} className="">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 px-0 px-sm-2">
              <div className="bg-white radius-10 py-0">
                <div className="text-center pt-sm-5 pt-2">
                  <ProfileImg src={getProfileLink(currentUser.imageId)} alt="user" className="img-fluid profile__pic" />
                </div>
                <div className="test_profile__block">
                  <div>
                    <h6 className="mb-0 p-0 text-center">{currentUser.name}</h6>
                  </div>
                  <Link to={{
                    pathname: `/${moduleId}/question-sets/test/userResult`,
                    state: {id: testId}
                  }} className="btn btn-dark" id="myPbtn">
                    Check answer <i className="fa fa-check" />
                  </Link>
                </div>
                {userStat && (
                  <div className="border-top border-bottom d-flex py-2 my-2 mx-2" style={{ borderRadius: '25px' }}>
                    <div className="w-50 text-center d-inline-block  border-right">
                      <h5 className="mb-0">{userStat.total_attempts}</h5>
                      <small className="text-muted">Total Attempts</small>
                    </div>
                    <div className="w-50 text-center d-inline-block">
                      <h5 className="mb-0">{userStat.highest_score}</h5>
                      <small className="text-muted">Highest Score</small>
                    </div>
                  </div>
                )}
                {testStat && (
                  <div className="d-block" style={{ padding: '5px' }}>
                    <div
                      className="d-flex"
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '5px',
                      }}
                    >
                      <div>Appeared Students: </div>
                      <div className="p-0">{testStat.total}</div>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '5px',
                      }}
                    >
                      <div>Highest Score: </div>
                      <div className="p-0">{testStat.highest}</div>
                    </div>
                  </div>
                )}
                {!isMobile && (
                  <div className="card-body">
                    <div className="small mb-2">Test History</div>
                    <div className="position-relative mb-4">
                      <canvas
                        id="sales-chart"
                        height={200}
                        width={882}
                        // className="chartjs-render-monitor"
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '200px',
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      <span className="mr-2 small">
                        <i className="fa fa-square text-primary pr-1" />
                        Percentage Secured
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {false && (
              <>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  {testStat && (
                    <div className="card score__box bg-dark">
                      <h2 className="p-0">{testStat.total}</h2>
                      <p style={{ fontSize: '12px' }}>Appeared Students</p>
                    </div>
                  )}
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <div className="card score__box bg-primary">
                    <h2 className="p-0">{testStat.highest}</h2>
                    <p style={{ fontSize: '12px' }}>Highest scores</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
const getProfileLink = (link) => {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  if (!link) {
    return '/images/profile.png';
  } else if (link.startsWith('https://')) {
    return link;
  } else {
    return `${serverUrl}/${link}`;
  }
};

export default SeriesTestStat;

import React, { Component } from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import "./Editor.css";

class RichTextEditor extends Component {
  state = {
    editorState: ""
  };

  handleChange = editorState => {
    console.log(editorState);
    this.setState({
      editorState
    });
  };

  render() {
    const handleAnswer = (e) => {
      e.preventDefault();
      this.props.addAnswer(this.state.editorState);
      this.setState({
        editorState: ""
      });
    };
    const handleKey = (a) => {
      console.log(a, "pkasd")
    }
    const { editorState } = this.state;
    return (
      <div>
        <ReactQuill value={editorState}
          onChange={this.handleChange}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.formats}
          bounds={'.app'}
          onKeyDown={handleKey}
          id="discussion-editor"
        />

        <div className="btn-action col-md-offset-5">
          <div className="form-group mt-2" align="center">
            <input
              type="button"
              name="btnAction"
              onClick={handleAnswer}
              defaultValue="Submit Answer"
              className="btn btn-success"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RichTextEditor


RichTextEditor.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, ],
    [{ size: ['normal', 'large'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}

RichTextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
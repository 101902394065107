import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import $ from "jquery";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function VideoResource({ resources }) {
  const [active, setActive] = useState(null);

  return (
    <div className="wrapper pt-3">
      <div className="resourceBox">
        <div className="row">
          {/* active ? (
            <ActiveResource resource={active} setActive={setActive} />
          )  */}
          <ResourceModel resource={active} />
          {resources.length !== 0
            ? resources.map((r) => {
                return (
                  <SingleResource key={r.id} r={r} setActive={setActive} />
                );
              })
            : "No resources added yet."}
        </div>
      </div>
    </div>
  );
}

export default VideoResource;

function ActiveResource({ resource, setActive }) {
  let img = `${resource.link.split(0, -4)}.jpg`;
  return (
    <div className="active-resource">
      <div onClick={() => setActive()} className="close-btnn">
        close
      </div>
      <img
        src={`${serverUrl}/images/videoResources/${img}`}
        alt="alert"
        scrolling="auto"
        height="100%"
        width="100%"
      ></img>
    </div>
  );
}

function ResourceModel({ resource }) {
  let img = resource && resource.link && `${resource.link.slice(0, -4)}.jpg`;

  return (
      <div
        className="modal fade"
        id="resourceModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="resourceModel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: "100%", height: "auto", maxWidth: "800px" }}
        >
          <div className="modal-content" style={{ height: "100%" }}>
            <div className="modal-header" style={{ minHeight: "65px" }}>
              <h5 className="modal-title" id="AskTitle">
                {resource && resource.title}
              </h5>
              <button
                type="button"
                className="close btn btn-link btn-sm px-2 py-1 m-1"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ background: "#f8f9fa", minHeight: "100%" }}
            >
              <img
                src={`${serverUrl}/images/videoResources/${img}`}
                alt="alert"
                scrolling="auto"
                width="100%"
              ></img>
            </div>
          </div>
        </div>
      </div>
    
  );
}

function SingleResource({ r, setActive }) {
  let { link, id, title, size, ent_date } = r;
  let date = moment(ent_date);

  const download = () => {
    axios({
      url: `${serverUrl}/download/resources/video/${link}`, // download url
      method: "get",
      withCredentials: true,
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, link);
    });
  };

  const handleResource = (r) => {
    setActive(r);
    $("#resourceModel").modal("show");
  };

  return (
    <div key={id} className="col-md-6 col-sm-12 col-6 ">
      <div className="resourceBox__item cursor-pointer">
        <div
          onClick={() => handleResource(r)}
          type="button"
          title="download"
        >
          <div className="resource-date">
            <span className="resource-date__title">{date.format("DD")}</span>
            <span className="resource-date__txt">{date.format("MMMM")}</span>
          </div>
          <div className="resource__img">
            <i className="far fa-file-word" />
          </div>
          <div className="file__size">{size} kb</div>
          <div className="resource__title">{title}</div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useModuleContext } from 'context/ModuleProvider';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../Announcement/Announcement.css';

function RecentAnnouncement() {
  let { moduleId, serverUrl, url } = useModuleContext();
  let [announcement, setAnnouncement] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'get',
      url: `${url}/announcement?limit=4`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      setAnnouncement(res.data.announcement);
    });
  }, []);

  return (
    <div className="col-md-12 col-12 mb-2 pl-md-2 px-0" style={{ marginTop: '5px' }}>
      <div className="bg-white p-3 notice_contain dash__shadow dash__border ">
        <h6 style={{ fontWeight: 600 }}>MEC Notice</h6>
        <img src="/images/icons/message.png" alt="Message" />

        <div className="no-img pt-2">
          <ul className="products-list product-list-in-card pl-2 pr-2">
            {!loading ? (
              announcement && announcement.length !== 0 ? (
                announcement.map((a) => {
                  let { id, title, about, logo } = a;
                  return (
                    <li key={id} className="item">
                      <div className="product-img">
                        <img
                          className="img-circle"
                          // src={`${serverUrl}/images/announcements/logo/${logo ? logo : 'logo.png'}`}
                          src={`/images/icons/announcement.svg`}
                          alt="Logo"
                          style={{ height: '30px', width: '30px', objectFit: 'contain' }}
                        />
                      </div>
                      <div className="product-info">
                        <Link to={`/${moduleId}/announcement/detail?id=${id}`} className="product-title">
                          {title}
                          <br />
                          {/* <p className="text-muted notice-desc">{about}</p> */}
                        </Link>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="center text-muted">Not any announcement. Please check later.</div>
              )
            ) : (
              <div>Loading...</div>
            )}

            {/* Name Notice */}

            {/* <h6 style={{ fontWeight: 600 }}>Name Notice</h6>
            <div className="no-img pt-2">
              <ul className="products-list product-list-in-card pl-2 pr-2">
                {!loading ? (
                  announcement && announcement.length !== 0 ? (
                    announcement.map((a) => {
                      let { id, title, about, logo } = a;
                      return (
                        <li key={id} className="item">
                          <div className="product-img">
                            <img
                              className="img-circle"
                              src={`${serverUrl}/images/announcements/logo/${logo ? logo : 'logo.png'}`}
                              alt="Logo"
                            />
                          </div>
                          <div className="product-info">
                            <Link to={`/${moduleId}/announcement/detail?id=${id}`} className="product-title">
                              {title}
                              <p className="text-muted notice-desc">{about}</p>
                            </Link>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div className="center text-muted">Not any announcement. Please check later.</div>
                  )
                ) : (
                  <div>Loading...</div>
                )}
              </ul>
            </div> */}
            <div className="btn btn-block p-0 pt-4 pb-3">
              <Link to={`/${moduleId}/announcement`}>
                <button className="btn btn-dark mb-0 mt-0" style={{ minWidth: '80px', width: '100%' }}>
                  See all
                </button>
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RecentAnnouncement;

import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "24",
  className = "",
  height = "24",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M13.4 23.4C9.29999 23.4 5.89999 20 5.89999 15.9V12.5C5.89999 11.9 6.29999 11.5 6.89999 11.5C7.49999 11.5 7.89999 11.9 7.89999 12.5V15.9C7.89999 18.9 10.4 21.4 13.4 21.4C16.4 21.4 18.9 18.9 18.9 15.9V14.7C18.9 14.1 19.3 13.7 19.9 13.7C20.5 13.7 20.9 14.1 20.9 14.7V15.9C20.9 20 17.6 23.4 13.4 23.4Z"
        fill="#42C074"
      />
      <path
        d="M4.5 12.8C4.3 12.8 4.2 12.8 4 12.7C2.1 11.6 1 9.7 1 7.5V2C1 1.4 1.4 1 2 1H4.7C5.3 1 5.7 1.4 5.7 2C5.7 2.6 5.3 3 4.7 3H3V7.5C3 8.9 3.8 10.2 5 10.9C5.5 11.2 5.6 11.8 5.4 12.3C5.2 12.6 4.9 12.8 4.5 12.8Z"
        fill="#42C074"
      />
      <path
        d="M7 13.4C6.4 13.4 6 13 6 12.4C6 11.8 6.4 11.4 7 11.4C9.2 11.4 10.9 9.6 10.9 7.5V3H9.3C8.7 3 8.3 2.6 8.3 2C8.3 1.4 8.7 1 9.3 1H12C12.6 1 13 1.4 13 2V7.5C13 10.8 10.3 13.4 7 13.4Z"
        fill="#42C074"
      />
      <path
        d="M19.9 15.7C18 15.7 16.4 14.1 16.4 12.2C16.4 10.3 18 8.70001 19.9 8.70001C21.8 8.70001 23.4 10.3 23.4 12.2C23.4 14.1 21.8 15.7 19.9 15.7ZM19.9 10.7C19.1 10.7 18.4 11.4 18.4 12.2C18.4 13 19.1 13.7 19.9 13.7C20.7 13.7 21.4 13 21.4 12.2C21.4 11.4 20.7 10.7 19.9 10.7Z"
        fill="#2B4832"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22.4"
          height="22.4"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

import React from "react";
// import "./Landing.css";

function Landing() {
  return (
    <div className="w-100" id="homeMain">
      <div className="row justify-content-center">
        <div id="landing">
          <div className="header">
            <a href="https://name.avyaas.com" className="logo">
              NameAvyaas
            </a>
            <a href="coursesselect.php" className="button">
              Login&nbsp;
              <i className="fas fa-chevron-right" />
            </a>
          </div>
          <div className="slider slider-1">
            <input className="slide" type="radio" name="slider-1" id="s-1-1" />
            <label htmlFor="s-1-1" />
            <div>
              <img alt="Avyaas" src="/images/landing/slide2.jpg" />
              <div className="content">
                <h3>Join Name Avyaas today.</h3>
                <p>
                  Learn Digitally with our e-portal and get access to unlimited
                  features.
                </p>
                <a
                  href="coursesselect.php"
                  className="button button bg-dark text-white"
                >
                  Get Started
                </a>
              </div>
            </div>
            <input className="slide" type="radio" name="slider-1" id="s-1-2" />
            <label htmlFor="s-1-2" />
            <div>
              <img alt="asd" src="/images/landing/asd.jpg" />
              <div className="content">
                <h3>10% off on all courses</h3>
                <p>We are providing free access to first 1000 students </p>
                <a
                  href="coursesselect.php"
                  className="button button bg-dark text-white"
                >
                  Enroll Now
                </a>
              </div>
            </div>
            <input
              className="slide"
              type="radio"
              name="slider-1"
              id="s-1-3"
              defaultChecked
            />
            <label htmlFor="s-1-3" />
            <div>
              <img alt="slide" src="css/new/slide1.jpg" />
              <div className="content">
                <h3>Rich features for improving your efficiency</h3>
                <p>
                  Practise with authentic questions to help you improve your
                  answering skills.
                </p>
                <a
                  href="coursesselect.php"
                  className="button button bg-dark text-white"
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
          <div className="menu">
            <a href="/#">Getting Started</a>
            <a href="/#">Name Institute</a>
            <a href="/#">e-Manual</a>
            <a href="/#">Download App</a>
          </div>
          <div className="feature">
            {/* <img class="picture" src="https://developer.playphone.com/App/Landing/img/feature-1.png" /> */}
            <h4 className="caption">Learn Smart</h4>
            <p className="summary">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </p>
          </div>
          <div className="feature">
            <h4 className="caption">Practise Assessments</h4>
            <p className="summary">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </p>
          </div>
          <div className="feature">
            <h4 className="caption">Performance Improvement</h4>
            <p className="summary">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </p>
          </div>
          <div className="col-12 pl-0 pr-0">
            <div className="taitale p-3 text-center">
              <h4 className="font-weight-bold text-uppercase">
                Online Exam Registration Process
              </h4>
            </div>
            <ul className="process">
              <li className="process__item">
                <span className="process__number">Step 1</span>
                <span className="process__title">Download the app</span>
                <span className="process__subtitle">
                  Portal is available in app/play store &amp; web portal
                </span>
              </li>
              <li className="process__item">
                <span className="process__number">Step 2</span>
                <span className="process__title">
                  Register &amp; select courses
                </span>
                <span className="process__subtitle">
                  Choose from several offered couses and join instantly
                </span>
              </li>
              <li className="process__item">
                <span className="process__number">Step 3</span>
                <span className="process__title">Make Payment</span>
                <span className="process__subtitle">
                  Instant access by paying via digital wallet
                </span>
              </li>
              <li className="process__item">
                <span className="process__number">Step 4</span>
                <span className="process__title">
                  Access to unlimited features
                </span>
                <span className="process__subtitle">
                  Exams, video lectures, discussion, polls etc.
                </span>
              </li>
            </ul>
          </div>
          <div className="streams-table-wrapper">
            <ul className="streams-table">
              <li className="streams-table__item">
                <h3 className="streams-table__title">Medical</h3>
                <ul className="streams-table__products">
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="streams-table__item">
                <h3 className="streams-table__title">Medical</h3>
                <ul className="streams-table__products">
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="streams-table__item">
                <h3 className="streams-table__title">Medical</h3>
                <ul className="streams-table__products">
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                  <li className="streams-table__product">
                    <a href>
                      1Yr. Medical Course
                      <p className="streams-table__label">
                        Class XI Studying Students moving to Class XII
                      </p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Landing;

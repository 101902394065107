import React from 'react'

function Nodata({title, src}) {
  return (
    <div className="noData text-center" >
      <p className="hero-p">{title}</p>
      <div className="post-error-content">
       {src && <img  src={src} alt="Post error" width="60%" height={300} />}
      </div>
    </div>
  )
}

export default Nodata
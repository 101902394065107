import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import axios from 'axios';
import { useModuleContext } from 'context/ModuleProvider';
import ProfileImage from './ProfileImage';
import './Profile.css';

function ProfileStat() {
  let [stat, setStat] = useState();
  let { url } = useModuleContext();
  let { currentUser } = useContext(AuthContext);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/dashboard/answer`,
      withCredentials: true,
    }).then(async (res) => {
      console.log(res.data);
      setStat(res.data.results);
    });
  }, [url]);

  let { email, phone } = currentUser;

  return (
    <div className="layout">
      <div className="profile__page mt-2 mt-sm-0">
        <ProfileImage url={url} />
        <div className="other__details">
          <p className="text-muted d-block px-0">{email}</p>
          <p className="text-muted d-block px-0">{phone}</p>
        </div>
        {stat && (
          <div className="profile__page__stats">
            <div className="profile__page__stat">
              <div className="profile__page__value">
                {stat.total}
                <div className="profile__page__key text-muted">Attempted</div>
              </div>
            </div>
            <div className="profile__page__stat">
              <div className="profile__page__value">
                {stat.correct}
                <div className="profile__page__key text-muted">Correct</div>
              </div>
            </div>
            <div className="profile__page__stat">
              <div className="profile__page__value">
                {stat.total - stat.correct}
                <div className="profile__page__key text-muted">Wrong</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileStat;

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TestCounter({ end, text, callback, snackbarCallback }) {
  let current = moment.utc();
  let remain = moment.utc(end).diff(current);
  let newDuration = moment.utc(remain).format('HH:mm:ss');
  let [duration, setDuration] = useState(newDuration);

  let endRef = useRef(newDuration);
  useEffect(() => {
    let id = setInterval(() => {
      let remain = moment.utc(end).diff(moment.utc());
      let newDuration = moment.utc(remain).format('HH:mm:ss');
      remain = parseInt(remain / 1000);

      if (remain < 0) {
        clearInterval(id);
        callback && callback();
      } else {
        if (remain === 5 * 60) {
          toast.error('5min remaining to complete the test!', {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        endRef.current = newDuration;
        setDuration(newDuration);
      }
    }, 1000);
    return () => clearInterval(id);
  }, []);
  return (
    <div>
      {text && text}
      {duration}
    </div>
  );
}

export default React.memo(TestCounter);

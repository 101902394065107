import React from 'react'
import { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import queryString from "query-string";

function ARDetail({location}) {
  let [dimension, setDimension] = useState({ width: window.innerWidth, height: window.innerHeight - 120 });
  let query = queryString.parse(location.search);


  useEffect(() => {
    window.addEventListener("resize", updateDimension)
    return () => {
      window.removeEventListener("resize", updateDimension);
    }
  }, [])

  const updateDimension = () => {
    setDimension({ width: window.innerWidth, height: window.innerHeight - 120 })
  }
  let { height, width } = dimension;
  return (
    <div>
      <div className="col-2" style={{ height: '45px', marginTop: '-25px', zIndex: 1000 }}>
        <p>&nbsp;&nbsp;&nbsp;</p>
      </div>
      <div className="sketchfab-embed-wrapper">
        <iframe width={width} title="model" height={height} style={{ marginTop: '-43px', zIndex: 1 }} align="middle" src={`https://sketchfab.com/models/${query.model}/embed?autostart=1`} frameBorder={0} allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true" allowtransparency="true" />
      </div>
      
      <div className="col-12" style={{ marginTop: '-40px', backgroundColor: 'white', zIndex: 1000 }}>
        {/* <img alt="Background" src="images/ar_thumbnail/background.png" /> */}
        <p style={{ fontSize: '13px', fontWeight: 'normal', color: '#4A4A4A', margin: '10px',  backgroundColor: 'white' }}>
          {query.name} by {query.author} on Sketchfab.
          </p>
      </div>
    </div>
  )
}

export default withRouter(ARDetail)

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContextProvider';
import { useModuleContext } from 'context/ModuleProvider';
import Preloader from './Preloader/Preloader';

function AccessModule({ history }) {
  let { loadUser, currentUser } = useAuthContext();
  let { url, moduleId, module } = useModuleContext();
  let [error, setError] = useState();
  let [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);

  async function getAccess(code) {
    setValidating(true);
    const res = await axios({
      method: 'get',
      url: `${url}/access/?code=${code}`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    setValidating(false);
    if (!res.data.error) {
      await loadUser();
      history.replace(`/${moduleId}/dashboard`);
    } else {
      setError(res.data.message);
    }
  }

  const accessWithoutCode = async () => {
    setLoading(true);
    const res = await axios({
      method: 'get',
      url: `${url}/access`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    setLoading(false);
    if (!res.data.error) {
      await loadUser();
      history.replace(`/${moduleId}/dashboard`);
    } else {
      setError(res.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let code = e.target.accessCode.value;
    getAccess(code);
  };

  useEffect(() => {
    if (module) {
      history.replace(`/${moduleId}/dashboard`);
    } else {
      console.log(`Trying to access ${moduleId}`);
      accessWithoutCode();
    }
  }, []);

  return (
    <div className="w-100" id="access-center">
      <div className="section__bg py-md-5 py-2">
        <div className="container-fluid">
          {!loading ? (
            <div className="row d-flex justify-content-end">
              <div className="col-md-5 col-10 d-md-block d-none">
                <img
                  src="https://image.freepik.com/free-vector/vault-concept-illustration_114360-222.jpg"
                  alt="Security"
                />
              </div>
              ​
              <div className="col-md-7 col-12">
                <div className="access__block p-2 mt-2 mr-2">
                  <p className="access-email">Hi! {currentUser.email}</p>
                  <h4 className="text-muted mb-3">Please enter access code for {moduleId}</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="text-danger text-left px-2 py-1" />
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="accessCode"
                          placeholder="Enter your code here .. "
                          aria-label="accessCode"
                          aria-describedby="accessCode"
                          maxLength={10}
                        />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-dark btn--primary">
                            {validating ? 'Validating ' : 'Validate'}&nbsp;
                            <i className="fas fa-key" />
                            &nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                    {error && <div className="text-danger ml-2">{error} </div>}
                  </form>
                  ​
                  <div className="access__msg">
                    <h6 className="text-black">Don't have an access code?</h6>
                    <br />
                    <div className="btn btn-outline-light btn-lg p-3 text-left">
                      <button
                        className="profile__page-card__button button--blue btn-lg px-5"
                        type="submit"
                        style={{ fontSize: '20px' }}
                      >
                        Get Subscription&nbsp;
                        <i className="fas fa-arrow-circle-right" />
                      </button>
                    </div>
                  </div>
                  ​
                  <div className="access__msg">
                    <h6 className="text-black">Access code not working?</h6>
                    <br />
                    <p className="text-dark text-justify">
                      <span className="d-block pb-1">Please make sure you've entered the correct access code.</span>
                      <br /> To redeem the access code, please reach us out in the contact details provided below.
                      <br /> We will get back to you as soon as possible.
                    </p>
                  </div>
                  ​
                  <div className="access__msg">
                    <div className="row">
                      <div className="col-sm-6 col-6 pl-0 pb-2 pr-sm-1 pr-1">
                        <div className="cat_access py-3 px-2 bg-faded-primary text-center">
                          <div className="d-sm-flex d-block  justify-content-around">
                            <div className="d-inline">
                              <i className="fas fa-mobile fa-2x" />
                              <p className="text-dark mt-2 d-block">Phone</p>
                            </div>
                            <div className="d-inline px-md-4 px-2 py-1 text-center">
                              <a href="tel:+977-9823146665" className="d-block">
                                9823146665
                              </a>
                              <a href="tel:+01-4231144" className="d-block">
                                01-4231144
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      ​
                      <div className="col-sm-6 col-6 pr-0 pb-2 pl-sm-1 pl-1">
                        <div className="cat_access py-3 px-2 bg-faded-primary text-center">
                          <div className="d-sm-flex d-block justify-content-around">
                            <div className="d-inline">
                              <i className="fa fa-envelope fa-2x" />
                              <p className="text-dark mt-2 d-block">Mail</p>
                            </div>
                            <div className="d-inline px-md-4 px-2 py-1 text-center">
                              <a href="mailto:avyaasapp@gmail.com" className="d-block">
                                info@name.edu.np
                              </a>
                              <a href="mailto:ayata@avyaas.com" className="d-block">
                                ayata@avyaas.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Preloader />
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccessModule);
